import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import type {
  BenefitsTypes,
  ComponentDataType,
  OrderItem,
  PageData,
  SalesItem,
  SalesItemVersion,
  StaticComponentDataType,
  Media,
  SalesStaticItem,
  EventDetails,
  Sales,
  Funnel,
  SalesSubscriptionData
} from 'shared/types/index';
import type { SalesComponentTypes } from 'shared/components/Sales/SalesRenderer';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { createContext, useEffect, useState, useMemo } from 'react';

import useAlert from 'shared/hooks/useAlert';

import {
  randomDifferentElement,
  baseKeysForPriceButtons,
  generateUUID,
  extractLastKey,
  extractComponentKey,
  fixWebsiteOrdering,
  versionMapper,
  defaultWebValue,
  updateWebsiteKeysToColor
} from 'shared/helpers/index';
import cloneDeep from 'lodash/cloneDeep';

export type TypeOfPages = 'sales' | 'delivery';

type ComponentOrder = Record<string, OrderItem[]>;

type PageTypes = 'sales_page' | 'delivery_page';

type PageTypeComponentOrder = {
  [key in PageTypes]: ComponentOrder;
};

export type SalesContextData = {
  downloadUrl: string;
  history: {
    sales?: PageData | null;
    delivery?: PageData | null;
  };
  salesPageValues?: PageData | null;
  deliveryPageValues?: PageData | null;
  renderSales?: PageData | null;
  renderDelivery?: PageData | null;
  coverDisabled: boolean;
  salesPageId: number;
  subDomain: string;
  deliveryToken: string;
  price: string;
  communityUrl: string;
  salesType: 'lead' | 'sales';
  thankYouType: 'basic' | 'video';
  lastModified: string;
  draft: boolean;
  webPrompt: string;
  parentWidth: number;
  type: TypeOfPages;
  ai: string;
  metaTags: Record<string, string | number | string[]>;
  publishModal: boolean;
  notDeletable: Array<string>;
  previousIndex: number;
  lastSaved: string;
  websitePrompts: ComponentDataType;
  websiteStaticData: StaticComponentDataType;
  pagesThumbnails: string[];
  salesPageStripeAccount: string;
  bookPromoMockupId: number;
  selectedCurrency: string;
  domainSalt: string;
  salesExtraBody: Record<string, string>;
  updateCommunityUrl: (value: string) => void;
  updatePublishModal: (value: boolean) => void;
  updateDownloadUrl: (newUrl: string) => void;
  updateMetaTags: (key: string, value: string) => void;
  updateHistory: (type: TypeOfPages, data: PageData) => void;
  updateWebsiteColor: (
    color: string,
    secondary?: string,
    button?: string,
    isSalesPage?: boolean
  ) => void;
  updateValues: (
    type: TypeOfPages,
    id: string | null,
    accessorParentKey: string | null,
    accessorKey: string | null,
    value: string,
    isBenefit?: boolean
  ) => void;
  syncRender: () => void;
  setInitialMetaTags: (meta: Record<string, string | number>) => void;
  updateInitialWebsite: (type: TypeOfPages, data: PageData) => void;
  updateCoverDisabled: (value: boolean) => void;
  updateSalesPageId: (id: number) => void;
  updateSubDomain: (domain: string) => void;
  setDeliveryToken: (token: string) => void;
  updatePrice: (price: string) => void;
  updateThankYouType: (type: 'basic' | 'video') => void;
  updateLastModified: (type: string) => void;
  updateSalesType: (type: 'lead' | 'sales') => void;
  updateDraft: (isDraft: boolean) => void;
  updateWebPrompt: (prompt: string) => void;
  updateParentRef: (el: HTMLElement) => void;
  updateElementVisibility: (el: string) => void;
  updateType: (type: TypeOfPages) => void;
  updateAi: (value: string) => void;
  moveComponent: (move: -1 | 1, type: TypeOfPages, accessorKey?: string | null) => void;
  handleComponentRefresh: (accessorKey?: string | null) => void;
  handleRemoveComponent: (key: string, type: 'delivery' | 'sales') => void;
  resetSalesValues: () => void;
  updatePricingComponents: (price: string) => void;
  addNewComponent: (
    key: string,
    version: string,
    benefitsCount: number,
    data: Record<string, string | Record<string, string>[]> | SalesStaticItem,
    defaultImage?: string
  ) => void;
  updatePreviousIndex: (index: number) => void;
  setLastSaved: (date: string) => void;
  setWebsitePrompts: (prompts: ComponentDataType) => void;
  setWebsiteStaticData: (data: StaticComponentDataType) => void;
  updateImageMeta: (key: string, val: string | number, tag: string) => void;
  removeMetaTag: (key: string, tag: string) => void;
  updateButtonColor: (parentKey: string, color: string) => void;
  updateButtonBenefitColor: (parentKey: string, color: string, index: number) => void;
  setPagesThumbnails: (thumbnails: string[]) => void;
  setSalesPageStripeAccount: (account: string) => void;
  setBookPromoMockupId: (name: number) => void;
  togglePhoneInputField: (accessor: string, key: string) => void;
  updateVideoUrl: (accessorKey: string, url: string, index: number) => void;
  updateButtonUrl: (accessorKey: string, url: string) => void;
  setDomainSalt: (salt: string) => void;
  setSalesExtraBody: (body: Record<string, string>) => void;
  pendingChanges: boolean;
  updatePendingChanges: (value: boolean) => void;
  updateSalesExtraBody: (data: Record<string, string>) => void;
  setSalesComponentOrdering: (data: PageTypeComponentOrder) => void;
  salesComponentOrdering: PageTypeComponentOrder;
  funnelType: string;
  setFunnelType: (type: string) => void;
  syncFooterValue: () => void;
  footerValue: string;
  updateFooterValue: (value: string) => void;
  salesMedia: Media[];
  updateSalesMedia: (media: Media[]) => void;
  isEventFormModalOpen: boolean;
  setIsEventFormModalOpen: (isOpen: boolean) => void;
  setSalesHomePageValues: (data: Sales, type: TypeOfPages) => void;
  eventDetails?: EventDetails;
  isStripeConnected: boolean;
  setIsStripeConnected: (value: boolean) => void;
  funnels: Funnel[];
  setFunnels: (funnels: Funnel[]) => void;
  salesOrderCount: number;
  setSalesOrderCount: (count: number) => void;
  setSelectedCurrency: (currency: string) => void;
  amazonURL: string;
  updateAmazonURL: (value: string) => void;
  isShippingForm: boolean;
  setIsShippingForm: (value?: boolean) => void;
  parentManualWidth: number;
  updateParentManualWidth: (value: number) => void;
  salesPageCollection: Sales[];
  setSalesPageCollection: (data: Sales[]) => void;
  isSubscription: boolean;
  setIsSubscription: (value: boolean | ((v: boolean) => boolean)) => void;
  subscriptionData?: SalesSubscriptionData;
  setSubscriptionData: (data?: SalesSubscriptionData) => void;
  isRecurringSubscriptionModalOpen: boolean;
  setIsRecurringSubscriptionModalOpen: (value: boolean) => void;
  isUpsellModalOpen?: boolean;
  setIsUpsellModalOpen: (value: boolean) => void;
  forceReloading: boolean;
  updateForceReloading: (value: boolean) => void;
  hidePagewheel: boolean;
  setHidePagewheel: (value: boolean) => void;
};

const SalesContext = createContext<SalesContextData | undefined>(undefined);

const SalesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [downloadUrl, setDownloadUrl] = useState<string>('');
  const [history, setHistory] = useState<SalesContextData['history']>({
    delivery: null,
    sales: null
  });
  const [salesPageValues, setSalesPageValues] = useState<PageData | undefined | null>();
  const [deliveryPageValues, setDeliveryPageValues] = useState<PageData | undefined | null>();
  const [renderSales, setRenderSales] = useState<PageData | undefined | null>();
  const [renderDelivery, setRenderDelivery] = useState<PageData | undefined | null>();
  const [metaTags, setMetaTags] = useState<Record<string, string | number>>({});
  const [salesExtraBody, setSalesExtraBody] = useState<Record<string, string>>({
    footer: '',
    formButton: 'Sign Up to Get Started'
  });
  const [salesPageId, setSalesPageId] = useState<number>(0);
  const [price, setPrice] = useState('0');
  const [parentWidth, setParentWidth] = useState(0);
  const [parentManualWidth, setParentManualWidth] = useState(0);
  const [parentRef, setParentRef] = useState<HTMLElement | null>(null);
  const [coverDisabled, setCoverDisabled] = useState(false);
  const [subDomain, setSubDomain] = useState('your-domain');
  const [deliveryToken, setDeliveryToken] = useState('');
  const [salesType, setSalesType] = useState<'lead' | 'sales'>('lead');
  const [thankYouType, setThankYouType] = useState<'basic' | 'video'>('basic');
  const [lastModified, setLastModified] = useState('');
  const [draft, setDraft] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [webPrompt, setWebPrompt] = useState('');
  const [communityUrl, setCommunityUrl] = useState('');
  const [type, setType] = useState<TypeOfPages>('sales');
  const [ai, setAi] = useState('');
  const [previousIndex, setPreviousIndex] = useState(-1);
  const notDeletable = useState(['form_1', 'receipt_1'])[0];
  const [lastSaved, setLastSaved] = useState('');
  const [websitePrompts, setWebsitePrompts] = useState<ComponentDataType>({});
  const [websiteStaticData, setWebsiteStaticData] = useState<StaticComponentDataType>({});
  const [pagesThumbnails, setPagesThumbnails] = useState<string[]>([]);
  const [salesPageStripeAccount, setSalesPageStripeAccount] = useState<string>('');
  const [bookPromoMockupId, setBookPromoMockupId] = useState<number>(0);
  const [domainSalt, setDomainSalt] = useState('');
  const [pendingChanges, setPendingChanges] = useState(false);
  const [salesComponentOrdering, setSalesComponentOrdering] = useState<PageTypeComponentOrder>({
    sales_page: {},
    delivery_page: {}
  });
  const [footerValue, setFooterValue] = useState('');
  const [funnelType, setFunnelType] = useState('digital_product');
  const [salesMedia, setSalesMedia] = useState<Media[]>([]);
  const [isEventFormModalOpen, setIsEventFormModalOpen] = useState(false);
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [salesOrderCount, setSalesOrderCount] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [amazonURL, setAmazonURL] = useState('');
  const [isShippingForm, _setIsShippingForm] = useState(false);
  const [salesPageCollection, setSalesPageCollection] = useState<Sales[]>([]);
  const [isSubscription, setIsSubscription] = useState(false);
  const [subscriptionData, _setSubscriptionData] = useState<SalesSubscriptionData | undefined>();
  const [isRecurringSubscriptionModalOpen, setIsRecurringSubscriptionModalOpen] = useState(false);
  const [forceReloading, setForceReloading] = useState(false);
  // For Certain subscriptions we are hiding Pagewheel information
  const [hidePagewheel, setHidePagewheel] = useState(false);
  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

  const updateForceReloading = (value: boolean) => {
    setForceReloading(value);
  };

  const setSubscriptionData = (data?: SalesSubscriptionData) => {
    if (data?.monthly_price) {
      data.monthly_price = String(Number(data.monthly_price).toFixed(2));
    }
    if (data?.yearly_price) {
      data.yearly_price = String(Number(data.yearly_price).toFixed(2));
    }
    _setSubscriptionData(data);
  };

  const { addAlert } = useAlert();

  const setIsShippingForm = (value?: boolean) => {
    if (value) _setIsShippingForm(value);
    else _setIsShippingForm(false);
  };

  const updateAmazonURL = (value: string) => {
    setAmazonURL(value);
  };

  const updateSalesMedia = (media: Media[]) => {
    setSalesMedia({
      ...salesMedia,
      ...media
    });
  };

  const updateFooterValue = (value: string) => {
    setFooterValue(value);
  };

  const updateParentManualWidth = (value: number) => {
    setParentManualWidth(value);
  };

  const updatePendingChanges = (value: boolean) => {
    setPendingChanges(value);
  };

  useEffect(() => {
    if (salesPageValues && deliveryPageValues && (history.delivery || history.sales)) {
      const sales = !isEqual(history.sales, salesPageValues);
      const delivery = !isEqual(history.delivery, deliveryPageValues);
      updatePendingChanges(sales || delivery);
    }
  }, [history, salesPageValues, deliveryPageValues]);

  const syncRender = () => {
    setRenderSales(salesPageValues);
    setRenderDelivery(deliveryPageValues);
  };

  const updateWebPrompt = (prompt: string) => {
    setWebPrompt(prompt);
  };

  const updateSalesExtraBody = (data: Record<string, string>) => {
    setSalesExtraBody(data);
  };

  const updateHistory = (type: TypeOfPages, data?: PageData | null) => {
    if (!data) return;
    if (type === 'sales') setHistory({ ...history, sales: data });
    if (type === 'delivery') setHistory({ ...history, delivery: data });
  };

  const updateVideoUrl = (accessorKey: string, url: string, index: number) => {
    if (!salesPageValues || !deliveryPageValues || !renderSales || !renderDelivery) return;
    const dataCopy =
      type === 'sales' ? salesPageValues[accessorKey] : deliveryPageValues[accessorKey];

    const dataCopyRender =
      type === 'sales' ? renderSales[accessorKey] : renderDelivery[accessorKey];

    if (typeof dataCopy === 'object' && typeof dataCopyRender === 'object') {
      const newKey = `url_${index}`;
      const updatedData = { ...dataCopy, meta: { ...dataCopy.meta, [newKey]: url } };
      const updatedDataRender = {
        ...dataCopyRender,
        meta: { ...dataCopyRender.meta, [newKey]: url }
      };
      if (type === 'sales') {
        setSalesPageValues({
          ...salesPageValues,
          [accessorKey]: updatedData
        });
        setRenderSales({
          ...renderSales,
          [accessorKey]: updatedDataRender
        });
      } else {
        setDeliveryPageValues({
          ...deliveryPageValues,
          [accessorKey]: updatedData
        });
        setRenderDelivery({
          ...renderDelivery,
          [accessorKey]: updatedDataRender
        });
      }
    }
    updatePendingChanges(true);
  };

  const updateButtonUrl = (accessorKey: string, url: string) => {
    if (!salesPageValues || !deliveryPageValues || !renderSales || !renderDelivery) return;
    const dataCopy =
      type === 'sales' ? salesPageValues[accessorKey] : deliveryPageValues[accessorKey];

    const dataCopyRender =
      type === 'sales' ? renderSales[accessorKey] : renderDelivery[accessorKey];

    if (typeof dataCopy === 'object' && typeof dataCopyRender === 'object') {
      const newKey = 'url';
      const updatedData = { ...dataCopy, meta: { ...dataCopy.meta, [newKey]: url } };
      const updatedDataRender = {
        ...dataCopyRender,
        meta: { ...dataCopyRender.meta, [newKey]: url }
      };
      if (type === 'sales') {
        setSalesPageValues({
          ...salesPageValues,
          [accessorKey]: updatedData
        });
        setRenderSales({
          ...renderSales,
          [accessorKey]: updatedDataRender
        });
      } else {
        setDeliveryPageValues({
          ...deliveryPageValues,
          [accessorKey]: updatedData
        });
        setRenderDelivery({
          ...renderDelivery,
          [accessorKey]: updatedDataRender
        });
      }
    }
    updatePendingChanges(true);
  };

  const updateCommunityUrl = (value: string) => {
    setCommunityUrl(value);
    setSalesExtraBody({
      ...salesExtraBody,
      community: value
    });
  };

  const updatePublishModal = (value: boolean) => {
    setPublishModal(value);
  };

  const setInitialMetaTags = (meta: Record<string, string | number>) => {
    setMetaTags(meta);
  };

  const updateMetaTags = (key: string, value: string | number) => {
    setMetaTags((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const togglePhoneInputField = (accessor: string, key: string) => {
    if (!salesPageValues || !renderSales) return;
    if (accessor in salesPageValues && accessor in renderSales) {
      const value = salesPageValues[accessor];
      const valueRender = renderSales[accessor];
      if (typeof value === 'object' && value)
        setSalesPageValues({
          ...salesPageValues,
          [accessor]: {
            ...value,
            meta: {
              ...value?.meta,
              [key]: !value?.meta?.[key]
            }
          }
        });
      if (typeof valueRender === 'object' && valueRender)
        setRenderSales({
          ...renderSales,
          [accessor]: {
            ...valueRender,
            meta: {
              ...valueRender?.meta,
              [key]: !valueRender?.meta?.[key]
            }
          }
        });
    }
    updatePendingChanges(true);
  };

  const updateButtonBenefitColor = (parentKey: string, color: string, index: number) => {
    if (!salesPageValues || !deliveryPageValues || !renderSales || !renderDelivery) return;

    const dataCopy = type === 'sales' ? salesPageValues : deliveryPageValues;
    const dataCopyRender = type === 'sales' ? renderSales : renderDelivery;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    const value = dataCopy[parentKey];
    const valueRender = dataCopyRender[parentKey];

    if (
      typeof valueRender === 'object' &&
      typeof value === 'object' &&
      typeof value.benefits === 'object' &&
      typeof valueRender.benefits === 'object'
    ) {
      const val = value.benefits[index];
      const valRender = valueRender.benefits[index];
      const updatedValue = value.benefits.map((benefit, i) => {
        if (index === i && typeof benefit.button === 'object')
          return {
            ...benefit,
            button: {
              ...benefit.button,
              color
            }
          };
        return benefit;
      });
      const updatedValueRender = valueRender.benefits.map((benefit, i) => {
        if (index === i && typeof benefit.button === 'object')
          return {
            ...benefit,
            button: {
              ...benefit.button,
              color
            }
          };
        return benefit;
      });
      if (val)
        update({
          ...dataCopy,
          [parentKey]: {
            ...value,
            benefits: updatedValue
          }
        });

      if (valRender)
        updateRender({
          ...dataCopyRender,
          [parentKey]: {
            ...value,
            benefits: updatedValueRender
          }
        });

      updatePendingChanges(true);

      return;
    }
    if (typeof value === 'object' && value.button)
      update({
        ...dataCopy,
        [parentKey]: {
          ...value,
          button: {
            ...value.button,
            color
          }
        }
      });
    if (typeof valueRender === 'object' && valueRender.button)
      updateRender({
        ...dataCopyRender,
        [parentKey]: {
          ...valueRender,
          button: {
            ...valueRender.button,
            color
          }
        }
      });

    updatePendingChanges(true);

    return;
  };

  const updateButtonColor = (parentKey: string, color: string) => {
    if (!salesPageValues || !deliveryPageValues || !renderSales || !renderDelivery) return;

    const dataCopy = type === 'sales' ? salesPageValues : deliveryPageValues;
    const dataCopyRender = type === 'sales' ? renderSales : renderDelivery;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    const value = dataCopy[parentKey];
    const valueRender = dataCopyRender[parentKey];

    if (parentKey === 'form_1' && typeof valueRender === 'object' && typeof value === 'object') {
      if (value.button) {
        update({
          ...dataCopy,
          [parentKey]: {
            ...value,
            button: {
              ...value.button,
              color
            }
          }
        });
      } else {
        update({
          ...dataCopy,
          [parentKey]: {
            ...value,
            button: {
              ...defaultWebValue,
              color
            }
          }
        });
      }

      if (valueRender.button) {
        updateRender({
          ...dataCopyRender,
          [parentKey]: {
            ...valueRender,
            button: {
              ...valueRender.button,
              color
            }
          }
        });
      } else {
        updateRender({
          ...dataCopyRender,
          [parentKey]: {
            ...valueRender,
            button: {
              ...defaultWebValue,
              color
            }
          }
        });
      }
      updatePendingChanges(true);

      return;
    }
    if (typeof value === 'object' && value.button)
      update({
        ...dataCopy,
        [parentKey]: {
          ...value,
          button: {
            ...value.button,
            color
          }
        }
      });
    if (typeof valueRender === 'object' && valueRender.button)
      updateRender({
        ...dataCopyRender,
        [parentKey]: {
          ...valueRender,
          button: {
            ...valueRender.button,
            color
          }
        }
      });

    updatePendingChanges(true);

    return;
  };

  const updateImageMeta = (key: string, val: string | number, tag: string) => {
    if (!salesPageValues || !deliveryPageValues || !renderSales || !renderDelivery) return;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    update((prevState: PageData | null | undefined) => {
      if (!prevState) return prevState;

      if (!prevState[key] || typeof prevState[key] !== 'object') return prevState;

      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          meta: {
            ...prevState[key].meta,
            [tag]: val
          }
        }
      };
    });

    updateRender((prevState) => {
      if (!prevState) return prevState;

      if (!prevState[key] || typeof prevState[key] !== 'object') return prevState;

      updatePendingChanges(true);
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          meta: {
            ...prevState[key].meta,
            [tag]: val
          }
        }
      };
    });
  };

  const removeMetaTag = (key: string, tag: string) => {
    if (!salesPageValues || !deliveryPageValues || !renderSales || !renderDelivery) return;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    update((prevState: PageData | null | undefined) => {
      if (!prevState) return prevState;

      if (!prevState[key] || typeof prevState[key] !== 'object') return prevState;
      const meta = prevState[key].meta;
      delete meta[tag];
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          meta: {
            ...meta
          }
        }
      };
    });

    updateRender((prevState) => {
      if (!prevState) return prevState;

      if (!prevState[key] || typeof prevState[key] !== 'object') return prevState;

      updatePendingChanges(true);
      const meta = prevState[key].meta;
      delete meta[tag];
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          meta: {
            ...meta
          }
        }
      };
    });
  };

  const updatePreviousIndex = (index: number) => {
    setPreviousIndex(index);
  };

  const updateAi = (value: string) => {
    setAi(value);
  };

  const updateDraft = (value: boolean) => {
    setDraft(value);
  };

  const updateParentRef = (el: HTMLElement) => {
    setParentRef(el);
  };

  const updateThankYouType = (type: 'basic' | 'video') => {
    setThankYouType(type);
  };

  const updateType = (type: TypeOfPages) => {
    setType(type);
  };

  const updateLastModified = (date: string) => {
    setLastModified(date);
  };

  const updateSalesType = (type: 'lead' | 'sales') => {
    setSalesType(type);
  };

  const updatePrice = (p: string) => {
    if (p === '0.00') p = '0';
    setPrice(p);
  };

  const updateSubDomain = (domain: string) => {
    setSubDomain(domain);
  };

  const updateSalesPageId = (id: number) => {
    setSalesPageId(id);
  };

  const updateCoverDisabled = (value: boolean) => {
    setCoverDisabled(value);
  };

  const updateDownloadUrl = (newUrl: string) => {
    setDownloadUrl(newUrl);
  };

  const syncFooterValue = () => {
    setSalesExtraBody({
      ...salesExtraBody,
      footer: footerValue
    });
  };

  const updateElementVisibility = (el: string) => {
    if (!salesPageValues || !deliveryPageValues || !renderSales || !renderDelivery) return;
    const entry = type === 'sales' ? salesPageValues : deliveryPageValues;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;

    const entryRender = type === 'sales' ? renderSales : renderDelivery;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    const value = entry[el];
    const valueRender = entryRender[el];
    if (typeof value === 'object' && typeof valueRender === 'object') {
      update({
        ...entry,
        [el]: {
          ...value,
          hidden: !value.hidden
        }
      });
      updateRender({
        ...entryRender,
        [el]: {
          ...valueRender,
          hidden: !valueRender.hidden
        }
      });
      updatePendingChanges(true);
    }
  };

  const addNewComponent = (
    newKey: string,
    version: string,
    benefitsCount: number,
    data: Record<string, string | Record<string, string>[]> | SalesStaticItem,
    defaultImage?: string
  ) => {
    if (
      !salesPageValues ||
      !deliveryPageValues ||
      !renderSales ||
      !renderDelivery ||
      previousIndex === -1
    )
      return;
    const newOrder = previousIndex + 1;

    let buttonValue = ' ';
    if (typeof data['button'] === 'string') {
      buttonValue = data['button'];
    }
    let contentValue = ' ';
    if (typeof data['content'] === 'string') {
      contentValue = data['content'];
    }
    let titleValue = ' ';
    if (typeof data['title'] === 'string') {
      titleValue = data['title'];
    }

    let buttonColor: string = '';

    const values = Object.values(type === 'sales' ? salesPageValues : deliveryPageValues);

    for (const value of values) {
      if (typeof value !== 'string' && value?.button?.color) {
        buttonColor = value.button.color;
        break;
      }
    }

    const defaultValues: SalesItem = {
      version: version as SalesItem['version'],
      order: newOrder,
      hidden: false,
      button: {
        id: generateUUID(),
        val: buttonValue,
        color: buttonColor
      },
      content: {
        id: generateUUID(),
        val: contentValue
      },
      title: {
        id: generateUUID(),
        val: titleValue
      },
      benefits: Array.from({ length: benefitsCount }).map((_, index) => {
        let benefitTitlevalue = ' ';
        if (typeof data['benefits'] === 'object' && data['benefits'][index]['title']) {
          benefitTitlevalue = data['benefits'][index]['title'];
        }
        let benefitcontentValue = ' ';
        if (typeof data['benefits'] === 'object' && data['benefits'][index]['content']) {
          benefitcontentValue = data['benefits'][index]['content'];
        }
        let benefitButtonValue = ' ';
        if (typeof data['benefits'] === 'object' && data['benefits'][index]['button']) {
          benefitButtonValue = data['benefits'][index]['button'];
        }
        return {
          content: {
            id: generateUUID(),
            val: benefitcontentValue
          },
          title: {
            id: generateUUID(),
            val: benefitTitlevalue
          },
          button: {
            id: generateUUID(),
            val: benefitButtonValue,
            color: buttonColor
          }
        };
      }) as SalesItem['benefits'],
      meta: {
        ...(defaultImage && newKey.includes('coach') ? { you_here: defaultImage } : {}),
        ...(defaultImage && newKey.includes('hero') ? { you_here: defaultImage } : {}),
        ...(defaultImage && (newKey.includes('about') || newKey.includes('image'))
          ? { image: defaultImage }
          : {})
      }
    };

    const keys = Object.keys(type === 'delivery' ? deliveryPageValues : salesPageValues);
    const filteredKeys = keys.filter((k) => k.includes(newKey)).sort();

    let lastKey: string | null = null;

    filteredKeys.map((fk) => (lastKey = extractLastKey(fk as keyof SalesComponentTypes)));

    const dataCopy = type === 'delivery' ? { ...deliveryPageValues } : { ...salesPageValues };
    const dataCopyRender = type === 'delivery' ? { ...renderDelivery } : { ...renderSales };

    const componentCount = Object.values(dataCopy).filter(
      (value) => typeof value === 'object' && value.order !== undefined
    ).length;

    const idValue = lastKey ? `${newKey}_${Number(lastKey) + 1}` : `${newKey}_1`;

    Object.entries(type === 'delivery' ? deliveryPageValues : salesPageValues).map(
      ([key, value]) => {
        if (typeof value === 'string') return;
        if (!value?.order && value?.order !== 0) return;
        if (value.order === newOrder) {
          dataCopy[key] = { ...value, order: newOrder + 1 };
          dataCopy[idValue] = {
            ...defaultValues,
            order: newOrder
          };
        }
        if (value.order > newOrder) {
          dataCopy[key] = { ...value, order: value.order + 1 };
        }
      }
    );

    Object.entries(type === 'delivery' ? renderDelivery : renderSales).map(([key, value]) => {
      if (typeof value === 'string') return;
      if (!value?.order && value?.order !== 0) return;
      if (value.order === newOrder) {
        dataCopyRender[key] = { ...value, order: newOrder + 1 };
        dataCopyRender[idValue] = {
          ...defaultValues,
          order: newOrder
        };
      }
      if (value.order > newOrder) {
        dataCopyRender[key] = { ...value, order: value.order + 1 };
      }
    });

    // If it's last component, add new component to the end
    if (newOrder === componentCount) {
      dataCopy[idValue] = {
        ...defaultValues,
        order: newOrder
      };
      dataCopyRender[idValue] = {
        ...defaultValues,
        order: newOrder
      };
    }

    if (dataCopy[idValue] && typeof dataCopy[idValue] === 'object') {
      dataCopy[idValue] = {
        ...dataCopy[idValue],
        id: idValue
      };
    }
    if (dataCopyRender[idValue] && typeof dataCopyRender[idValue] === 'object') {
      dataCopyRender[idValue] = {
        ...dataCopyRender[idValue],
        id: idValue
      };
    }

    if (type === 'delivery') {
      setDeliveryPageValues({
        ...dataCopy,
        base_color: deliveryPageValues.base_color,
        website_color: deliveryPageValues.website_color,
        secondary_color: deliveryPageValues.secondary_color
      });
      setRenderDelivery({
        ...dataCopyRender,
        base_color: renderDelivery.base_color,
        website_color: renderDelivery.website_color,
        secondary_color: renderDelivery.secondary_color
      });
    } else {
      setSalesPageValues({
        ...dataCopy,
        base_color: salesPageValues.base_color,
        website_color: salesPageValues.website_color,
        secondary_color: salesPageValues.secondary_color
      });
      setRenderSales({
        ...dataCopyRender,
        base_color: renderSales.base_color,
        website_color: renderSales.website_color,
        secondary_color: renderSales.secondary_color
      });
      updatePendingChanges(true);
    }

    setPreviousIndex(-1);
  };

  const moveComponent = (move: -1 | 1, type: TypeOfPages, accessorKey?: string | null) => {
    if (!accessorKey) return;

    if (!deliveryPageValues || !salesPageValues || !renderSales || !renderDelivery) return;

    const entry = type === 'sales' ? salesPageValues : deliveryPageValues;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;

    const entryRender = type === 'sales' ? renderSales : renderDelivery;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    const tempObject: Partial<PageData> = {};
    const tempObjectRender: Partial<PageData> = {};
    const value = entry[accessorKey];
    const valueRender = entryRender[accessorKey];

    if (typeof value === 'object' && typeof valueRender === 'object') {
      const oldIndex = value.order;
      const newIndex = value.order + move;
      const oldIndexRender = valueRender.order;
      const newIndexRender = valueRender.order + move;

      if (newIndex < 0 || Object.keys(entry).length < newIndex) return;

      Object.entries(entry).map(([key, value]) => {
        if (typeof value === 'object' && value.order === newIndex)
          tempObject[key] = {
            ...value,
            order: oldIndex
          };
        else if (typeof value === 'object' && value.order === oldIndex)
          tempObject[key] = {
            ...value,
            order: newIndex
          };
        else tempObject[key] = value;
      });

      Object.entries(entryRender).map(([key, value]) => {
        if (typeof value === 'object' && value.order === newIndexRender)
          tempObjectRender[key] = {
            ...value,
            order: oldIndexRender
          };
        else if (typeof value === 'object' && value.order === oldIndexRender)
          tempObjectRender[key] = {
            ...value,
            order: newIndexRender
          };
        else tempObjectRender[key] = value;
      });

      update(tempObject as PageData);
      updateRender(tempObjectRender as PageData);
      updatePendingChanges(true);
    }
  };

  const updateWebsiteColor = (color: string, secondary?: string, buttonColor?: string) => {
    if (!salesPageValues || !deliveryPageValues || !renderDelivery || !renderSales) {
      throw new Error(
        'Website is missing values or was not initialised correctly, please try again later...'
      );
    }
    const entry = type === 'sales' ? salesPageValues : deliveryPageValues;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;

    const entryRender = type === 'sales' ? renderSales : renderDelivery;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;
    Object.entries(entry).map(([key, value]) => {
      if (typeof value === 'string') return;
      if (typeof value === 'object' && value.button) {
        entry[key] = {
          ...value,
          button: {
            ...value?.button,
            color: buttonColor
          }
        };
      }
    });

    update({
      ...entry,
      website_color: color,
      secondary_color: secondary
    });

    Object.entries(entryRender).map(([key, value]) => {
      if (typeof value === 'string') return;
      if (typeof value === 'object' && value.button) {
        entryRender[key] = {
          ...value,
          button: {
            ...value?.button,
            color: buttonColor
          }
        };
      }
    });

    updateRender({
      ...entryRender,
      website_color: color,
      secondary_color: secondary
    });
  };

  const updateValues = (
    type: TypeOfPages,
    id: string | null,
    accessorParentKey: string | null,
    accessorKey: string | null,
    value: string,
    isBenefit: boolean = false
  ) => {
    if (!salesPageValues || !deliveryPageValues) {
      throw new Error(
        'Website is missing values or was not initialised correctly, please try again later...'
      );
    }

    if (!accessorKey || !accessorParentKey) {
      throw new Error('accessor keys are not setup correctly please check');
    }

    const entry = type === 'sales' ? salesPageValues : deliveryPageValues;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;

    const values = entry[accessorParentKey as keyof PageData];
    let accessorValue = (values as SalesItem)[accessorKey as keyof SalesItem];

    if (accessorParentKey === 'form_1' && !accessorValue) {
      accessorValue = {
        ...defaultWebValue,
        hidden: false,
        id: generateUUID()
      };
    }

    // Handle the case where we modify content, title or button for a component
    if (!isBenefit && typeof values === 'object' && typeof accessorValue === 'object') {
      const updated = {
        ...entry,
        [accessorParentKey]: {
          ...values,
          [accessorKey]: {
            ...accessorValue,
            val: value
          }
        }
      };
      update(updated);
      updateHistory(type, updated);
      return;
    }

    // Handle the case where we modify a benefit content, title or button
    const copy = type === 'sales' ? { ...salesPageValues } : { ...deliveryPageValues };
    const activeItem = copy[accessorParentKey as keyof PageData];

    if (typeof activeItem !== 'object') return;

    const activeBenefit = activeItem.benefits?.find((benefit: BenefitsTypes) => {
      const b = benefit[accessorKey as keyof BenefitsTypes];
      if (typeof b === 'object') {
        return b?.id === id;
      }
      return false;
    });

    if (!activeBenefit) return;

    const benefitIndex = activeItem.benefits?.indexOf(activeBenefit);

    const updated = {
      ...copy,
      [accessorParentKey]: {
        ...activeItem,
        benefits: activeItem.benefits?.map((benefit: BenefitsTypes, index: number) => {
          const b = benefit[accessorKey as keyof BenefitsTypes];
          if (typeof b === 'object') {
            if (index === benefitIndex)
              return {
                ...benefit,
                [accessorKey]: {
                  ...b,
                  val: value
                }
              };
            return benefit;
          }
          return benefit;
        })
      }
    };
    update(updated);
    updateHistory(type, updated);
  };

  const resetSalesValues = () => {
    setDownloadUrl('');
    setSalesPageValues(null);
    setDeliveryPageValues(null);
    setRenderDelivery(null);
    setRenderSales(null);
    setCoverDisabled(false);
    setSalesPageId(0);
    setSubDomain('your-domain');
    setPrice('0');
    setSalesType('lead');
    setThankYouType('basic');
    setLastModified('');
    setDraft(false);
    setWebPrompt('');
    setParentWidth(0);
    setType('sales');
    setAi('');
    setMetaTags({});
    setPublishModal(false);
    setPreviousIndex(-1);
    setCommunityUrl('');
  };

  const _updateButtonValue = (value: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
    if (tempDiv.textContent) return tempDiv.textContent;
    return value;
  };

  // const updatePricingComponents = (newPrice: string) => {
  //   if (!salesPageValues || !renderSales) return;
  //   let salesPageCopy = { ...salesPageValues };
  //   let salesPageCopyRender = { ...renderSales };

  //   Object.keys(salesPageCopy).forEach((key) => {
  //     if (!baseKeysForPriceButtons.some((base) => key.includes(base))) {
  //       return;
  //     }
  //     const copy = salesPageValues[key];
  //     let payload = {};

  //     if (typeof copy === 'object') {
  //       let value = _updateButtonValue(copy?.button?.val || '');
  //       if (price === '0') {
  //         value = value
  //           .split(' ')
  //           .filter((t) => t !== 'HERE')
  //           .join(' ');
  //       } else if (price && value && /\$\d+(\.\d{1,2})?/.test(value)) {
  //         value = value?.replace(/\$\d+(\.\d{1,2})?/, '');
  //       }
  //       if (newPrice && newPrice !== '0') {
  //         value = `${value} $${newPrice}`;
  //       } else {
  //         value = `${value} HERE`;
  //       }

  //       payload = {
  //         ...salesPageCopy,
  //         [key]: {
  //           ...copy,
  //           button: {
  //             ...copy.button,
  //             val: copy?.button?.val ? value : null
  //           }
  //         }
  //       };
  //       salesPageCopy = {
  //         ...salesPageCopy,
  //         ...payload
  //       };
  //     }
  //   });

  //   Object.keys(salesPageCopyRender).forEach((key) => {
  //     if (!baseKeysForPriceButtons.some((base) => key.includes(base))) {
  //       return;
  //     }
  //     const copy = salesPageCopyRender[key];
  //     let payload = {};

  //     if (typeof copy === 'object') {
  //       let value = _updateButtonValue(copy?.button?.val || '');
  //       if (price === '0') {
  //         value = value
  //           .split(' ')
  //           .filter((t) => t !== 'HERE')
  //           .join(' ');
  //       } else if (price && value && /\$\d+(\.\d{1,2})?/.test(value)) {
  //         value = value?.replace(/\$\d+(\.\d{1,2})?/, '');
  //       }
  //       if (newPrice && newPrice !== '0') {
  //         value = `${value} $${newPrice}`;
  //       } else {
  //         value = `${value} HERE`;
  //       }

  //       payload = {
  //         ...salesPageCopyRender,
  //         [key]: {
  //           ...copy,
  //           button: {
  //             ...copy.button,
  //             val: copy?.button?.val ? value : null
  //           }
  //         }
  //       };
  //       salesPageCopyRender = {
  //         ...salesPageCopyRender,
  //         ...payload
  //       };
  //     }
  //   });

  //   setSalesPageValues(salesPageCopy);
  //   setRenderSales(salesPageCopyRender);
  //   updatePendingChanges(true);
  // };

  const updatePricingComponents = (newPrice: string) => {
    if (!salesPageValues || !renderSales) return;

    let salesPageCopy = { ...salesPageValues };
    let salesPageCopyRender = { ...renderSales };

    Object.keys(salesPageCopy).forEach((key) => {
      if (!baseKeysForPriceButtons.some((base) => key.includes(base))) {
        return;
      }

      const copy = salesPageValues[key];
      let payload = {};

      if (typeof copy === 'object') {
        let value = _updateButtonValue(copy?.button?.val || '');

        if (value.includes('$')) {
          value = value.replace(/\$/, '');
        }

        if (newPrice.includes('USD')) {
          newPrice = `$${newPrice.replace(/USD/, '').trim()}`;
        } else if (!newPrice.includes('USD') && value.includes('$')) {
          value = value.replace(/\$/, '');
        } else if (selectedCurrency === 'USD' && Number(newPrice) > 0) {
          newPrice = `$${newPrice}`;
        }

        value = value.replace(/\b[A-Z]{3}\b/g, '').trim();

        if (price === `0 ${selectedCurrency}`) {
          value = value.replace(/HERE/, '').trim();
        } else if (price && value && /\d+(\.\d{1,2})?/.test(value)) {
          value = value.replace(/\d+(\.\d{1,2})?/, '').trim();
        }

        if (value.includes('HERE')) {
          value = value.replace(/HERE/, '').trim();
        }

        if (newPrice === '$0') {
          value = `${value} HERE`.replace('$0', 'HERE').trim();
        } else if (newPrice && newPrice !== `0 ${selectedCurrency}`) {
          value = `${value} ${newPrice}`.replace(/HERE/, '').trim();
        } else {
          // value = `${value} HERE`;
        }

        if (newPrice === '0') {
          value = value.replaceAll('0', '');
        }

        payload = {
          ...salesPageCopy,
          [key]: {
            ...copy,
            button: {
              ...copy.button,
              val: value || null
            }
          }
        };
        salesPageCopy = {
          ...salesPageCopy,
          ...payload
        };
      }
    });

    Object.keys(salesPageCopyRender).forEach((key) => {
      if (!baseKeysForPriceButtons.some((base) => key.includes(base))) {
        return;
      }

      const copy = salesPageCopyRender[key];
      let payload = {};

      if (typeof copy === 'object') {
        let value = _updateButtonValue(copy?.button?.val || '');
        value = value.replace(/\b[A-Z]{3}\b/g, '').trim();
        if (!value.length || value === '<p><br></p>') return;

        if (value.includes('$')) {
          value = value.replace(/\$/, '');
        }

        if (newPrice.includes('USD')) {
          newPrice = `$${newPrice.replace(/USD/, '').trim()}`;
        } else if (!newPrice.includes('USD') && value.includes('$')) {
          value = value.replace(/\$/, '');
        } else if (selectedCurrency === 'USD' && Number(newPrice) > 0) {
          newPrice = `$${newPrice}`;
        }

        if (price === `0 ${selectedCurrency}`) {
          value = value.replace(/HERE/, '').trim();
        } else if (price && value && /\d+(\.\d{1,2})?/.test(value)) {
          value = value.replace(/\d+(\.\d{1,2})?/, '').trim();
        }

        if (value.includes('HERE')) {
          value = value.replace(/HERE/, '').trim();
        }

        if (newPrice === '$0') {
          value = `${value} HERE`.replace('$0', 'HERE').trim();
        } else if (newPrice && newPrice !== `0 ${selectedCurrency}`) {
          value = `${value} ${newPrice}`.replace(/HERE/, '').trim();
        } else {
          // ignore this for now
          // value = `${value} HERE`;
        }

        if (newPrice === '0') {
          value = value.replaceAll('0', '');
        }

        payload = {
          ...salesPageCopyRender,
          [key]: {
            ...copy,
            button: {
              ...copy.button,
              val: value || null
            }
          }
        };
        salesPageCopyRender = {
          ...salesPageCopyRender,
          ...payload
        };
      }
    });

    setSalesPageValues(salesPageCopy);
    setRenderSales(salesPageCopyRender);
    updatePendingChanges(true);
  };

  function getNextMonthlyDate(originalDate: Dayjs, currentDate: Dayjs) {
    if (currentDate.isBefore(originalDate) || currentDate.isSame(originalDate, 'day')) {
      return originalDate;
    }

    const monthsPassed = currentDate.diff(originalDate, 'month');
    let nextEventDate = originalDate.add(monthsPassed + 1, 'month');

    if (nextEventDate.date() !== originalDate.date()) {
      nextEventDate = nextEventDate.endOf('month');
    }

    return nextEventDate;
  }

  const updateRecurringEvent = (data: PageData) => {
    if (
      !data ||
      !data?.['event_1'] ||
      typeof data?.['event_1'] !== 'object' ||
      !data['event_1']?.['meta']?.['isRecurring'] ||
      data['event_1']?.benefits?.length !== 3
    )
      return data;

    const eventMeta = data['event_1']?.['meta'];
    const originalDate = dayjs(eventMeta?.['date'], 'MM/DD/YYYY');
    const currentDate = dayjs(new Date());
    const diffInDays = currentDate.diff(originalDate, 'day');
    const weeksPassed = Math.floor(diffInDays / 7);
    if (diffInDays <= 0) {
      return data;
    }

    let nextEventDate = originalDate;
    if (eventMeta?.['recurringType'] === 'Daily' && !currentDate.isSame(originalDate, 'day')) {
      const daysPassed = currentDate.diff(originalDate, 'day');
      nextEventDate = originalDate.add(daysPassed + 1, 'day');
    }
    if (eventMeta?.['recurringType'] === 'Weekly') {
      nextEventDate = originalDate.add(weeksPassed + 1, 'week');
    }
    if (eventMeta?.['recurringType'] === 'Monthly') {
      nextEventDate = getNextMonthlyDate(originalDate, currentDate);
    }

    const updatedData = {
      ...data,
      event_1: {
        ...data?.['event_1'],
        benefits: [
          {
            ...data?.['event_1']?.benefits?.[0],
            content: {
              ...data?.['event_1']?.benefits?.[0].content,
              val: nextEventDate.format('MM/DD/YYYY')
            }
          },
          {
            ...data?.['event_1']?.benefits?.[1]
          },
          {
            ...data?.['event_1']?.benefits?.[2]
          }
        ]
      }
    };

    return updatedData;
  };

  const updateInitialWebsite = (type: TypeOfPages, data: PageData) => {
    data = updateRecurringEvent(data);
    if (type === 'sales') {
      setSalesPageValues(data);
      setRenderSales(data);
    }

    if (type === 'delivery') {
      setDeliveryPageValues(data);
      setRenderDelivery(data);
    }
  };

  const removeImageParameters = (data: Record<string, unknown>) => {
    const pattern = /^(x_\d+|y_\d+|d_width_\d+|d_height_\d+)$/;
    return Object.fromEntries(Object.entries(data).filter(([key]) => !pattern.test(key)));
  };

  const _updateVersion = (
    key: string,
    version: SalesItemVersion,
    data: PageData,
    update: Dispatch<SetStateAction<PageData | null | undefined>>
  ) => {
    if (key in data) {
      const copy = data[key];
      if (typeof copy === 'object') {
        const updatedMeta = removeImageParameters(copy.meta);
        update({
          ...data,
          [key]: {
            ...copy,
            meta: updatedMeta,
            version
          }
        });
      }
    }
  };

  const handleComponentRefresh = (accessorKey?: string | null) => {
    if (!salesPageValues || !accessorKey || !deliveryPageValues || !renderSales || !renderDelivery)
      return;

    const entry = cloneDeep(type === 'sales' ? salesPageValues : deliveryPageValues);
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;

    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    const value = entry[accessorKey];
    if (accessorKey in entry) {
      if (typeof value === 'object' && value.version) {
        const componentKey = extractComponentKey(accessorKey as keyof SalesComponentTypes);
        const versions = versionMapper(componentKey);

        // New Way to update versions
        const newVersion = randomDifferentElement(versions, value.version);
        if (newVersion) {
          _updateVersion(accessorKey, newVersion as SalesItemVersion, entry, updateRender);
          _updateVersion(accessorKey, newVersion as SalesItemVersion, entry, update);
        }

        // Old Way to update versions
        // const oldVersion = versions.indexOf(value.version as never);
        // if (oldVersion !== -1) {
        //   if (oldVersion + 1 === versions.length) {
        //     _updateVersion(accessorKey, 'V1', entry, updateRender);
        //     _updateVersion(accessorKey, 'V1', entry, update);
        //   } else {
        //     const version = versions[oldVersion + 1] as SalesItemVersion;
        //     _updateVersion(accessorKey, version, entry, update);
        //     _updateVersion(accessorKey, version, entry, updateRender);
        //   }
        //   updatePendingChanges(true);
        // }
      }
    }
  };

  const handleRemoveComponent = (key: string, type: 'delivery' | 'sales') => {
    if (notDeletable.includes(key)) {
      addAlert?.('This component cannot be deleted!', 'warning');
      return;
    }
    if (!deliveryPageValues || !salesPageValues || !renderSales || !renderDelivery) return;

    const target = deliveryPageValues[key];
    if (typeof target === 'object' && target.meta['download']) {
      addAlert?.('This component cannot be deleted!', 'warning');
      return; // don't allow deletion of download product button
    }

    const entry = type === 'sales' ? salesPageValues : deliveryPageValues;
    const update = type === 'sales' ? setSalesPageValues : setDeliveryPageValues;
    const updateRender = type === 'sales' ? setRenderSales : setRenderDelivery;

    const copy = omit(Object.assign({}, entry), key) as PageData;
    const newValues = fixWebsiteOrdering(copy);
    update({
      ...newValues,
      base_color: entry.base_color,
      website_color: entry.website_color,
      secondary_color: entry.secondary_color
    });

    updateRender({
      ...newValues,
      base_color: entry.base_color,
      website_color: entry.website_color,
      secondary_color: entry.secondary_color
    });
    updatePendingChanges(true);
  };

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      setParentWidth(entry.contentRect.width);
    };

    if (parentRef) {
      const rect = parentRef.getBoundingClientRect();
      setParentWidth(rect.width);
    }

    const observer = new ResizeObserver(handleResize);
    if (parentRef) observer.observe(parentRef);

    return () => observer.disconnect();

    // listen to when parentRef mounts
  }, [parentRef]);

  const eventDetails = useMemo(() => {
    if (
      salesPageValues &&
      salesPageValues['event_1'] &&
      typeof salesPageValues['event_1'] === 'object'
    )
      return {
        eventName: salesPageValues['event_1'].title?.val || '',
        eventDescription: salesPageValues['event_1'].content?.val || '',
        eventDate: salesPageValues['event_1'].benefits?.[0].content?.val || '',
        eventTime: salesPageValues['event_1'].benefits?.[1].content?.val || '',
        eventPlace: salesPageValues['event_1'].benefits?.[2].content?.val || '',
        eventUrl: salesPageValues['event_1'].meta?.['eventUrl'],
        calendarUrl: salesPageValues['event_1'].meta?.['calendarUrl'],
        zoomPassword: salesPageValues['event_1'].meta?.['zoomPassword'],
        date: salesPageValues['event_1'].meta?.['date'] ?? '',
        time: salesPageValues['event_1'].meta?.['time'] ?? '',
        timezone: salesPageValues['event_1'].meta?.['timezone'] ?? '',
        isRecurring: salesPageValues['event_1'].meta?.['isRecurring'] ?? '',
        recurringType: salesPageValues['event_1'].meta?.['recurringType'] ?? '',
        peopleLimit: salesPageValues['event_1'].meta?.['peopleLimit'] ?? '',
        noDates: salesPageValues['event_1'].meta?.['noDates'] ?? ''
      };
    return;
  }, [salesPageValues]);

  const setSalesHomePageValues = (data: Sales, type: TypeOfPages) => {
    if (!data) return;
    const colorPalette = data.color_palette;

    if (data.extra_body?.['community']) updateCommunityUrl(data.extra_body['community']);
    setIsSubscription(!!data.is_subscription);
    if (data.subscription_info) {
      setSubscriptionData(data.subscription_info);
    }

    setSalesExtraBody(data.extra_body);

    setPagesThumbnails(data.pages_thumbnails);

    updateSubDomain(data.sub_domain);
    setDeliveryToken(data.delivery_token);
    updatePrice(data.price);
    updateSalesType(data.type === 'sales' ? 'sales' : 'lead');

    // Convert Website/Delivery Data Keys to Colors
    const updatedWebsiteData = updateWebsiteKeysToColor(data.page_data, colorPalette);
    const updatedDeliveryData = updateWebsiteKeysToColor(data.delivery_page_data, colorPalette);

    if (data.book_file?.full_url) {
      updateDownloadUrl(data.book_file?.full_url);
    }
    updateType(type);

    if (updatedWebsiteData) updateInitialWebsite('sales', updatedWebsiteData);
    if (updatedDeliveryData) updateInitialWebsite('delivery', updatedDeliveryData);
  };

  return (
    <SalesContext.Provider
      value={{
        downloadUrl,
        salesPageValues,
        deliveryPageValues,
        coverDisabled,
        salesPageId,
        subDomain,
        price,
        salesType,
        thankYouType,
        lastModified,
        draft,
        webPrompt,
        parentWidth,
        type,
        ai,
        metaTags,
        notDeletable,
        publishModal,
        lastSaved,
        communityUrl,
        websitePrompts,
        websiteStaticData,
        domainSalt,
        salesExtraBody,
        updateAi,
        updateDownloadUrl,
        updateMetaTags,
        updateValues,
        updateWebsiteColor,
        updatePublishModal,
        updateInitialWebsite,
        setInitialMetaTags,
        updateCoverDisabled,
        updateSalesPageId,
        updateSubDomain,
        updateParentRef,
        updatePrice,
        updateThankYouType,
        updateLastModified,
        updateSalesType,
        updateDraft,
        updateWebPrompt,
        updateElementVisibility,
        updateType,
        moveComponent,
        resetSalesValues,
        handleComponentRefresh,
        handleRemoveComponent,
        updatePricingComponents,
        addNewComponent,
        updatePreviousIndex,
        previousIndex,
        setLastSaved,
        setWebsitePrompts,
        setWebsiteStaticData,
        updateImageMeta,
        updateButtonColor,
        updateCommunityUrl,
        pagesThumbnails,
        setPagesThumbnails,
        salesPageStripeAccount,
        setSalesPageStripeAccount,
        bookPromoMockupId,
        setBookPromoMockupId,
        togglePhoneInputField,
        updateVideoUrl,
        setDomainSalt,
        setSalesExtraBody,
        renderDelivery,
        renderSales,
        syncRender,
        history,
        updateHistory,
        pendingChanges,
        updatePendingChanges,
        updateButtonUrl,
        updateSalesExtraBody,
        salesComponentOrdering,
        setSalesComponentOrdering,
        funnelType,
        setFunnelType,
        updateFooterValue,
        footerValue,
        syncFooterValue,
        salesMedia,
        updateSalesMedia,
        isEventFormModalOpen,
        setIsEventFormModalOpen,
        eventDetails,
        setSalesHomePageValues,
        isStripeConnected,
        setIsStripeConnected,
        updateButtonBenefitColor,
        funnels,
        setFunnels,
        salesOrderCount,
        setSalesOrderCount,
        setSelectedCurrency,
        selectedCurrency,
        amazonURL,
        updateAmazonURL,
        isShippingForm,
        setIsShippingForm,
        parentManualWidth,
        updateParentManualWidth,
        salesPageCollection,
        setSalesPageCollection,
        isSubscription,
        setIsSubscription,
        subscriptionData,
        setSubscriptionData,
        isRecurringSubscriptionModalOpen,
        setIsRecurringSubscriptionModalOpen,
        forceReloading,
        updateForceReloading,
        removeMetaTag,
        deliveryToken,
        setDeliveryToken,
        hidePagewheel,
        setHidePagewheel,
        isUpsellModalOpen,
        setIsUpsellModalOpen
      }}>
      {children}
    </SalesContext.Provider>
  );
};

export { SalesContext, SalesProvider };
