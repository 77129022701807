export const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const SalesOptions = [
  { id: 1, label: 'topbar', value: 'topbar' },
  { id: 2, label: 'universal_button', value: 'universal_button' },
  { id: 3, label: 'headline_1', value: 'headline_1' },
  { id: 4, label: 'subheadline_1', value: 'subheadline_1' },
  {
    id: 5,
    label: 'minihero_sidebar_headline',
    value: 'minihero_sidebar_headline'
  },
  {
    id: 6,
    label: 'minihero_sidebar_paragraph',
    value: 'minihero_sidebar_paragraph'
  },
  { id: 7, label: 'headline_2', value: 'headline_2' },
  { id: 8, label: 'paragraph_2', value: 'paragraph_2' },
  {
    id: 9,
    label: 'objcrush_shortblurp_1_headline',
    value: 'objcrush_shortblurp_1_headline'
  },
  {
    id: 10,
    label: 'objcrush_shortblurp_1_blurp',
    value: 'objcrush_shortblurp_1_blurp'
  },
  {
    id: 11,
    label: 'objcrush_shortblurp_2_headline',
    value: 'objcrush_shortblurp_2_headline'
  },
  {
    id: 12,
    label: 'objcrush_shortblurp_2_blurp',
    value: 'objcrush_shortblurp_2_blurp'
  },
  {
    id: 13,
    label: 'objcrush_shortblurp_3_headline',
    value: 'objcrush_shortblurp_3_headline'
  },
  {
    id: 14,
    label: 'objcrush_shortblurp_3_blurp',
    value: 'objcrush_shortblurp_3_blurp'
  },
  { id: 15, label: 'headline_3', value: 'headline_3' },
  { id: 16, label: 'subheadline_3', value: 'subheadline_3' },
  { id: 17, label: 'thisisfor_headline', value: 'thisisfor_headline' },
  {
    id: 18,
    label: 'thisisfor_paragraph',
    value: 'thisisfor_paragraph'
  },
  { id: 19, label: 'thisisfor_1_blurp', value: 'thisisfor_1_blurp' },
  { id: 20, label: 'thisisfor_2_blurp', value: 'thisisfor_2_blurp' },
  { id: 21, label: 'thisisfor_3_blurp', value: 'thisisfor_3_blurp' },
  { id: 22, label: 'aboutme_headline', value: 'aboutme_headline' },
  { id: 23, label: 'aboutme_paragraph', value: 'aboutme_paragraph' },
  { id: 24, label: 'cart_headline', value: 'cart_headline' },
  { id: 25, label: 'cart_subheadline', value: 'cart_subheadline' },
  {
    id: 26,
    label: 'cart_section_1_headline',
    value: 'cart_section_1_headline'
  },
  {
    id: 27,
    label: 'cart_section_1_subheadline',
    value: 'cart_section_1_subheadline'
  },
  {
    id: 28,
    label: 'cart_section_2_headline',
    value: 'cart_section_2_headline'
  },
  {
    id: 29,
    label: 'cart_section_2_subheadline',
    value: 'cart_section_2_subheadline'
  },
  {
    id: 30,
    label: 'cart_section_3_headline',
    value: 'cart_section_3_headline'
  },
  {
    id: 31,
    label: 'cart_section_3_subheadline',
    value: 'cart_section_3_subheadline'
  },
  { id: 32, label: 'sp_cart_button', value: 'sp_cart_button' },
  { id: 33, label: 'sp_footer_text', value: 'sp_footer_text' },
  { id: 34, label: 'guarantee_headline', value: 'guarantee_headline' },
  {
    id: 35,
    label: 'guarantee_subheadline',
    value: 'guarantee_subheadline'
  },
  { id: 36, label: 'faq_headline', value: 'faq_headline' },
  { id: 37, label: 'faq_subheadline', value: 'faq_subheadline' },
  { id: 38, label: 'faq_qa_1_question', value: 'faq_qa_1_question' },
  { id: 39, label: 'faq_qa_1_answer', value: 'faq_qa_1_answer' },
  { id: 40, label: 'faq_qa_2_question', value: 'faq_qa_2_question' },
  { id: 41, label: 'faq_qa_2_answer', value: 'faq_qa_2_answer' },
  { id: 42, label: 'faq_qa_3_question', value: 'faq_qa_3_question' },
  { id: 43, label: 'faq_qa_3_answer', value: 'faq_qa_3_answer' },
  { id: 44, label: 'faq_qa_4_question', value: 'faq_qa_4_question' },
  { id: 45, label: 'faq_qa_4_answer', value: 'faq_qa_4_answer' },
  { id: 46, label: 'faq_qa_5_question', value: 'faq_qa_5_question' },
  { id: 47, label: 'faq_qa_5_answer', value: 'faq_qa_5_answer' },
  { id: 48, label: 'faq_qa_6_question', value: 'faq_qa_6_question' },
  { id: 49, label: 'faq_qa_6_answer', value: 'faq_qa_6_answer' },
  { id: 50, label: 'detailed_headline', value: 'detailed_headline' },
  {
    id: 51,
    label: 'detailed_headline_bullet_1',
    value: 'detailed_headline_bullet_1'
  },
  {
    id: 52,
    label: 'detailed_headline_bullet_2',
    value: 'detailed_headline_bullet_2'
  },
  {
    id: 53,
    label: 'detailed_headline_bullet_3',
    value: 'detailed_headline_bullet_3'
  }
];

export const DeliveryOptions = [
  { id: 1, label: 'headline_4', value: 'headline_4' },
  { id: 2, label: 'subheadline_4', value: 'subheadline_4' },
  { id: 3, label: 'tx_sidebar_headline', value: 'tx_sidebar_headline' },
  {
    id: 4,
    label: 'tx_sidebar_paragraph',
    value: 'tx_sidebar_paragraph'
  },
  { id: 5, label: 'headline_5', value: 'headline_5' },
  { id: 6, label: 'subheadline_5', value: 'subheadline_5' },
  { id: 7, label: 'tx_download_button', value: 'tx_download_button' },
  { id: 8, label: 'bold_blurp_1', value: 'bold_blurp_1' },
  { id: 9, label: 'regular_blurp_1', value: 'regular_blurp_1' },
  { id: 10, label: 'bold_blurp_2', value: 'bold_blurp_2' },
  { id: 11, label: 'regular_blurp_2', value: 'regular_blurp_2' },
  { id: 12, label: 'headline_6', value: 'headline_6' },
  { id: 13, label: 'tx_join_button', value: 'tx_join_button' },
  {
    id: 14,
    label: 'tx_join_button_link',
    value: 'tx_join_button_link'
  },
  { id: 15, label: 'video_heading_1', value: 'video_heading_1' },
  { id: 16, label: 'video_paragraph_1', value: 'video_paragraph_1' },
  { id: 17, label: 'videoYoutubeUrl1', value: 'videoYoutubeUrl1' },
  { id: 18, label: 'video_heading_2', value: 'video_heading_2' },
  { id: 19, label: 'video_paragraph_2', value: 'video_paragraph_2' },
  { id: 20, label: 'videoYoutubeUrl2', value: 'videoYoutubeUrl2' },
  { id: 21, label: 'video_heading_3', value: 'video_heading_3' },
  { id: 22, label: 'video_paragraph_3', value: 'video_paragraph_3' },
  { id: 23, label: 'videoYoutubeUrl3', value: 'videoYoutubeUrl3' },
  { id: 24, label: 'video_heading_4', value: 'video_heading_4' },
  { id: 25, label: 'video_paragraph_4', value: 'video_paragraph_4' },
  { id: 26, label: 'videoYoutubeUrl4', value: 'videoYoutubeUrl4' },
  { id: 27, label: 'video_heading_5', value: 'video_heading_5' },
  { id: 28, label: 'video_paragraph_5', value: 'video_paragraph_5' },
  { id: 29, label: 'videoYoutubeUrl5', value: 'videoYoutubeUrl5' },
  { id: 30, label: 'video_heading_6', value: 'video_heading_6' },
  { id: 31, label: 'video_paragraph_6', value: 'video_paragraph_6' },
  { id: 32, label: 'videoYoutubeUrl6', value: 'videoYoutubeUrl6' },
  { id: 33, label: 'video_heading_7', value: 'video_heading_7' },
  { id: 34, label: 'video_paragraph_7', value: 'video_paragraph_7' },
  { id: 35, label: 'videoYoutubeUrl7', value: 'videoYoutubeUrl7' },
  { id: 36, label: 'video_heading_8', value: 'video_heading_8' },
  { id: 37, label: 'video_paragraph_8', value: 'video_paragraph_8' },
  { id: 38, label: 'videoYoutubeUrl8', value: 'videoYoutubeUrl8' },
  { id: 39, label: 'video_heading_9', value: 'video_heading_9' },
  { id: 40, label: 'video_paragraph_9', value: 'video_paragraph_9' },
  { id: 41, label: 'videoYoutubeUrl9', value: 'videoYoutubeUrl9' },
  { id: 42, label: 'video_heading_10', value: 'video_heading_10' },
  { id: 43, label: 'video_paragraph_10', value: 'video_paragraph_10' },
  { id: 44, label: 'videoYoutubeUrl10', value: 'videoYoutubeUrl10' },
  { id: 45, label: 'video_heading_11', value: 'video_heading_11' },
  { id: 46, label: 'video_paragraph_11', value: 'video_paragraph_11' },
  { id: 47, label: 'videoYoutubeUrl11', value: 'videoYoutubeUrl11' },
  { id: 48, label: 'video_heading_12', value: 'video_heading_12' },
  { id: 49, label: 'video_paragraph_12', value: 'video_paragraph_12' },
  { id: 50, label: 'videoYoutubeUrl12', value: 'videoYoutubeUrl12' },
  { id: 51, label: 'tx_button', value: 'tx_button' }
];

export const SalesPromptOptions = [
  {
    id: 1,
    label: 'Sales',
    value: 'sales'
  },
  {
    id: 2,
    label: 'Lead Magnet',
    value: 'lead'
  }
];

export const boldKeys = [
  'guarantee_headline',
  'faq_headline',
  'cart_headline',
  'thisisfor_headline',
  'aboutme_headline',
  'detailed_headline_bullet_1',
  'detailed_headline_bullet_2',
  'detailed_headline_bullet_3',
  'detailed_headline',
  'topbar_headline',
  'objcrush_shortblurp_1_headline',
  'objcrush_shortblurp_2_headline',
  'objcrush_shortblurp_3_headline',
  'cart_section_1_headline',
  'cart_section_2_headline',
  'cart_section_3_headline',
  'minihero_sidebar_headline',
  'headline_1',
  'headline_2',
  'headline_3',
  'headline_4',
  'headline_5',
  'headline_6',
  'tx_sidebar_headline',
  'bold_blurp_1',
  'bold_blurp_2',
  'video_heading_1',
  'video_heading_2',
  'video_heading_3'
];

export const PAGE_TYPES = {
  //   learning: 'Information & Learning Pages',
  list: 'Checklists and Trackers for Tasks and Lists',
  calendar: 'Calendar & Schedule Pages',
  //   recipe: 'Recipes or Steps in a Process',
  'about me': 'About Me Page',
  journal: 'Journal or Workbook Pages'
};

export const PAGE_TYPES_REVERSED = {
  //   'Information & Learning Pages': 'learning',
  'Checklists and Trackers for Tasks and Lists': 'list',
  'Calendar & Schedule Pages': 'calendar',
  //   'Recipes or Steps in a Process': 'recipe',
  'About Me Page': 'about me',
  'Journal or Workbook Pages': 'journal'
} as { [key: string]: string };

export const MAIN_COLOR_1 = '#11bad5';
export const MAIN_COLOR_2 = '#11bad5';
export const MAIN_COLOR_3 = '#11bad5';
export const MAIN_COLOR_4 = '#11bad5';
export const MAIN_COLOR_5 = '#11bad5';
export const BACKGROUND_COLOR_1 = '#f1fcfe';
export const BACKGROUND_COLOR_2 = '#f1fcfe';
export const BACKGROUND_COLOR_3 = '#f1fcfe';
export const BACKGROUND_COLOR_4 = '#FFFFFF';
export const BASE_COLOR_1 = '#000000';
export const BASE_COLOR_2 = '#002C5C';
export const BASE_COLOR_3 = '#606060';
export const BASE_COLOR_4 = '#9A9A9A';
export const BASE_COLOR_DARK = '#FFFFFF';

export const DEFAULT_PALETTE = {
  id: 0,
  background_color1: BACKGROUND_COLOR_1,
  background_color2: BACKGROUND_COLOR_2,
  background_color3: BACKGROUND_COLOR_3,
  background_color4: BACKGROUND_COLOR_4,
  base_color1: BASE_COLOR_1,
  base_color2: BASE_COLOR_2,
  base_color3: BASE_COLOR_3,
  base_color4: BASE_COLOR_4,
  base_color_dark: BASE_COLOR_DARK,
  color1: MAIN_COLOR_1,
  color2: MAIN_COLOR_2,
  color3: MAIN_COLOR_3,
  color4: MAIN_COLOR_4,
  color5: MAIN_COLOR_5,
  name: 'Untitled - 2163f-86b2-6fdf-bd2c-ba2303b335c4'
};

export const DEFAULT_CATEGORIZED_PALETTE = {
  id: 0,
  palette_id: 0,
  name: 'default',
  mainColors: [
    {
      id: 0,
      value: MAIN_COLOR_1,
      name: 'Color 1'
    },
    {
      id: 1,
      value: MAIN_COLOR_2,
      name: 'Color 2'
    },
    {
      id: 2,
      value: MAIN_COLOR_3,
      name: 'Color 3'
    },
    {
      id: 3,
      value: MAIN_COLOR_4,
      name: 'Color 4'
    },
    {
      id: 4,
      value: MAIN_COLOR_5,
      name: 'Color 5'
    }
  ],
  backgroundColors: [
    {
      id: 0,
      value: BACKGROUND_COLOR_1,
      name: 'Background Color 1'
    },
    {
      id: 1,
      value: BACKGROUND_COLOR_2,
      name: 'Background Color 2'
    },
    {
      id: 2,
      value: BACKGROUND_COLOR_3,
      name: 'Background Color 3'
    },
    {
      id: 3,
      value: BACKGROUND_COLOR_4,
      name: 'Background Color 4'
    }
  ],
  baseColors: [
    {
      id: 0,
      value: BASE_COLOR_1,
      name: 'Base Color 1'
    },
    {
      id: 1,
      value: BASE_COLOR_2,
      name: 'Base Color 2'
    },
    {
      id: 2,
      value: BASE_COLOR_3,
      name: 'Base Color 3'
    },
    {
      id: 3,
      value: BASE_COLOR_4,
      name: 'Base Color 4'
    }
  ]
};

interface DefaultcomponentAccesoryItem {
  id: boolean;
  title?: boolean;
  content?: boolean;
  button?: boolean;
  benefits?: { id: boolean; title?: boolean; content?: boolean; button?: boolean }[];
}

export const DefaultComponentAccesories = {
  topbar: {
    id: true,
    button: true,
    content: true,
    title: true,
    benefits: [
      {
        id: true,
        title: true
      },
      {
        id: true,
        title: true
      }
    ]
  },
  hero: {
    id: true,
    button: true,
    content: true,
    title: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  },
  hero_basic: {
    id: true,
    title: true,
    content: true,
    button: true
  },
  hero_detailed: {
    id: true,
    button: true,
    content: true,
    title: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  },
  hero_coach: {
    id: true,
    button: true,
    content: true,
    title: true
  },
  mini_hero: {
    id: true,
    title: true,
    content: true
  },
  heading: {
    id: true,
    content: true,
    title: true
  },
  check_section: {
    id: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  },
  for_you: {
    id: true,
    title: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true,
        button: true
      },
      {
        id: true,
        title: true,
        content: true,
        button: true
      },
      {
        id: true,
        title: true,
        content: true,
        button: true
      }
    ]
  },
  about_me: {
    id: true,
    title: true,
    content: true
  },
  guarantee: {
    id: true,
    title: true,
    content: true
  },
  video: {
    id: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  },
  photo: {
    id: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  },
  faq: {
    id: true,
    title: true,
    content: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  },
  call_to_action: {
    id: true,
    title: true,
    button: true,
    benefits: [
      {
        id: true,
        content: true
      },
      {
        id: true,
        content: true
      },
      {
        id: true,
        content: true
      }
    ]
  },
  how_to_start: {
    id: true,
    title: true,
    content: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  },
  headline_only: {
    id: true,
    title: true
  },
  paragraph: {
    id: true,
    content: true
  },
  heading_with_button: {
    id: true,
    title: true,
    content: true,
    button: true
  },
  heading_with_button_calendar: {
    id: true,
    title: true,
    content: true,
    button: true
  },
  heading_with_button_event: {
    id: true,
    title: true,
    content: true,
    button: true
  },
  heading_with_button_community: {
    id: true,
    title: true,
    content: true,
    button: true
  },
  form: {
    id: true,
    title: true,
    content: true,
    button: true
  },
  recurring_form: {
    id: true,
    title: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true,
        button: true
      },
      {
        id: true,
        title: true,
        content: true,
        button: true
      }
    ]
  },
  receipt: {
    id: true
  },
  event: {
    id: true,
    title: true,
    content: true,
    benefits: [
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      },
      {
        id: true,
        title: true,
        content: true
      }
    ]
  }
} as Record<string, DefaultcomponentAccesoryItem>;

export const ERROR_MESSAGES_MAPPER = {
  sub_domain: {
    unique: 'Subdomain Already Exists'
  }
};

export const componentsData = [
  {
    name: 'Topbar',
    selector: 'topbar',
    items: [
      {
        id: 1,
        name: 'Two colors with button 1',
        img: '/img/components/topbar/topbar_illustr_1.jpg',
        version: 'V1',
        selector: 'topbar',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Two colors with button 1',
        img: '/img/components/topbar/topbar_illustr_2.jpg',
        version: 'V2',
        selector: 'topbar',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Hero',
    selector: 'hero',
    items: [
      {
        id: 1,
        name: 'Hero Basic V1',
        img: '/img/components/hero/hero_illustr_1.jpg',
        version: 'V1',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Hero Basic V2',
        img: '/img/components/hero/hero_illustr_2.jpg',
        version: 'V2',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 3,
        name: 'Hero Basic V3',
        img: '/img/components/hero/hero_illustr_3.jpg',
        version: 'V3',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 4,
        name: 'Hero Basic V4',
        img: '/img/components/hero/hero_illustr_4.jpg',
        version: 'V4',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 5,
        name: 'Hero Coaching V1',
        img: '/img/components/hero/hero_illustr_8.jpg',
        version: 'V5',
        selector: 'hero',
        benefitsCount: 1
      },
      {
        id: 6,
        name: 'Hero Coaching V2',
        img: '/img/components/hero/hero_illustr_9.jpg',
        version: 'V6',
        selector: 'hero',
        benefitsCount: 1
      },
      {
        id: 7,
        name: 'Hero Basic V2',
        img: '/img/components/hero/hero_illustr_2.jpg',
        version: 'V7',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 8,
        name: 'Hero Basic V3',
        img: '/img/components/hero/hero_illustr_3.jpg',
        version: 'V8',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 9,
        name: 'Hero Coaching V1',
        img: '/img/components/hero/hero_illustr_8.jpg',
        version: 'V9',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 10,
        name: 'Hero Basic V5',
        img: '/img/components/hero/hero_illustr_10.jpg',
        version: 'V10',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 11,
        name: 'Hero Basic V6',
        img: '/img/components/hero/hero_illustr_11.jpg',
        version: 'V11',
        selector: 'hero',
        benefitsCount: 0
      },
      {
        id: 12,
        name: 'Hero Detailed V1',
        img: '/img/components/hero/hero_illustr_5.jpg',
        version: 'V12',
        selector: 'hero',
        benefitsCount: 3
      },
      {
        id: 13,
        name: 'Hero Detailed V2',
        img: '/img/components/hero/hero_illustr_6.jpg',
        version: 'V13',
        selector: 'hero',
        benefitsCount: 3
      },
      {
        id: 14,
        name: 'Hero Detailed V3',
        img: '/img/components/hero/hero_illustr_7.jpg',
        version: 'V14',
        selector: 'hero',
        benefitsCount: 3
      },
      {
        id: 15,
        name: 'Hero Detailed V4',
        img: '/img/components/hero/hero_illustr_12.jpg',
        version: 'V15',
        selector: 'hero',
        benefitsCount: 3
      }
    ]
  },
  {
    name: 'Hero Detailed',
    selector: 'hero_detailed',
    items: [
      {
        id: 1,
        name: 'Hero Detailed V1',
        img: '/img/components/hero/hero_illustr_5.jpg',
        version: 'V1',
        selector: 'hero_detailed',
        benefitsCount: 3
      },
      {
        id: 2,
        name: 'Hero Detailed V2',
        img: '/img/components/hero/hero_illustr_6.jpg',
        version: 'V2',
        selector: 'hero_detailed',
        benefitsCount: 3
      },
      {
        id: 3,
        name: 'Hero Detailed V3',
        img: '/img/components/hero/hero_illustr_7.jpg',
        version: 'V3',
        selector: 'hero_detailed',
        benefitsCount: 3
      },
      {
        id: 4,
        name: 'Hero Detailed V4',
        img: '/img/components/hero/hero_illustr_12.jpg',
        version: 'V4',
        selector: 'hero_detailed',
        benefitsCount: 3
      }
    ]
  },
  {
    name: 'Minihero',
    selector: 'mini_hero',
    items: [
      {
        id: 1,
        name: 'Minihero Left Image V1',
        img: '/img/components/miniHero/minihero_illustr_1.jpg',
        version: 'V1',
        selector: 'mini_hero',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Minihero Right Image V2',
        img: '/img/components/miniHero/minihero_illustr_2.jpg',
        version: 'V2',
        selector: 'mini_hero',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Sidebar',
    selector: 'check_section',
    items: [
      {
        id: 1,
        name: 'Sidebar Right Image V1',
        img: '/img/components/sidebar/sidebar_illustr_1.jpg',
        version: 'V1',
        selector: 'check_section',
        benefitsCount: 3
      },
      {
        id: 2,
        name: 'Sidebar Left Image V2',
        img: '/img/components/sidebar/sidebar_illustr_2.jpg',
        version: 'V2',
        selector: 'check_section',
        benefitsCount: 3
      }
    ]
  },
  {
    name: 'This Is For',
    selector: 'for_you',
    items: [
      {
        id: 1,
        name: 'This Is For You V1',
        img: '/img/components/thisIsFor/thisisfor_illustr_1.jpg',
        version: 'V1',
        selector: 'for_you',
        benefitsCount: 3
      },
      {
        id: 2,
        name: 'This Is For You V2',
        img: '/img/components/thisIsFor/thisisfor_illustr_2.jpg',
        version: 'V2',
        selector: 'for_you',
        benefitsCount: 3
      },
      {
        id: 3,
        name: 'Card V1',
        img: '/img/components/3parts/cards_three_1.jpg',
        version: 'V3',
        selector: 'for_you',
        benefitsCount: 3
      }
    ]
  },
  {
    name: 'About Me',
    selector: 'about_me',
    items: [
      {
        id: 1,
        name: 'About Me Portrait Image',
        img: '/img/components/aboutme/aboutme_1.jpg',
        version: 'V1',
        selector: 'about_me',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'About Me Landscape Image',
        img: '/img/components/aboutme/aboutme_2.jpg',
        version: 'V2',
        selector: 'about_me',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Guarantee',
    selector: 'guarantee',
    items: [
      {
        id: 1,
        name: 'Guarantee V1',
        img: '/img/components/guarantee/guarantee_illustr_1.jpg',
        version: 'V1',
        selector: 'guarantee',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Guarantee V2',
        img: '/img/components/guarantee/guarantee_illustr_2.jpg',
        version: 'V2',
        selector: 'guarantee',
        benefitsCount: 0
      },
      {
        id: 3,
        name: 'Guarantee V3',
        img: '/img/components/guarantee/guarantee_illustr_3.jpg',
        version: 'V3',
        selector: 'guarantee',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'FAQ',
    selector: 'faq',
    items: [
      {
        id: 1,
        name: 'FAQ V1',
        img: '/img/components/faqs/faq_illustr_1.jpg',
        version: 'V1',
        selector: 'faq',
        benefitsCount: 5
      },
      {
        id: 2,
        name: 'FAQ V2',
        img: '/img/components/faqs/faq_illustr_2.jpg',
        version: 'V2',
        selector: 'faq',
        benefitsCount: 5
      },
      {
        id: 3,
        name: 'FAQ V3',
        img: '/img/components/faqs/faq_illustr_3.jpg',
        version: 'V3',
        selector: 'faq',
        benefitsCount: 5
      }
    ]
  },
  {
    name: 'Call To Action',
    selector: 'call_to_action',
    items: [
      {
        id: 1,
        name: 'Call To Action V1',
        img: '/img/components/calltoaction/calltoaction_illustr_1.jpg',
        version: 'V1',
        selector: 'call_to_action',
        benefitsCount: 3
      },
      {
        id: 2,
        name: 'Call To Action V2',
        img: '/img/components/calltoaction/calltoaction_illustr_2.jpg',
        version: 'V2',
        selector: 'call_to_action',
        benefitsCount: 3
      }
    ]
  },
  {
    name: 'Video',
    selector: 'video',
    items: [
      {
        id: 1,
        name: 'Video V1',
        img: '/img/components/video/video_illustr_1.jpg',
        version: 'V1',
        selector: 'video',
        benefitsCount: 1
      },
      {
        id: 2,
        name: 'Video V2',
        img: '/img/components/video/video_illustr_2.jpg',
        version: 'V2',
        selector: 'video',
        benefitsCount: 1
      },
      {
        id: 3,
        name: 'Video V3',
        img: '/img/components/video/video_illustr_3.jpg',
        version: 'V3',
        selector: 'video',
        benefitsCount: 2
      },
      {
        id: 4,
        name: 'Video V4',
        img: '/img/components/video/video_illustr_4.jpg',
        version: 'V4',
        selector: 'video',
        benefitsCount: 1
      },
      {
        id: 5,
        name: 'Video V5',
        img: '/img/components/video/video_illustr_5.jpg',
        version: 'V5',
        selector: 'video',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Photo',
    selector: 'photo',
    items: [
      {
        id: 1,
        name: 'Photo V1',
        img: '/img/components/image/image_illustr_1.jpg',
        version: 'V1',
        selector: 'photo',
        benefitsCount: 2
      },
      {
        id: 2,
        name: 'Photo V2',
        img: '/img/components/image/image_illustr_2.jpg',
        version: 'V2',
        selector: 'photo',
        benefitsCount: 2
      },
      {
        id: 3,
        name: 'Photo V3',
        img: '/img/components/image/image_illustr_3.jpg',
        version: 'V3',
        selector: 'photo',
        benefitsCount: 2
      },
      {
        id: 4,
        name: 'Photo V4',
        img: '/img/components/image/image_illustr_4.jpg',
        version: 'V4',
        selector: 'photo',
        benefitsCount: 2
      }
    ]
  },
  {
    name: 'How To Get Started',
    selector: 'how_to_start',
    items: [
      {
        id: 1,
        name: 'How To Get Started V1',
        img: '/img/components/3parts/3parts_illustr_1.jpg',
        version: 'V1',
        selector: 'how_to_start',
        benefitsCount: 3
      },
      {
        id: 2,
        name: 'How To Get Started V2',
        img: '/img/components/3parts/3parts_illustr_2.jpg',
        version: 'V2',
        selector: 'how_to_start',
        benefitsCount: 3
      },
      {
        id: 3,
        name: 'How To Get Started V3',
        img: '/img/components/3parts/3parts_illustr_3.jpg',
        version: 'V3',
        selector: 'how_to_start',
        benefitsCount: 3
      }
    ]
  },
  {
    name: 'Headline Only',
    selector: 'headline_only',
    items: [
      {
        id: 1,
        name: 'Headline Only V1',
        img: '/img/components/headlineOnly/headline_only_illustr_1.jpg',
        version: 'V1',
        selector: 'headline_only',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Headline Only V2',
        img: '/img/components/headlineOnly/headline_only_illustr_2.jpg',
        version: 'V2',
        selector: 'headline_only',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Paragraph',
    selector: 'paragraph',
    items: [
      {
        id: 1,
        name: 'Paragraph V1',
        img: '/img/components/paragraph/paragraph_only_illustr_1.jpg',
        version: 'V1',
        selector: 'paragraph',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Paragraph V2',
        img: '/img/components/paragraph/paragraph_only_illustr_2.jpg',
        version: 'V2',
        selector: 'paragraph',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Headline and Subheadline',
    selector: 'heading',
    items: [
      {
        id: 1,
        name: 'Headline and Subheadline V1',
        img: '/img/components/headline_subheadline/head_sub_illustr_1.jpg',
        version: 'V1',
        selector: 'heading',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Headline and Subheadline V2',
        img: '/img/components/headline_subheadline/head_sub_illustr_2.jpg',
        version: 'V2',
        selector: 'heading',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Heading With Button',
    selector: 'heading_with_button',
    items: [
      {
        id: 1,
        name: 'Headline With Button V1',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_1.jpg',
        version: 'V1',
        selector: 'heading_with_button',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Headline With Button V2',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_2.jpg',
        version: 'V2',
        selector: 'heading_with_button',
        benefitsCount: 0
      },
      {
        id: 3,
        name: 'Headline With Button V3',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_3.jpg',
        version: 'V3',
        selector: 'heading_with_button',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Heading With Button Event',
    selector: 'heading_with_button_event',
    items: [
      {
        id: 1,
        name: 'Headline With Button V1',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_1.jpg',
        version: 'V1',
        selector: 'heading_with_button_event',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Heading With Button Community',
    selector: 'heading_with_button_community',
    items: [
      {
        id: 1,
        name: 'Headline With Button V1',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_1.jpg',
        version: 'V1',
        selector: 'heading_with_button_community',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Heading With Button Calendar',
    selector: 'heading_with_button_calendar',
    items: [
      {
        id: 1,
        name: 'Headline With Button V1',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_1.jpg',
        version: 'V1',
        selector: 'heading_with_button_calendar',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Events',
    selector: 'event',
    items: [
      {
        id: 1,
        name: 'Event V1',
        img: '/img/components/events/event_illustr_1.jpg',
        version: 'V1',
        selector: 'event',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Event V2',
        img: '/img/components/events/event_illustr_2.jpg',
        version: 'V2',
        selector: 'event',
        benefitsCount: 0
      },
      {
        id: 3,
        name: 'Event V3',
        img: '/img/components/events/event_illustr_3.jpg',
        version: 'V3',
        selector: 'event',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Forms',
    selector: 'form',
    items: [
      {
        id: 1,
        name: 'Form V1',
        img: '/img/components/form/order_withbar_illustr_6.jpg',
        version: 'V1',
        selector: 'form',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Form V2',
        img: '/img/components/form/order_withbar_illustr_7.jpg',
        version: 'V2',
        selector: 'form',
        benefitsCount: 0
      }
    ]
  },
  {
    name: 'Recurring Forms',
    selector: 'recurring_form',
    items: [
      {
        id: 1,
        name: 'Recurring Form V1',
        img: '/img/components/form/order_withbar_illustr_8.jpg',
        version: 'V1',
        selector: 'recurring_form',
        benefitsCount: 0
      },
      {
        id: 2,
        name: 'Recurring Form V2',
        img: '/img/components/form/order_withbar_illustr_9.jpg',
        version: 'V2',
        selector: 'recurring_form',
        benefitsCount: 0
      },
      {
        id: 3,
        name: 'Recurring Form V3',
        img: '/img/components/form/order_withbar_illustr_10.jpg',
        version: 'V3',
        selector: 'recurring_form',
        benefitsCount: 0
      },
      {
        id: 4,
        name: 'Recurring Form V4',
        img: '/img/components/form/order_withbar_illustr_11.jpg',
        version: 'V4',
        selector: 'recurring_form',
        benefitsCount: 0
      },
      {
        id: 5,
        name: 'Recurring Form V5',
        img: '/img/components/form/order_withbar_illustr_12.jpg',
        version: 'V5',
        selector: 'recurring_form',
        benefitsCount: 0
      }
    ]
  }
];

export const userSelectionMapper = {
  hero: ['hero', 'hero_detailed'],
  check_section: ['check_section', 'mini_hero'],
  headline_only: ['headline_only', 'heading', 'heading_with_button'],
  for_you: ['for_you', 'how_to_start']
};

export const userComponentSelection = [
  {
    name: 'Saved Components',
    selector: [],
    items: []
  },
  {
    name: 'components.topBar',
    selector: 'topbar',
    items: [
      {
        id: 1,
        name: 'components.topBarCTAWithButton',
        img: '/img/components/topbar/topbar_illustr_1.jpg',
        version: 'V1',
        selector: 'topbar',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.topBarLogoWithButton',
        img: '/img/components/topbar/topbar_illustr_2.jpg',
        version: 'V2',
        selector: 'topbar',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 3,
        name: 'components.topBarLogoAndLinksWithButton',
        img: '/img/components/topbar/topbar_illustr_3.jpg',
        version: 'V3',
        selector: 'topbar',
        benefitsCount: 2,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.hero',
    selector: 'hero',
    items: [
      {
        id: 1,
        name: 'components.heroBasic',
        img: '/img/components/hero/hero_illustr_1.jpg',
        version: 'V1',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.heroBasic',
        img: '/img/components/hero/hero_illustr_2.jpg',
        version: 'V2',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 3,
        name: 'components.heroBasic',
        img: '/img/components/hero/hero_illustr_3.jpg',
        version: 'V3',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 4,
        name: 'components.heroBasic',
        img: '/img/components/hero/hero_illustr_4.jpg',
        version: 'V4',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 5,
        name: 'components.heroCoaching',
        img: '/img/components/hero/hero_illustr_8.jpg',
        version: 'V5',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 6,
        name: 'components.heroCoaching',
        img: '/img/components/hero/hero_illustr_9.jpg',
        version: 'V6',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 7,
        name: 'components.heroWithVideo',
        img: '/img/components/hero/hero_illustr_10.png',
        version: 'V10',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 8,
        name: 'components.heroWithVideo',
        img: '/img/components/hero/hero_illustr_11.jpg',
        version: 'V11',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 9,
        name: 'components.heroDetailed',
        img: '/img/components/hero/hero_illustr_5.jpg',
        version: 'V12',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 10,
        name: 'components.heroDetailed',
        img: '/img/components/hero/hero_illustr_6.jpg',
        version: 'V13',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 11,
        name: 'components.heroDetailed',
        img: '/img/components/hero/hero_illustr_7.jpg',
        version: 'V14',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 12,
        name: 'components.heroDetailed',
        img: '/img/components/hero/hero_illustr_12.jpg',
        version: 'V15',
        selector: 'hero',
        benefitsCount: 3,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.sidebar',
    selector: 'mini_hero',
    items: [
      {
        id: 1,
        name: 'components.miniHeroLeftImage',
        img: '/img/components/miniHero/minihero_illustr_1.jpg',
        version: 'V1',
        selector: 'mini_hero',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.miniHeroRightImage',
        img: '/img/components/miniHero/minihero_illustr_2.jpg',
        version: 'V2',
        selector: 'mini_hero',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 3,
        name: 'components.sidebarRightImage',
        img: '/img/components/sidebar/sidebar_illustr_1.jpg',
        version: 'V1',
        selector: 'check_section',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 4,
        name: 'components.sidebarLeftImage',
        img: '/img/components/sidebar/sidebar_illustr_2.jpg',
        version: 'V2',
        selector: 'check_section',
        benefitsCount: 3,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.threePartSection',
    selector: 'for_you',
    items: [
      {
        id: 1,
        name: 'components.thisIsForYou',
        img: '/img/components/thisIsFor/thisisfor_illustr_1.jpg',
        version: 'V1',
        selector: 'for_you',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.thisIsForYou',
        img: '/img/components/thisIsFor/thisisfor_illustr_2.jpg',
        version: 'V2',
        selector: 'for_you',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 3,
        name: 'components.howToGetStarted',
        img: '/img/components/3parts/3parts_illustr_1.jpg',
        version: 'V1',
        selector: 'how_to_start',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 4,
        name: 'components.howToGetStarted',
        img: '/img/components/3parts/3parts_illustr_2.jpg',
        version: 'V2',
        selector: 'how_to_start',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 5,
        name: 'components.howToGetStarted',
        img: '/img/components/3parts/3parts_illustr_3.jpg',
        version: 'V3',
        selector: 'how_to_start',
        benefitsCount: 3,
        translationForVersion: true
      },
      !process.env['API_URL']?.includes('.com') && {
        id: 6,
        name: 'components.cardV1',
        img: '/img/components/3parts/cards_three_1.jpg',
        version: 'V3',
        selector: 'for_you',
        benefitsCount: 3,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.aboutMe',
    selector: 'about_me',
    items: [
      {
        id: 1,
        name: 'components.aboutMePortraitImage',
        img: '/img/components/aboutme/aboutme_1.jpg',
        version: 'V1',
        selector: 'about_me',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.aboutMeLandscapeImage',
        img: '/img/components/aboutme/aboutme_2.jpg',
        version: 'V2',
        selector: 'about_me',
        benefitsCount: 0,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.headlinesAndButtons',
    selector: ['headline_only', 'heading', 'heading_with_button'],
    items: [
      {
        id: 1,
        name: 'components.headlineOnly',
        img: '/img/components/headlineOnly/headline_only_illustr_1.jpg',
        version: 'V1',
        selector: 'headline_only',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.headlineOnly',
        img: '/img/components/headlineOnly/headline_only_illustr_2.jpg',
        version: 'V2',
        selector: 'headline_only',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 3,
        name: 'components.headlineAndSubheadline',
        img: '/img/components/headline_subheadline/head_sub_illustr_1.jpg',
        version: 'V1',
        selector: 'heading',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 4,
        name: 'components.headlineAndSubheadline',
        img: '/img/components/headline_subheadline/head_sub_illustr_2.jpg',
        version: 'V2',
        selector: 'heading',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 5,
        name: 'components.headlineAndSubheadline',
        img: '/img/components/headline_subheadline/head_sub_illustr_v3.jpg',
        version: 'V3',
        selector: 'heading',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 6,
        name: 'components.headlineWithButton',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_1.jpg',
        version: 'V1',
        selector: 'heading_with_button',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 7,
        name: 'components.headlineWithButton',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_2.jpg',
        version: 'V2',
        selector: 'heading_with_button',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 8,
        name: 'components.headlineWithButton',
        img: '/img/components/heading_withbutton/heading_withbutton_illustr_3.jpg',
        version: 'V3',
        selector: 'heading_with_button',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 9,
        name: 'components.callToAction',
        img: '/img/components/calltoaction/calltoaction_illustr_1.jpg',
        version: 'V1',
        selector: 'call_to_action',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 10,
        name: 'components.callToAction',
        img: '/img/components/calltoaction/calltoaction_illustr_2.jpg',
        version: 'V2',
        selector: 'call_to_action',
        benefitsCount: 3,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.videoOrImage',
    selector: ['photo', 'video'],
    items: [
      {
        id: 1,
        name: 'components.video',
        img: '/img/components/video/video_illustr_1.jpg',
        version: 'V1',
        selector: 'video',
        benefitsCount: 1,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.video',
        img: '/img/components/video/video_illustr_2.jpg',
        version: 'V2',
        selector: 'video',
        benefitsCount: 1,
        translationForVersion: true
      },
      {
        id: 3,
        name: 'components.video',
        img: '/img/components/video/video_illustr_3.jpg',
        version: 'V3',
        selector: 'video',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 4,
        name: 'components.video',
        img: '/img/components/video/video_illustr_4.jpg',
        version: 'V4',
        selector: 'video',
        benefitsCount: 1,
        translationForVersion: true
      },
      {
        id: 5,
        name: 'components.video',
        img: '/img/components/video/video_illustr_5.jpg',
        version: 'V5',
        selector: 'video',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 5,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_1.jpg',
        version: 'V1',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 6,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_2.jpg',
        version: 'V2',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 7,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_3.jpg',
        version: 'V3',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 8,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_4.jpg',
        version: 'V4',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 9,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_5.jpg',
        version: 'V5',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 10,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_6.png',
        version: 'V6',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 11,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_7.jpg',
        version: 'V7',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 11,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_8.png',
        version: 'V8',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 12,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_9.jpg',
        version: 'V9',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 13,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_10.jpg',
        version: 'V10',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 14,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_11.png',
        version: 'V11',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      },
      {
        id: 15,
        name: 'components.photo',
        img: '/img/components/image/image_illustr_12.jpg',
        version: 'V12',
        selector: 'photo',
        benefitsCount: 2,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.paragraphHeadline',
    selector: 'paragraph',
    items: [
      {
        id: 1,
        name: 'components.paragraph',
        img: '/img/components/paragraph/paragraph_only_illustr_1.jpg',
        version: 'V1',
        selector: 'paragraph',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.paragraph',
        img: '/img/components/paragraph/paragraph_only_illustr_2.jpg',
        version: 'V2',
        selector: 'paragraph',
        benefitsCount: 0,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.other',
    selector: ['faq', 'guarantee', 'form'],
    items: [
      {
        id: 1,
        name: 'components.faq',
        img: '/img/components/faqs/faq_illustr_1.jpg',
        version: 'V1',
        selector: 'faq',
        benefitsCount: 5,
        translationForVersion: true
      },
      {
        id: 2,
        name: 'components.faq',
        img: '/img/components/faqs/faq_illustr_2.jpg',
        version: 'V2',
        selector: 'faq',
        benefitsCount: 5,
        translationForVersion: true
      },
      {
        id: 3,
        name: 'components.faq',
        img: '/img/components/faqs/faq_illustr_3.jpg',
        version: 'V3',
        selector: 'faq',
        benefitsCount: 5,
        translationForVersion: true
      },
      {
        id: 4,
        name: 'components.guarantee',
        img: '/img/components/guarantee/guarantee_illustr_1.jpg',
        version: 'V1',
        selector: 'guarantee',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 5,
        name: 'components.guarantee',
        img: '/img/components/guarantee/guarantee_illustr_2.jpg',
        version: 'V2',
        selector: 'guarantee',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 6,
        name: 'components.guarantee',
        img: '/img/components/guarantee/guarantee_illustr_3.jpg',
        version: 'V3',
        selector: 'guarantee',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 7,
        name: 'components.event',
        img: '/img/components/events/event_illustr_1.jpg',
        version: 'V1',
        selector: 'event',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 8,
        name: 'components.event',
        img: '/img/components/events/event_illustr_2.jpg',
        version: 'V2',
        selector: 'event',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 9,
        name: 'components.event',
        img: '/img/components/events/event_illustr_3.jpg',
        version: 'V3',
        selector: 'event',
        benefitsCount: 3,
        translationForVersion: true
      },
      {
        id: 10,
        name: 'Custom HTML/CSS/Javascript',
        img: '/img/components/custom_html.jpg',
        version: 'V1',
        selector: 'custom_element',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 11,
        name: 'components.form',
        img: '/img/components/form/order_withbar_illustr_6.jpg',
        version: 'V1',
        selector: 'form',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 12,
        name: 'components.form',
        img: '/img/components/form/order_withbar_illustr_7.jpg',
        version: 'V2',
        selector: 'form',
        benefitsCount: 0,
        translationForVersion: true
      },

      {
        id: 13,
        name: 'components.form',
        img: '/img/components/form/order_withbar_illustr_8.jpg',
        version: 'V3',
        selector: 'form',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 14,
        name: 'components.form',
        img: '/img/components/form/order_withbar_illustr_9.jpg',
        version: 'V4',
        selector: 'form',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 15,
        name: 'components.form',
        img: '/img/components/form/order_withbar_illustr_10.jpg',
        version: 'V5',
        selector: 'form',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 16,
        name: 'components.form',
        img: '/img/components/form/order_withbar_illustr_11.jpg',
        version: 'V6',
        selector: 'form',
        benefitsCount: 0,
        translationForVersion: true
      },
      {
        id: 17,
        name: 'components.form',
        img: '/img/components/form/order_withbar_illustr_12.jpg',
        version: 'V7',
        selector: 'form',
        benefitsCount: 0,
        translationForVersion: true
      }
    ]
  },
  {
    name: 'components.toolsCalc',
    selector: ['form'],
    items: [
      (!process.env['API_URL']?.includes('.ai') || !process.env['API_URL']?.includes('.com')) && {
        id: 1,
        name: 'components.tools',
        img: '/img/components/toolSection.png',
        version: 'V1',
        selector: 'custom_element_tool',
        benefitsCount: 0,
        translationForVersion: true,
        hasLimitation: true
      }
    ]
  }
];

export const subTitlePromptVal = `
  Craft a numeric list of single-sentence descriptions for a digital book about "{$INPUT}".
  Each description should be concise and avoid the use of semicolons, commas, or any other punctuation.
`;

export const salesStaticDataFromDb = {
  sales: {
    topbar_headline: [
      '<p><strong>It is waiting for you!!</strong></p>',
      'What are you waiting for??',
      "You're going to LOVE this!!"
    ],
    universal_button: [
      '<p>YES!!  I want all of it!! {PRODUCT.PRICE}</p>',
      '<p>Sign Up Today - HERE  {PRODUCT.PRICE}</p>'
    ],
    headline_1: [
      '<p>Say Goodbye to the HASSLE and Enjoy a DOABLE Strategy!</p>',
      '<p>Discover the Secret Strategies - With Our Comprehensive Resource!</p>'
    ],
    subheadline_1: [
      "<p>Take control of your journey today! Sign up and start transforming. Don't wait, click here to join now and unleash the best version of yourself!</p>",
      'Join the thousands of satisfied customers who have already transformed their lives with our online guidebook. Hear their success stories and be inspired to start your own journey. Get started now!',
      'Are you tired of feeling overwhelmed? Let us demystify the process for you. Our guidebook is designed to fit your needs and goals!'
    ],
    minihero_sidebar_headline: [
      "<p><strong>If you're here, we bet it's because...</strong></p>",
      '<p>This is for you if... </p>',
      '<p>If you have thought...</p>'
    ],
    minihero_sidebar_paragraph: [
      "<p>Improve the foundation you already have! Develop and GROW.</p><p><br></p><p><br></p><p>Even if you're starting out.. we hear you!</p><p>Even if you've been at this for years!!</p><p><br></p><p><br></p><p>This guidebook will help you uplevel!</p>"
    ],
    headline_2: [
      '<p><strong>Are you tired of feeling overwhelmed? Let us help you.</strong></p>',
      'Experience hassle-free results, once and for all!'
    ],
    paragraph_2: [
      "<p>We know you've tried everything!  And it's still a struggle!  That's why you'll love this strategy-packed resource!!  Stop missing out and start growing.</p>",
      'Even if you have limited experience, our friendly guidebook provides the step-by-step process - designed for real people with busy lives.  Start growing today!'
    ],
    objcrush_shortblurp_1_blurp: [
      '<p>BUT!! By investing in this product, you will actually save time in the long run because it streamlines your tasks and eliminates unnecessary steps, giving you more time for yourself.</p>'
    ],
    objcrush_shortblurp_2_blurp: ['<p>Taking a chance on YOU is always, ALWAYS worth it!!</p>'],
    objcrush_shortblurp_3_blurp: [
      "<p>We get it.  Really, we do.  We have been exactly where you are.  We know the struggle and we don't want you to have to go through the rigmarole we survived!</p>"
    ],
    headline_3: [
      "<p><strong>This can be your reality, even if think you're at the wits-end!</strong></p>"
    ],
    subheadline_3: ['<p>Getting progress is SO MUCH easier when you have a plan. Promise!</p>'],
    thisisfor_headline: [
      '<p><strong>This is for you IF…</strong></p>',
      'You need this IF…',
      'Does this describe YOU?',
      'We made this for people who…'
    ],
    thisisfor_1_blurp: [
      '<p>You are OUT of time, you know you need results FAST!</p>',
      'You know that having a guide will save you TIME!  Lots of it!'
    ],
    thisisfor_2_blurp: [
      '<p>The idea of having a "plan" that is DOABLE sounds like a breath of fresh air!</p>',
      'You find it difficult to feel like there is progress without a "plan"'
    ],
    thisisfor_3_blurp: [
      "<p>You know that you can't keep spinning your wheels.  There is a better way!</p>",
      'You\'re unsure of what the "next step" is - or if it is even relevant for you!'
    ],
    aboutme_headline: [
      '<p><strong>Brought to you by me, {INPUT.YOURNAME},</strong></p>',
      "<p>Hey, I'm {INPUT.YOURNAME},</p>",
      "<p>Hello!!  I'm {INPUT.YOURNAME}!</p>",
      "<p><strong>About Me. I'm {INPUT.YOURNAME}.</strong></p>"
    ],
    aboutme_paragraph: [
      '<p>I\'ve been there. I know what it feels like to feel like I see-saw from "tactic" and "idea" to "more tactics" and even "more ideas". </p><p> It\'s not only demoralizing, it\'s also EXPENSIVE!</p><p><br></p><p>But also knowing - there has to be a way!  One that actually HELPS and doesn\'t cost an arm and a leg.</p><p><br></p><p>For years I desperately craved a guidebook or plan to help me!</p><p><br></p><p>At the time, it didn\'t exist, so I decided to create it!</p><p><br></p><p>And YOU can now get the same results I have experienced.</p>'
    ],
    cart_headline: ['<p><strong>How To Get Started</strong></p>'],
    cart_subheadline: [
      '<p>The <strong>{PRODUCT.NAME}</strong> will be yours in just three steps and in three minutes or less...</p>'
    ],
    cart_section_1_headline: ['<p><strong>Easy Sign-Up</strong></p>'],
    cart_section_1_subheadline: ['<p>We are fast, safe AND secure.</p>'],
    cart_section_2_headline: ['<p><strong>Instantly Download!</strong></p>'],
    cart_section_2_subheadline: ['<p>Your files are emailed to you in seconds!</p>'],
    cart_section_3_headline: ['<p><strong>Get Started </strong></p>'],
    cart_section_3_subheadline: ['<p>Just download and start implementing!</p>'],
    sp_cart_button: ["<p>Click and it's YOURS!!</p>"],
    guarantee_headline: [
      '<p>You have our PROMISE!</p>',
      "<p>The We-Know-You'll-Love-It Guarantee</p>",
      '<p>Our Perfect-Fit Guarantee!!</p>',
      '<p>Your Satisfaction is GUARANTEED!</p>',
      '<p>We offer a FULL GUARANTEE.</p>',
      '<p><strong>We offer a FULL GUARANTEE.</strong></p>'
    ],
    guarantee_subheadline: [
      '<p>If you are not completely happy, you’ll get your money back. No questions asked. You get a full, prompt &amp; friendly refund - and we’ll part ways as friends.</p>'
    ],
    faq_headline: ['<p><strong>FAQ’s</strong></p>'],
    faq_subheadline: ['<p>You have questions!! We have ANSWERS!</p>'],
    faq_qa_1_question: ['<p><strong>How Do I Know {PRODUCT.NAME} Is Right For Me?</strong></p>'],
    faq_qa_1_answer: [
      "<p>The <strong>{PRODUCT.NAME}</strong> is for anyone looking for a way to save time and get results - without the stress!!  My guess, if you've gotten this far down in this sales page, that. is. you!  Really!!</p>"
    ],
    faq_qa_2_question: ['<p><strong>Can I Print More Than One?</strong></p>'],
    faq_qa_2_answer: [
      '<p>Your purchase of the <strong>{PRODUCT.NAME}</strong> covers your individual needs (today and many years over). If you wish to print multiple copies, feel free to do so. Sharing the files with others is strictly prohibited under our copyright.</p>'
    ],
    faq_qa_3_question: [
      '<p><strong>How  Soon After Purchasing Will I Recieve My Product?</strong></p>'
    ],
    faq_qa_3_answer: [
      '<p>(Almost) immediately! Your purchase will be delivered directly on the confirmation page and will also be emailed to you, via the email address you provide at checkout. It should arrive within 5-10 minutes of your purchase.</p>'
    ],
    faq_qa_4_question: ["<p><strong>Help! I Can't Open the File!</strong></p>"],
    faq_qa_4_answer: [
      '<p>To open a PDF file, you will need a program that is probably already installed on your computer. This program will enable you to view PDF files from another site or source. If you don’t have a program, you can get one free with a simple Google search.</p>'
    ],
    faq_qa_5_question: ['<p><strong>Is This REALLY Worth the Cost?</strong></p>'],
    faq_qa_5_answer: [
      '<p>Absolutely! The <strong>{PRODUCT.NAME}</strong> will pay for itself as soon as you start using it and for many years to come. Buy it once and print it over and over again. You will not find a better, more practical, and more comprehensive bundle at a lower price.</p>'
    ],
    detailed_headline: ['<p><strong>What are you waiting for?! </strong></p>'],
    detailed_headline_bullet_1: ['<p>Brilliant Checklist</p>'],
    detailed_headline_bullet_2: ['<p>Fool-Proof Strategies</p>'],
    detailed_headline_bullet_3: ['<p>Time-Saving Hacks!</p>'],
    objcrush_shortblurp_3_headline: [
      "<p><strong>You're terrified that you are not able to put in the effort... </strong></p>"
    ],
    objcrush_shortblurp_2_headline: [
      "<p><strong>You're wondering if it's worth the cost... financially AND mentally!</strong></p>"
    ],
    objcrush_shortblurp_1_headline: [
      "<p><strong>You're spinning your wheels trying fad-after-fad and getting nowhere...</strong></p>"
    ],
    tx_download_button: ['<p>👉 Download Your {PRODUCT NAME} Here 👈 </p>'],
    headline_6: [
      '<p><strong>Join our community - and GET SUPPORT!!</strong></p>',
      '<p>AND!!  We have a community for YOU!!</p>',
      '<p>PLUS!!  Join Our Program!!</p>'
    ],
    tx_join_button: ['<p>Sign Up And Uplevel With Us!!</p>'],
    video_subheading_1: ['<p>Step 1:</p>'],
    video_subheading_2: ['<p>Step 2:</p>'],
    video_subheading_3: ['<p>Step 3:</p>'],
    tx_cta_v3_heading_e: [
      '<p>We have a community to help you, cheer you on, and help you get results that stick.</p>'
    ],
    tx_button: ['<p>Join the Community!</p>'],
    headline_4: ["<p><strong>It's YOURS!!  Just download it below!</strong></p>"],
    subheadline_4: [
      '<p>Start by downloading your product, and then take action!  Put your guidebook into action!</p>'
    ],
    tx_sidebar_headline: [
      '<p><strong>Your Journey Begins Today!!</strong></p>',
      "<p><strong>It's Yours!!  Download it now!</strong></p>"
    ],
    tx_sidebar_paragraph: [
      "<p>We are pleased to inform you that your purchase of the <strong>{PRODUCT.NAME}</strong> is now ready for download. Simply click on the provided link to access the files.</p><p><br></p><p>In addition, we invite you to join our vibrant community of learners for ongoing support and inspiration. Connect with fellow beginners, ask questions, and share your progress by joining our online forum. Together, let's grow!</p>"
    ],
    headline_5: ['<p><strong>Click The Button to Download Your {PRODUCT.NAME}</strong></p>'],
    subheadline_5: [
      "<p>We are here to support you!  This is not just a <strong>{PRODUCT.NAME}</strong>, it's a whole blueprint!!</p>"
    ],
    bold_blurp_1: ['<p>Just imagine never having to stress about what to do next!</p>'],
    regular_blurp_1: [
      '<p>You have the steps you need to effectively plan, create, and grow!!  Seeing improvement with our <strong>{PRODUCT.NAME}</strong></p>'
    ],
    tx_cta_v3_heading_c: [
      '<p>Get support and encouragement, share successes &amp; challenges, and stay motivated with the help of like-minded friends.</p>'
    ]
  }
};

export const salesPromptDataFromDb = {
  sales: [
    {
      prompt:
        'Please create a call-to-action 4-6 word headline on the topic of {$INPUT} for a sales page.  Do not use quotation marks or punctuation. ',
      keys: ['topbar_headline'],
      mapping: {
        topbar_headline: 'alltext'
      },
      types: {
        topbar_headline: 'field'
      }
    },
    {
      prompt:
        'Please create a 6-to-8 word How-To subheadline and a two-sentence description. Write in complete sentences. Do not use quotation marks, colons, or punctuation. Format the responses as "How-To Headline" and "How-To Description". For the topic: {$INPUT}. ',
      keys: ['headline_1', 'subheadline_1'],
      mapping: {
        headline_1: 'how-to headline',
        subheadline_1: 'how-to description'
      },
      types: {
        headline_1: 'field',
        subheadline_1: 'field'
      }
    },
    {
      prompt:
        'Please create a 3-sentence paragraph with three sentences.  Have the paragraph start with "you wanted to...".  After the paragraph have a 1-sentence Call-to-Action.   For the topic {$INPUT}.  Add paragraph breaks after each sentence.',
      keys: ['minihero_sidebar_paragraph'],
      mapping: {
        minihero_sidebar_paragraph: 'alltext'
      },
      types: {
        minihero_sidebar_paragraph: 'field'
      },
      is_breakline_enabled: true
    },
    {
      prompt:
        'On the topic: {$INPUT}, Please create a 6-to-8-word Promised result headline and a three-sentence description. Write in complete thoughts. Do not use quotation marks or punctuation. Format the responses as \n"Promised result Headline:" \n"Promised result Description:"',
      keys: ['headline_2', 'paragraph_2'],
      mapping: {
        headline_2: 'promised result headline',
        paragraph_2: 'promised result description'
      },
      types: {
        headline_2: 'field',
        paragraph_2: 'field'
      },
      is_breakline_enabled: false
    },
    {
      prompt:
        'Fill the fields with creative and motivational words or sentences based on the product name. Based on the examples, come up with creative page fields.\n\nProduct name: Cleaning Plan for Cluttered Homes\nPage 1 Promise: Be MORE productive than you ever thought was possible.\nPage 2 Promise: Clean up all the zones in your home in 15 Minutes (or less).\nPage 3 Promise: Keep your home cleaner as you now have your space organized.\nPage 1 Solution: The kitchen. Your fridge. Laundry Systems. Those "storage" zones.  ALL of it! Get the whole house organized!!\nPage 2 Solution: It\'s not another "list" it is a whole strategy to help you get clean, FAST - and stay that way.\nPage 3 Solution: Getting a space clean is more than getting rid of a mess. It\'s about creating a system that is easy to stick with.\n\nProduct Name: {$INPUT}',
      keys: [
        'objcrush_shortblurp_1_headline',
        'objcrush_shortblurp_1_blurp',
        'objcrush_shortblurp_2_headline',
        'objcrush_shortblurp_2_blurp',
        'objcrush_shortblurp_3_headline',
        'objcrush_shortblurp_3_blurp'
      ],
      mapping: {
        objcrush_shortblurp_1_headline: 'page 1 promise',
        objcrush_shortblurp_1_blurp: 'page 1 solution',
        objcrush_shortblurp_2_headline: 'page 2 promise',
        objcrush_shortblurp_2_blurp: 'page 2 solution',
        objcrush_shortblurp_3_headline: 'page 3 promise',
        objcrush_shortblurp_3_blurp: 'page 3 solution'
      },
      types: {
        objcrush_shortblurp_1_headline: 'field',
        objcrush_shortblurp_1_blurp: 'field',
        objcrush_shortblurp_2_headline: 'field',
        objcrush_shortblurp_2_blurp: 'field',
        objcrush_shortblurp_3_headline: 'field',
        objcrush_shortblurp_3_blurp: 'field'
      }
    },
    {
      prompt:
        'PROMPT: Please create a 6-to-8-word Promised result headline and a two-sentence description. Write in complete thoughts. Do not use quotation marks or punctuation. Format the responses as "Promised result Headline" and "Promised result Description". For the topic: {$INPUT}.',
      keys: ['headline_3', 'subheadline_3'],
      mapping: {
        headline_3: 'promised result headline',
        subheadline_3: 'promised result description'
      },
      types: {
        headline_3: 'field',
        subheadline_3: 'field'
      }
    },
    {
      prompt:
        'PROMPT: Finish the following sentences on the topic of {$INPUT}\n"Sentence 1: This result is for you if..."\n"Sentence 2: This success is for you if..."\n"Sentence 3: This goal is for you if..."',
      keys: ['thisisfor_2_blurp', 'thisisfor_3_blurp', 'thisisfor_1_blurp'],
      mapping: {
        thisisfor_1_blurp: 'sentence 1',
        thisisfor_2_blurp: 'sentence 2',
        thisisfor_3_blurp: 'sentence 3'
      },
      types: {
        thisisfor_1_blurp: 'field',
        thisisfor_2_blurp: 'field',
        thisisfor_3_blurp: 'field'
      }
    },
    {
      prompt:
        'Use the following example problems, and apply them to the new product.  Tell me in 3 sentences, how your product solves these problems.  Do not use the words "Additionally", "Our product", or  "Lastly" and please include an exclamation point.\n\nExample Product name: Cleaning Plan for Cluttered Homes\nExample Problems: You feel lost wondering what to do next, looking at a messy room.  You\'re looking for time-saving tricks to tackle all of the messiest areas - in minutes! You want to keep a space clean!\n\nProduct Name: {$INPUT}',
      keys: ['thisisfor_paragraph'],
      mapping: {
        thisisfor_paragraph: 'alltext'
      },
      types: {
        thisisfor_paragraph: 'field'
      }
    },
    {
      prompt:
        'Use the voice of a cheerful friend, using simple English, and write a 150-word "about me" with an encouraging "take Action" and tell them about how you "got started" on the topic of {$INPUT} to use as the first page of a book.  Do not refer to yourself with a name, do not use the word book.  Do not use the words "Hey there" or "hi" or "hey" or "so glad you\'re here".  ',
      keys: ['aboutme_paragraph'],
      mapping: {
        aboutme_paragraph: 'alltext'
      },
      types: {
        aboutme_paragraph: 'field'
      },
      is_breakline_enabled: false
    },
    {
      prompt:
        'please rephrase this, using only 9 words, on the topic of  {$INPUT}: "Say goodbye to feeling left behind or aimless"',
      keys: ['cart_section_3_subheadline'],
      mapping: {
        cart_section_3_subheadline: 'alltext'
      },
      types: {
        cart_section_3_subheadline: 'field'
      }
    },
    {
      prompt:
        'Please create a 4-6 word scarcity headline on the topic of {$INPUT} for a sales page.  Do not use quotation marks or punctuation.',
      keys: ['detailed_headline'],
      mapping: {
        detailed_headline: 'alltext'
      },
      types: {
        detailed_headline: 'field'
      }
    },
    {
      prompt:
        'PROMPT: Please create a 6-to-8-word headline and a one-sentence description starting with the words "It\'s Yours!..." on the topic of {$INPUT}. Write in complete thoughts. Do not use quotation marks or punctuation. Format the responses as "Download Headline" and "Download Description". For the topic: {$INPUT}.',
      keys: ['headline_4', 'subheadline_4'],
      mapping: {
        headline_4: 'download headline',
        subheadline_4: 'download description'
      },
      types: {
        headline_4: 'field',
        subheadline_4: 'field'
      }
    },
    {
      prompt:
        'Please create a three-sentence paragraph telling users that the {$INPUT} product I purchased is ready to download and ask them to join a community for support. Start each new sentence in it\'s own paragraph. Format the section as "To Our User:". Do not close or sign the paragraph.  ',
      keys: ['tx_sidebar_paragraph'],
      mapping: {
        tx_sidebar_paragraph: 'to our user'
      },
      types: {
        tx_sidebar_paragraph: 'field'
      }
    },
    {
      prompt:
        'PROMPT: Please create a 6-to-8-word benefit-featuring headline and a two-sentence description on the topic: {$INPUT}. Write in complete thoughts. Do not use quotation marks or punctuation. Format the responses as "Benefit Headline" and "Benefit Description".',
      keys: ['headline_5', 'subheadline_5'],
      mapping: {
        headline_5: 'benefit headline',
        subheadline_5: 'benefit description'
      },
      types: {
        headline_5: 'field',
        subheadline_5: 'field'
      }
    },
    {
      prompt:
        'PROMPT: Please finish these two sentences on the topic of {$INPUT}:\nSentence 1: Just imagine never\nSentence 2: You have the steps you need to',
      keys: ['bold_blurp_1', 'regular_blurp_1'],
      mapping: {
        bold_blurp_1: 'sentence 1',
        regular_blurp_1: 'sentence 2'
      },
      types: {
        bold_blurp_1: 'field',
        regular_blurp_1: 'field'
      }
    },
    {
      prompt:
        'PROMPT: please finish these two sentences on the topic of {$INPUT}:\nSentence 1: looking to accelerate your results\nSentence 2: We have a community of people just like you',
      keys: ['bold_blurp_2', 'regular_blurp_2'],
      mapping: {
        bold_blurp_2: 'sentence 1',
        regular_blurp_2: 'sentence 2'
      },
      types: {
        bold_blurp_2: 'field',
        regular_blurp_2: 'field'
      }
    },
    {
      prompt:
        'Please create three steps and for each step explain what someone will learn and why it is important in a short 3-sentence paragraph.  Label each step as \n"Task 1: Step 1..."\n"Explanation 1"\n"Task 2: Step 2... "\n"Explanation 2"\n"Task 3:Step 3..."\n"Explanation 3".  \nOn the topic of {$INPUT}.',
      keys: [
        'video_heading_1',
        'video_paragraph_1',
        'video_heading_2',
        'video_paragraph_2',
        'video_heading_3',
        'video_paragraph_3'
      ],
      mapping: {
        video_heading_1: 'task 1',
        video_heading_2: 'task 2',
        video_heading_3: 'task 3',
        video_paragraph_1: 'explanation 1',
        video_paragraph_2: 'explanation 2',
        video_paragraph_3: 'explanation 3'
      },
      types: {
        video_heading_1: 'field',
        video_paragraph_1: 'field',
        video_heading_2: 'field',
        video_paragraph_2: 'field',
        video_heading_3: 'field',
        video_paragraph_3: 'field'
      }
    }
  ],
  lead: []
};

export const baseKeysForPriceButtons = ['topbar_', 'hero_'];

export const FooterLinks = {
  listLinks: [
    // {
    //   linkText: "Frequently Asked Questions",
    //   path: "",
    // },
    {
      linkText: 'footer.learnWithUs',
      path: 'https://learn.pagewheel.com'
    },
    {
      linkText: 'footer.featureOrBug',
      path: 'hello@pagewheel.com'
    },
    {
      linkText: 'footer.becomePartner',
      path: 'https://pagewheel.com/become-a-partner'
    }
  ],
  links: [
    {
      linkText: 'footer.terms',
      path: 'https://learn.pagewheel.com/privacy-policy/'
    },
    {
      linkText: 'footer.privacy',
      path: 'https://learn.pagewheel.com/privacy-policy/'
    },
    {
      linkText: 'footer.contactUs',
      path: 'mailto:hello@pagewheel.com'
    }
  ]
};

export const purchasePlans = [
  {
    apiTitle: 'bizzy_free',
    title: 'Free',
    monthCost: 0,
    yearCost: 0,
    selectedCost: 0,
    content:
      'The perfect way to start your journey with Bizzy. You can create and brand your first product. Download and SELL!!',
    features: [
      'Design Your Project',
      'Access to over 25 PDF Batches',
      'Fully Editable Templates',
      'All Products Branded to YOU!!',
      'Commercial Resell Rights'
    ],
    appearance: null,
    productsCreated: 0,
    allowedProducts: 1
  },
  {
    apiTitle: 'bizzy_starter',
    title: 'The Starter',
    monthCost: 27,
    yearCost: 243,
    selectedCost: 27,
    content:
      'All of the features in our free version - AND we will even build and host your sales assets FOR YOU!!',
    features: [
      'Full Library with 100+ Pages',
      'Ability to Add Video Elements',
      'Unlimited Edits',
      'Unlimited Pages & Products',
      'Brand Kit for Social Promotion',
      '5 Sales Pages Written For You',
      'Hosting of Your Product Website'
    ],
    appearance: null,
    productsCreated: 0,
    allowedProducts: 5
  },
  {
    apiTitle: 'bizzy_business',
    title: 'Business',
    monthCost: 47,
    yearCost: 423,
    selectedCost: 47,
    content:
      'For the big businesses with enterprise-level needs. This is for companies who want access to support and trainings for growth.',
    features: [
      'Unlimited Products',
      'Unlimited Sales Pages',
      'Priority Support',
      'Ability to Publish with POD',
      'Create Fillable PDF Products'
    ],
    appearance: 'black',
    productsCreated: 0,
    allowedProducts: 1000000
  },
  {
    apiTitle: 'pw_enterprise_beta',
    title: 'Enterprise',
    monthCost: 99,
    yearCost: 891,
    selectedCost: 891,
    content: 'For the big businesses, get high level customizations and agency-tools',
    features: ['Serve Up to 10 Sub-Accounts', 'Agency Services Enabled', 'Whitelabel Capabilities'],
    appearance: 'black',
    productsCreated: 0,
    allowedProducts: 1000000
  }
];

export const fontSizeMapper = {
  LARGE_HEADLINE: {
    '1920': '50px',
    '1252': '44px',
    '991': '40px',
    '768': '34.5px',
    '575': '32.5px',
    '375': '29.5px'
  },
  HEADLINE: {
    '1920': '47px',
    '1252': '41px',
    '991': '37px',
    '768': '32.5px',
    '575': '30.5px',
    '375': '27.5px'
  },
  PARAGRAPH: {
    '1920': '26px',
    '1252': '20px',
    '991': '20px',
    '768': '22px',
    '575': '20px',
    '375': '20px'
  },
  SUBHEADLINE: {
    '1920': '30px',
    '1252': '24px',
    '991': '22px',
    '768': '22px',
    '575': '20px',
    '375': '20px'
  },
  TINY_TEXT: {
    '1920': '22px',
    '1252': '20px',
    '991': '20px',
    '768': '20px',
    '575': '20px',
    '375': '20px'
  }
};

export const ZOOM_SIZE = 0.72;
export const EDITOR_ZOOM_SIZE = ZOOM_SIZE - 0.16646;

export const ALERT_MESSAGE = {
  EDITING_IS_DISABLED_FOR_CHILD:
    'Editing is disabled for your account, please contact the parent account holder.'
};

export const AddonPlans = {
  accounts_first: {
    items: [
      {
        id: 0,
        accTitle: 'Add 1 Account ',
        accDesc: '$19 per user each month',
        monthly: 19,
        yearly: 190,
        name: 'addon_1_child'
      },
      {
        id: 1,
        accTitle: 'Add 10 Accounts',
        accDesc: '$15 per user each month',
        monthly: 150,
        yearly: 1800,
        name: 'addon_10_child'
      },
      {
        id: 2,
        accTitle: 'Add 100 Accounts',
        accDesc: '$11 per user each month',
        monthly: 1100,
        yearly: 13200,
        name: 'addon_100_child'
      }
    ]
  },
  accounts_second: {
    items: [
      {
        id: 0,
        accTitle: 'Add 250 Accounts',
        accDesc: '$8 per user each month',
        monthly: 2000,
        yearly: 24000,
        name: 'addon_250_child'
      },
      {
        id: 1,
        accTitle: 'Add 500 Accounts',
        accDesc: '$6 per user each month',
        monthly: 3000,
        yearly: 36000,
        name: 'addon_500_child'
      },
      {
        id: 2,
        accTitle: 'Add 1000 Accounts',
        accDesc: '$5 per user each month',
        monthly: 5000,
        yearly: 60000,
        name: 'addon_1000_child'
      }
    ]
  }
};

export const languages = {
  en: {
    name: 'English',
    icon: '/img/languages/united-states.png',
    lang: 'English',
    active: true
  },
  'es-mx': {
    name: 'Mexican Spanish',
    icon: '/img/languages/mexico.png',
    lang: 'Mexican Spanish',
    active: true
  },
  br: {
    name: 'Brazilian Portuguese',
    icon: '/img/languages/brazil.png',
    lang: 'Brazilian Portuguese',
    active: true
  },
  pt: {
    name: 'Portuguese',
    icon: '/img/languages/portugal.png',
    lang: 'Portuguese',
    active: true
  },
  es: {
    name: 'Spanish',
    icon: '/img/languages/spain.png',
    lang: 'Spanish (Castilian)',
    active: true
  },
  de: {
    name: 'German',
    icon: '/img/languages/germany.png',
    lang: 'German',
    active: true
  },
  it: {
    name: 'Italian',
    icon: '/img/languages/italy.png',
    lang: 'Italian',
    active: true
  },
  fr: {
    name: 'French',
    icon: '/img/languages/france.png',
    lang: 'French',
    active: false
  },
  nl: {
    name: 'Dutch',
    icon: '/img/languages/netherland.png',
    lang: 'Dutch',
    active: true
  },
  da: {
    name: 'Danish',
    icon: '/img/languages/denmark.png',
    lang: 'Danish',
    active: false
  },
  bg: {
    name: 'Bulgarian',
    icon: '/img/languages/bulgaria.png',
    lang: '/img/languages/Bulgarian',
    active: false
  },
  hu: {
    name: 'Hungarian',
    icon: '/img/languages/hungary.png',
    lang: 'Hungarian',
    active: false
  },
  sl: {
    name: 'Slovenia',
    icon: '/img/languages/slovenia.png',
    lang: 'Slovenian',
    active: true
  },
  'en-gb': {
    name: 'British English',
    icon: '/img/languages/united_kingdom.png',
    lang: 'British English',
    active: true
  },
  ka: {
    name: 'Georgian',
    icon: '/img/languages/georgia.png',
    lang: 'Georgian',
    active: false
  }
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const languageMapping = {
  en: 'English',
  'es-mx': 'Spanish',
  br: 'Brazilian Portuguese',
  pt: 'Portuguese',
  es: 'Spanish',
  de: 'German',
  it: 'Italian',
  sl: 'Slovenian',
  nl: 'Dutch',
  ro: 'Romanian',
  bg: 'Bulgarian',
  fr: 'French',
  'en-gb': 'British English',
  ka: 'Georgian'
} as Record<string, string>;

export const CURRENCIES = {
  USD: '$',
  GBP: '£',
  AED: 'د.إ',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BMD: '$',
  BND: '$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'CHF',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EGP: '£',
  ETB: 'Br',
  EUR: '€',
  FJD: '$',
  GEL: '₾',
  GIP: '£',
  GMD: 'D',
  HKD: '$',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'kr',
  JMD: '$',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KYD: '$',
  KZT: '₸',
  LBP: 'ل.ل',
  LKR: 'Rs',
  LRD: '$',
  LSL: 'L',
  MAD: 'د.م.',
  MDL: 'L',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'P',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NOK: 'kr',
  NPR: 'Rs',
  NZD: '$',
  PGK: 'K',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  QAR: 'ر.ق',
  RON: 'lei',
  RSD: 'дин',
  RUB: '₽',
  SAR: '﷼',
  SBD: '$',
  SCR: 'Rs',
  SEK: 'kr',
  SGD: '$',
  SLE: 'Le',
  SOS: 'Sh',
  SZL: 'E',
  THB: '฿',
  TJS: 'ЅМ',
  TOP: 'T$',
  TRY: '₺',
  TTD: 'TT$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  WST: 'WS$',
  XCD: '$',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  AFN: '؋',
  AOA: 'Kz',
  ARS: '$',
  BOB: 'Bs.',
  BRL: 'R$',
  CLP: '$',
  COP: '$',
  CRC: '₡',
  CVE: '$',
  DJF: 'Fdj',
  FKP: '£',
  GNF: 'Fr',
  GTQ: 'Q',
  HNL: 'L',
  LAK: '₭',
  MUR: '₨',
  NIO: 'C$',
  PAB: 'B/.',
  PEN: 'S/.',
  PYG: '₲',
  SHP: '£',
  SRD: '$',
  STD: 'Db',
  UYU: '$U',
  XOF: 'CFA',
  XPF: '₣'
};

export const PRIMARY_CURRENCY_SYMBOLS = {
  $: 'USD',
  '£': 'GBP'
};

export const TOP_USED_CURRENCIES = {
  USD: '$',
  EUR: '€',
  CAD: '$',
  MXN: '$',
  GBP: '£',
  EGP: '£',
  GIP: '£',
  FKP: '£',
  SHP: '£'
};

export const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const stateCodes = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
};
