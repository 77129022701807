import {
  capitalizeFirstLetter,
  customDeliveryUrl,
  ApiService,
  getCustomWebsiteUrl
} from 'shared/helpers/index';

import useAuth from 'shared/hooks/useAuth';
import useSales from 'shared/hooks/useSales';
import useBook from 'shared/hooks/useBook';
import useOpenAi from './useOpenAi';
import type { PageData } from '../types';

interface DeliveryEmailPayload {
  sales_page: number;
  preview_text: string;
  headline: string;
  body: string;
  is_published?: boolean;
}

/**
 * @function useDeliveryEmail
 *
 * @description This hook is used for handling the delivery email for the book.
 * Here we have methods to get the delivery email data, initialize the delivery email,
 * and update the delivery email. Currently we support 2 versions for 1.0 and 2.0.
 */
const useDeliveryEmail = () => {
  const { bookName } = useBook();
  const {
    downloadUrl,
    salesPageValues,
    communityUrl,
    deliveryPageValues,
    ai,
    subDomain,
    deliveryToken
  } = useSales();
  const { user } = useAuth();
  const { runAi } = useOpenAi();

  const initializeDeliveryEmail = async (
    salesPageId: number,
    funnelType: string,
    forceInitialization = false,
    salesPageValuesUpdated: PageData | undefined | null = undefined,
    deliveryPageValuesUpdated: PageData | undefined | null = undefined
  ) => {
    const deliveryEmailResponse = await ApiService.get(
      `/sales/book-delivery-email/${salesPageId}/`
    );
    // Dont Initialize if already published, unless forced
    if (deliveryEmailResponse.data.is_published && !forceInitialization) return;
    const response = await ApiService.get(`sales/sales-page/delivery-email-templates/`);
    const template = response.data[funnelType] || response.data['short_and_sweet'];
    salesPageValuesUpdated = salesPageValuesUpdated || salesPageValues;
    deliveryPageValuesUpdated = deliveryPageValuesUpdated || deliveryPageValues;
    if (!salesPageValuesUpdated || !deliveryPageValuesUpdated) return;
    const deliveryEmailData = await deliveryEmailGenerator(
      template.headline,
      template.preview,
      template.body,
      template.ai_runs || [],
      ai,
      salesPageValuesUpdated,
      deliveryPageValuesUpdated
    );
    let preview_text = deliveryEmailData?.preview;
    if (preview_text.length >= 180) {
      const index = preview_text.indexOf('.');
      if (index !== -1) {
        preview_text = preview_text.slice(0, index + 1);
      }
      if (preview_text.length >= 180) {
        preview_text = preview_text.slice(0, 179);
      }
    }
    let headline = deliveryEmailData?.headline;
    if (headline?.length >= 120) {
      const index = headline.indexOf('.');
      if (index !== -1) {
        headline = headline.slice(0, index + 1);
      }
      if (headline.length >= 120) {
        headline = headline.slice(0, 179);
      }
    }
    await updateDeliveryEmail(salesPageId, {
      ...{
        headline,
        preview_text,
        body: deliveryEmailData.body
      },
      sales_page: salesPageId
    });
  };

  const getDeliveryEmailBodyLinkSection = (
    subdomain: string,
    subheadline_1: string,
    check_section: string
  ) => {
    const tx_join_button_link = communityUrl || '';
    // const headline_6 = '';
    const tx_join_button = 'Join Now!';
    subheadline_1 = subheadline_1.replaceAll('"', '').replace('\n', '');
    check_section = check_section.replaceAll('"', '').replace('\n', '');

    const printThePdfText = downloadUrl
      ? `<a href="${downloadUrl}" rel="noopener noreferrer" target="_blank"> Print the PDF </a>`
      : 'Print the PDF';

    const thankyouUrl = customDeliveryUrl(subdomain, deliveryToken);

    const bodyTemplate = `<p>We are so excited that you’ve joined us!  Let’s ${subheadline_1} - TOGETHER!</p>
        <p><br></p>
        <p>${check_section}</p>
        <p><br></p>
        <p>Your steps:</p>
        <p>1. <a href="${thankyouUrl}" rel="noopener noreferrer" target="_blank">Go download your ${bookName}</a></p>
        <p>2.${printThePdfText}</p>
        <p>3. Take it further!</p>
        <p><br></p>
        ${
          tx_join_button
            ? `<p><a href="${tx_join_button_link}" rel="noopener noreferrer" target="_blank">${tx_join_button}</a></p>`
            : ''
        }
        <p>Grateful you’ve joined us!</p>
        <p><br></p>
        ${user?.username ? `<p>${capitalizeFirstLetter(user.username || '')}</p>` : ''}
        ${user?.business_name ? `<p>${capitalizeFirstLetter(user.business_name || '')}</p>` : ''}
    `;

    return bodyTemplate;
  };

  const updateDeliveryEmail = async (
    salesPageId: number,
    deliveryEmailPayload: DeliveryEmailPayload
  ) => {
    await ApiService.patch(`/sales/book-delivery-email/${salesPageId}/`, deliveryEmailPayload);
  };

  const cleanUpAiOutput = (aiOutput: string) => {
    return aiOutput.replaceAll('"', '');
  };

  const cleanSalesOutput = (salesOutput: string) => {
    return salesOutput
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replaceAll('"', '')
      .replace('\n', '');
  };

  function parseTemplate(template: string, data: Record<string, string>) {
    if (!template) return '';
    template = template?.replace(
      /\[PDF_LINK:(.*?)\]/g,
      `<a href="${downloadUrl}" rel="noopener noreferrer" target="_blank">$1</a>`
    );
    template = template.replace(
      /\[DELIVERY_URL:(.*?)\]/g,
      `<a href="${customDeliveryUrl(subDomain, deliveryToken)}" rel="noopener noreferrer" target="_blank">$1</a>`
    );
    template = template.replace(/\[(.*?):(.*?)\]/g, (_, urlKey, linkText) => {
      // Check if the URL key exists in the data and use its value or a default #
      const url = data[urlKey] || '#';
      return `<a href="${url}" rel="noopener noreferrer" target="_blank">${linkText}</a>`;
    });

    return template?.replace(/{(.*?)}/g, (_, variableGroup) => {
      const parts = variableGroup.split('|').map((v: string) => v.trim());
      let defaultText = '';
      let leftText = '';
      let rightText = '';

      for (const part of parts) {
        if (part.startsWith('left(')) {
          leftText = part.slice(5, -1);
        } else if (part.startsWith('right(')) {
          rightText = part.slice(6, -1);
        }
      }

      for (const part of parts) {
        if (part.startsWith('default(')) {
          defaultText = part.slice(8, -1);
        } else if (
          Object.prototype.hasOwnProperty.call(data, part) &&
          data[part] !== undefined &&
          data[part] !== null
        ) {
          return leftText + data[part] + rightText;
        }
      }
      if (defaultText) {
        return leftText + defaultText + rightText;
      }
      return defaultText.replaceAll('&nbsp;', ' ');
    });
  }

  function parseSalesDataForEmail(data: PageData, salesType: 'sales' | 'delivery') {
    const result = {} as Record<string, string>;
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (typeof value !== 'object') return;

      if (value.meta?.['url']) {
        result[`${key}__meta__url`] =
          `<a href="${value.meta['url']}" rel="noopener noreferrer" target="_blank">${value.meta['url']}</a>`;
      }
      if (value.meta?.['eventUrl']) {
        result[`${key}__meta__eventUrl`] =
          `<a href="${value.meta['eventUrl']}" rel="noopener noreferrer" target="_blank">${value.meta['eventUrl']}</a>`;
      }
      if (value.meta?.['calendarUrl']) {
        result[`${key}__meta__calendarUrl`] =
          `<a href="${value.meta['calendarUrl']}" rel="noopener noreferrer" target="_blank">${value.meta['calendarUrl']}</a>`;
      }

      if (value.title?.val) {
        result[`${key}__title`] = cleanSalesOutput(value.title.val);
        result[`${salesType}_${key}__title`] = cleanSalesOutput(value.title.val);
      }
      if (value.content?.val) {
        result[`${key}__content`] = cleanSalesOutput(value.content.val);
        result[`${salesType}_${key}__content`] = cleanSalesOutput(value.content.val);
      }
      if (value.button?.val) {
        result[`${key}__button`] = cleanSalesOutput(value.button.val);
        result[`${salesType}_${key}__content`] = cleanSalesOutput(value.button.val);
      }
      if (!value.benefits) return;
      value.benefits.forEach((benefit, index) => {
        if (benefit.title?.val) {
          result[`${key}__benefits__${index}__title`] = cleanSalesOutput(benefit.title.val);
          result[`${salesType}_${key}__benefits__${index}__title`] = cleanSalesOutput(
            benefit.title.val
          );
        }
        if (benefit.content?.val) {
          result[`${salesType}_${key}__benefits__${index}__content`] = cleanSalesOutput(
            benefit.content.val
          );
        }
        if (benefit.button?.val) {
          result[`${salesType}_${key}__benefits__${index}__button`] = cleanSalesOutput(
            benefit.button.val
          );
        }
      });
    });

    return result;
  }

  const deliveryEmailGenerator = async (
    headline: string,
    preview: string,
    body: string,
    aiRuns: string[],
    aiValue: string,
    salesData: PageData,
    deliveryData: PageData
  ) => {
    aiRuns = aiRuns.filter((run) => run !== '');
    const aiResponses = await Promise.all(aiRuns.map((run) => runAi(run, aiValue)));
    const aiDataMapping = aiResponses.reduce((acc, val, index) => {
      acc[`AI_RUN_${index}`] = cleanUpAiOutput(val);
      return acc;
    }, {});

    const salesDataMapping = parseSalesDataForEmail(salesData, 'sales');
    const deliveryDataMapping = parseSalesDataForEmail(deliveryData, 'delivery');
    const dataMapping = { ...salesDataMapping, ...deliveryDataMapping, ...aiDataMapping };
    dataMapping['PRODUCT.NAME'] = bookName || 'Sample Product';
    dataMapping['USER.NAME'] = user?.entity?.name || null;
    dataMapping['USER.BUSINESS_NAME'] = user?.business_name || null;
    dataMapping['USER.EMAIL'] = user?.business_email || user?.email || null;
    dataMapping['SALES_URL'] = customDeliveryUrl(subDomain, deliveryToken);
    dataMapping['DELIVERY_URL'] = getCustomWebsiteUrl(subDomain);

    const headlineResult = parseTemplate(headline, dataMapping);
    const previewResult = parseTemplate(preview, dataMapping);
    const bodyResult = parseTemplate(body, dataMapping);

    return {
      headline: headlineResult,
      preview: previewResult,
      body: bodyResult
    };
  };

  return {
    updateDeliveryEmail,
    initializeDeliveryEmail,
    getDeliveryEmailBodyLinkSection,
    deliveryEmailGenerator,
    parseSalesDataForEmail
  };
};

export default useDeliveryEmail;
