import { useMemo, useState, type FC } from 'react';
import type { HeroBasicProps } from './types';

import styled, { css } from 'styled-components';

import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  COLOR_BLUE_3,
  COLOR_YELLOW,
  ApiService
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper, Button, Mockup } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Image as ImageComp } from 'shared/components/Common';
import { HoverableDropzone } from '../HoverableDropzone';

const Hero = styled.div`
  position: relative;
  overflow: hidden;
  padding: 70px 0;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 60px 0 75px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 50px 0 70px;
  `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  gap: 30px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
    gap: 10px;
  `}
`;

const StyledMockup = styled((props) => <Mockup {...props} />)`
  left: 40px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
      height: auto;
      width: 56%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
      width: 70%;
    `}

  ${({ mockup_id, theme: { $width } }) =>
    mockup_id === 1
      ? `
        width: 480px;
        height: auto;

        ${
          $width <= 1254 &&
          `
            left: -54px;
            width: 480px;
          `
        }

        ${
          $width <= 991 &&
          `
            left: -40px;
            width: 350px;
          `
        }

        ${
          $width <= 768 &&
          `
            left: -20px;
          `
        }

        ${
          $width <= 575 &&
          `
            width: 80%;
          `
        }
      `
      : mockup_id === 2
        ? `
        width: 500px;
        height: auto;
        left: -50px;

        ${
          $width <= 1254 &&
          `
            left: -64px;
            width: 448px;
          `
        }

        ${
          $width <= 991 &&
          `
            left: -40px;
            width: 375px;
          `
        }

        ${
          $width <= 768 &&
          `
            left: -20px;
          `
        }

        ${
          $width <= 575 &&
          `
            left: -10px;
            width: 80%;
          `
        }
      `
        : mockup_id === 3
          ? `
        width: 440px;
        height: auto;
        ${
          $width <= 1254 &&
          `
            width: 402px;
          `
        }

        ${
          $width <= 991 &&
          `
            width: 352px;
            height: 466px;
          `
        }

        ${
          $width <= 575 &&
          `
            width: 70%;
            height: auto;
          `
        }
      `
          : ``}
`;

const TitleWrapper = styled.div`
  width: 655px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 492px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-top: 20px;
  `}
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  margin-bottom: 26px;
  line-height: 1.3em;
  line-height: 1.35em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 20px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 58px;
  line-height: 1.35em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 50px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 45px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 40px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const StyledButton = styled(Button)`
  max-width: 465px;
  width: auto;
  display: inline-block;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 395px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 345px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}
`;

const WhiteGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 0.01%, #fff 75.52%);

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    height: 76%;
    top: auto;
    bottom: 0;
  `}
`;

const DynamicBackground = styled.div`
  width: 100%;
  height: 98%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

const imageStyles = css`
  height: 685px;
  width: 513px;
  position: relative;
  overflow: hidden;
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  // object-fit: ${(props) => (props.isImageUploaded ? 'cover' : 'contain')};
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V1: FC<HeroBasicProps> = (props) => {
  const {
    mockup,
    cover_thumbnail,
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    backgroundColor = COLOR_BLUE_3,
    secondaryColor = COLOR_YELLOW,
    textColor,
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { pages, isCoverDisabled, bookMedia } = useBook();
  const { pagesThumbnails, salesPageId, isSubscription, updateImageMeta } = useSales();
  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [pages, isCoverDisabled, cover_thumbnail, pagesThumbnails]);

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }
    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <Hero
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        {bookMockup ? (
          <HoverableDropzone
            isSalePage
            css={imageStyles as never}
            disabled={!editable}
            allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
            onUpload={handleUpload}
            loading={isLoading}
            alreadyUploaded={Boolean(bookMockup)}
            imageFile={bookMockup}
            resizeSelector={editable}
            handleResize={({ width, height }) => {
              setResize({ width, height });
              updateImageMeta(meta['accessorKey'], width, 'width');
              updateImageMeta(meta['accessorKey'], height, 'height');
            }}
            initialPosition={{
              x: meta['x_1'] || 0,
              y: meta['y_1'] || 0,
              width: meta['d_width_1'],
              height: meta['d_height_1']
            }}
            meta={meta}
            positionEnabled
            handlePosition={(width: number, height: number, x: number, y: number) => {
              updateImageMeta(meta['accessorKey'], x, 'x_1');
              updateImageMeta(meta['accessorKey'], y, 'y_1');
              updateImageMeta(meta['accessorKey'], width, 'd_width_1');
              updateImageMeta(meta['accessorKey'], height, 'd_height_1');
            }}
            imageAspectRatio={{
              ratio: '3x4',
              pixels: '600x800'
            }}>
            <BookMediaImg
              isImageUploaded={isImageUploaded}
              src={bookMockup}
              width={resize.width || 500}
              height={resize.height || 500}
              alt="Book Mockup"
            />
          </HoverableDropzone>
        ) : (
          <StyledMockup
            mockup={mockup?.file}
            mockup_id={mockup?.id}
            cover_thumbnail={coverThumbnail}
          />
        )}
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor(backgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            // id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{
              color: filterBackgroundColor(
                'linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.05) 0.01%, #fff 75.52%)',
                textColor
              )
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <StyledButton
            to={isSubscription ? 'recurring_form' : 'form'}
            button={button}
            meta={meta}
          />
        </TitleWrapper>
      </StyledWrapper>
      <WhiteGradient />
      <DynamicBackground style={{ background: secondaryColor }} />
    </Hero>
  );
};
