import { useMemo, type FC } from 'react';
import type { HeadingWithButtonProps } from './types';

import styled from 'styled-components';

import {
  COLOR_GRAY_13,
  COLOR_BLUE_6,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';
import { useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper, Button, Download } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 80px 0px;
  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const TitleContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  text-align: center;
  line-height: 1.3em;
  margin-bottom: 40px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    br {
      display: none;
    }
  `}
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    white-space: nowrap;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Subtitle = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  color: ${COLOR_GRAY_13};
  margin-bottom: 17px;
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  text-align: center;
  line-height: 1.4em;
  position: relative;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    white-space: nowrap;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V3: FC<HeadingWithButtonProps> = (props) => {
  const {
    backgroundColor = COLOR_BLUE_6,
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    textColor,
    meta = {},
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { type, isSubscription } = useSales();
  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      style={{
        backgroundColor: shadedBackgroundColor,
        display:
          (hidden && !websiteAssembler) || (!websiteAssembler && isTitleEmpty && isContentEmpty)
            ? 'none'
            : 'block',
        opacity: hidden ? 0.2 : 1
      }}
      className={containerClassname}>
      <Wrapper>
        <TitleContainer>
          <Subtitle
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          {meta['download'] || meta['community'] || meta['event'] || type === 'delivery' ? (
            <Download textColor={textColor} button={button} meta={meta} />
          ) : (
            <Button to={isSubscription ? 'recurring_form' : 'form'} button={button} meta={meta} />
          )}
        </TitleContainer>
      </Wrapper>
    </Container>
  );
};
