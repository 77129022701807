import type { FC } from 'react';
import type { MiniHeroProps } from './types';

import styled, { css } from 'styled-components';
import { useMemo, useState } from 'react';
import {
  COLOR_GRAY_13,
  COLOR_BLACK_6,
  filterBackgroundColor,
  sanitizeRichText,
  defaultWebValue,
  ApiService,
  imageViewer
} from 'shared/helpers/index';
import { Image as ImageFC } from './Image';

import { Wrapper } from '../Common';

import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { FlexBox, Image as ImageComp } from 'shared/components/Common';
import { HoverableDropzone } from '../HoverableDropzone';

const MiniHero = styled.div`
  padding: 80px 0 60px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
padding: 70px 0 50px;
`}
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 100%;
  gap: 30px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  flex-direction: column;
  `}
`;

const ImageStyled = styled(ImageFC)`
  margin-top: 30px;
`;

const ParagraphContainer = styled.div<{ isEmpty?: boolean; editable?: boolean }>`
  width: 47%;
  margin-top: -40px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 45%;
`}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-top: 0;
  `}
`;

const Paragraph = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.5em;
  color: ${COLOR_GRAY_13};
  strong {
    color: ${COLOR_BLACK_6};
    font-weight: 500;
  }
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const HeroMockupImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  // object-fit: ${(props) => (props.isImageUploaded ? 'cover' : 'contain')};
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 820px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  width: 438px;
`}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
`}

  ${({ theme: { $width } }) =>
    $width <= 420 &&
    `
  width: 90%;
  margin: 0 auto;
`}
`;

export const V1: FC<MiniHeroProps> = (props) => {
  const { content = defaultWebValue, textColor, meta, hidden, cover_thumbnail, pageImages } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { salesPageId, updateImageMeta } = useSales();
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');
  const { isCoverDisabled, bookMedia } = useBook();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const images = useMemo(() => {
    let imgs = pageImages;
    if (isCoverDisabled) {
      imgs = pageImages.slice(0, 4);
    }

    if (!isCoverDisabled) {
      return [cover_thumbnail, ...imgs.slice(0, 3)];
    }

    return pageImages.slice(0, 3);
  }, [isCoverDisabled, cover_thumbnail, pageImages]);

  const heroMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }
    const miniheroMedia = bookMedia?.find((media) => media.name === 'minihero_mockup');
    if (miniheroMedia && miniheroMedia?.url) return miniheroMedia?.url;

    const media = bookMedia?.find((media) => media.name === 'hero_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <MiniHero
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'flex',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        {heroMockup ? (
          <FlexBox width="537px">
            <HoverableDropzone
              isSalePage
              css={imageStyles as never}
              disabled={!editable}
              allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
              onUpload={handleUpload}
              loading={isLoading}
              alreadyUploaded={Boolean(heroMockup)}
              imageFile={heroMockup}
              resizeSelector={editable}
              handleResize={({ width, height }) => {
                setResize({ width, height });
                updateImageMeta(meta['accessorKey'], width, 'width');
                updateImageMeta(meta['accessorKey'], height, 'height');
              }}
              initialPosition={{
                x: meta['x_1'] || 0,
                y: meta['y_1'] || 0,
                width: meta['d_width_1'],
                height: meta['d_height_1']
              }}
              meta={meta}
              positionEnabled
              handlePosition={(width: number, height: number, x: number, y: number) => {
                updateImageMeta(meta['accessorKey'], x, 'x_1');
                updateImageMeta(meta['accessorKey'], y, 'y_1');
                updateImageMeta(meta['accessorKey'], width, 'd_width_1');
                updateImageMeta(meta['accessorKey'], height, 'd_height_1');
              }}
              imageAspectRatio={{
                ratio: '3x4',
                pixels: '600x800'
              }}>
              <HeroMockupImg
                isImageUploaded={isImageUploaded}
                src={imageViewer(heroMockup) || ''}
                width={resize.width || 500}
                height={resize.height || 500}
                alt="Book Mockup"
              />
            </HoverableDropzone>
          </FlexBox>
        ) : (
          <ImageStyled images={images} />
        )}
        <ParagraphContainer>
          <Paragraph
            id={content?.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content?.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
        </ParagraphContainer>
      </StyledWrapper>
    </MiniHero>
  );
};
