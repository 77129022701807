import type {
  CSSProperties,
  HTMLProps,
  ReactElement,
  MouseEvent,
  MutableRefObject,
  ComponentType
} from 'react';
import type { IngredientType } from 'shared/types/PageBuilderTypes';
import type { DOMNode, Element } from 'html-react-parser';
import type { AlertProps } from '@mui/material';
import type { BookPages, KeyValueBoolean, Media, WhoAmI } from '../types';
import type { HoverableDropzoneProps } from 'shared/components/Sales/HoverableDropzone';

import { createElement } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { css } from 'styled-components';

import { COLOR_LIGHT_GRAY } from './colors';
import debounce from 'lodash/debounce';

export const parseInlineStyle = (style: string) => {
  const template = document.createElement('template');
  template.setAttribute('style', style);
  return Object.entries(template.style)
    .filter(([key]) => !/^[0-9]+$/.test(key))
    .filter(([, value]) => Boolean(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export const updateTemplate = (
  template: string,
  ingredients: IngredientType[],
  mediaInputs: Array<string>,
  loading: KeyValueBoolean,
  page: BookPages,
  bookMedia: Media[] | undefined = [],
  pageRef: MutableRefObject<HTMLDivElement | null>,
  onClick: (e: MouseEvent<HTMLElement>) => void,
  resetRef: () => void,
  handleUpload: (key: string, file: File) => void,
  handleMetaUpdates: (container: number, data: Record<string, string | number>) => void,
  forceUpdateIngredients: () => void,
  updateImagePosition: (container: number, tag: string, data: 'center' | 'left' | 'right') => void,
  HoverableDropzone: ComponentType<HoverableDropzoneProps>,
  pendingUpdates: Record<number, Record<string, string | number>>,
  user?: WhoAmI,
  addAlert?: (message: string, severity?: AlertProps['severity'], timeout?: number) => void
) => {
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'input',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr'
  ]);

  let container: string;
  let imgCount: number = 0;
  let extractContainer: string;
  let avaliableBackground: Record<string, string> = {};
  let order: number | undefined;
  let background: string;

  const convertStyleStringToObject = (
    attributes: Record<string, string>,
    orders: Array<{ order: number; id: number }>
  ) => {
    const isContainer = attributes['data-container'];

    const styles = attributes['style'];

    if (!isContainer && !styles) return undefined;
    const styleObject: CSSProperties = {};
    if (isContainer) {
      const last = Math.max(...orders.map((t) => t.order));
      const current = orders.find((c) => c.id === Number(isContainer))?.order;
      styleObject['borderTop'] = `2px dashed ${COLOR_LIGHT_GRAY}`;

      if (last === current) {
        styleObject['borderBottom'] = `2px dashed ${COLOR_LIGHT_GRAY}`;
      }
    }

    if (!styles) return styleObject;

    const reactStyles = parseInlineStyle(styles);

    return { ...reactStyles, ...styleObject };
  };

  const extractUsefulData = (domNode: DOMNode) => {
    if (domNode.type !== 'tag') {
      return false;
    }

    extractContainer = domNode.attribs['data-container']
      ? domNode.attribs['data-container']
      : extractContainer;

    const background = domNode.attribs['data-bg-color'];

    if (background)
      avaliableBackground = {
        ...avaliableBackground,
        [extractContainer]: background
      };
    return;
  };

  const addEventHandlersAndUpdateValues = (domNode: DOMNode): ReactElement | false => {
    if (domNode.type !== 'tag') {
      return false;
    }

    // Debounced function to batch updates
    const updateMeta = debounce((container: number) => {
      if (!pendingUpdates[container]) return;
      handleMetaUpdates(container, pendingUpdates[container]);
    }, 150);

    // Wrapper to collect updates before execution
    const queueMetaUpdate = (container: number, values: Record<string, string | number>) => {
      // Merge new values into existing ones
      pendingUpdates[container] = {
        ...(pendingUpdates[container] || {}),
        ...values // New values overwrite old ones if the same key exists
      };

      // Trigger the debounced update
      updateMeta(container);
    };

    if (
      domNode.attribs['data-image-width'] &&
      domNode.children
        .map((c) => {
          if (c.type === 'tag') return c.tagName;
          return false;
        })
        .filter(Boolean)
        .includes('img')
    ) {
      let mediaVariable = mediaInputs[imgCount];
      mediaVariable = page.page ? mediaVariable?.replace('_xx_', `_${page.id}_`) : mediaVariable;
      const alreadyUploaded = bookMedia.find((v) => v.name?.localeCompare(mediaVariable));
      const imageAspectRatio =
        page?.images_aspect_ratio?.[mediaVariable?.replace(/_\d+_/g, '_xx_')];
      const height = domNode.attribs['data-image-height'];
      const width = domNode.attribs['data-image-width'];
      let margin = domNode.attribs['data-image-margin'];
      const image = Array.from(domNode.children).find(
        (c) => c.type === 'tag' && c.tagName.toLowerCase() === 'img'
      ) as Element | undefined;

      if (!image) return false;

      const imageWidthAttr = image.attribs['data-image-position-width'];
      const imageHeightAttr = image.attribs['data-image-position-height'];
      const xAttr = image.attribs['data-image-position-x'];
      const yAttr = image.attribs['data-image-position-y'];
      const skipMarginCopy = image.attribs['data-skip-margin-copy'];

      let currentNode = domNode;
      let imageContainerId = container;

      while (currentNode) {
        const cont = currentNode.attribs['data-container'];
        const marg = currentNode.attribs['data-image-margin'];
        if (!margin && marg) {
          margin = marg;
        }
        if (cont) {
          imageContainerId = cont;
          break;
        }
        if (currentNode.parentNode) currentNode = currentNode.parentNode as Element;
        else break;
      }

      const ingredient = ingredients.find((ing) => ing.id === Number(container));

      const domNodeUpdated = {
        ...image?.attribs
      };

      const imageProps = {
        children: createElement('img', domNodeUpdated),
        onUpload: (file: File) => {
          if (!mediaVariable) return;
          handleUpload?.(mediaVariable, file);
        },
        style: parseInlineStyle(domNode.attribs['style']) ?? {},
        loading: loading[mediaVariable],
        disabled: false,
        user,
        containerId: container,
        alreadyUploaded: Boolean(alreadyUploaded),
        resizeSelector: Boolean(height && width),
        handleResize: (value: { width: number | string; height: number | string }) => {
          queueMetaUpdate(Number(imageContainerId), {
            [width]: value.width,
            [height]: value.height
          });
        },
        forceUpdateIngredients,
        skipMargin: Boolean(skipMarginCopy),
        positionEnabled: Boolean(imageWidthAttr && imageHeightAttr && xAttr && yAttr),
        handlePosition: (width: number, height: number, x: number, y: number) => {
          if (!imageWidthAttr || !imageHeightAttr || !xAttr || !yAttr) return;
          queueMetaUpdate(Number(imageContainerId), {
            [imageWidthAttr]: width,
            [imageHeightAttr]: height,
            [xAttr]: x,
            [yAttr]: y
          });
        },
        initialPosition: {
          x: ingredient?.ingredient_data[xAttr] as unknown as number,
          y: ingredient?.ingredient_data[yAttr] as unknown as number,
          width: ingredient?.ingredient_data[imageWidthAttr] as unknown as number,
          height: ingredient?.ingredient_data[imageHeightAttr] as unknown as number
        },
        moveHandler: (position: 'center' | 'left' | 'right') => {
          updateImagePosition(Number(imageContainerId), margin, position);
        },
        css: css`` as never,
        allowedExtensions: ['image/png', 'image/jpg', 'image/jpeg'],
        addAlert,
        imageFile: image.attribs['src'],
        mediaVariable,
        className: domNode.attribs['class'],
        imageAspectRatio,
        meta: {
          width,
          height,
          margin,
          imageWidthAttr
        }
      };

      imgCount++;
      return createElement(HoverableDropzone, imageProps);
    } else if (domNode.name === 'img') {
      let mediaVariable = mediaInputs[imgCount];
      mediaVariable = page.page ? mediaVariable?.replace('_xx_', `_${page.id}_`) : mediaVariable;
      const alreadyUploaded = bookMedia.find((v) => v.name?.localeCompare(mediaVariable));
      const imageAspectRatio =
        page?.images_aspect_ratio?.[mediaVariable?.replace(/_\d+_/g, '_xx_')];
      const height = domNode.attribs['data-image-height'];
      const width = domNode.attribs['data-image-width'];
      const margin = domNode.attribs['data-image-margin'];
      const domNodeUpdated = {
        ...domNode.attribs,
        style: parseInlineStyle(domNode.attribs['style']) ?? {}
      };

      const imageWidthAttr = domNode.attribs['data-image-position-width'];
      const imageHeightAttr = domNode.attribs['data-image-position-height'];
      const xAttr = domNode.attribs['data-image-position-x'];
      const yAttr = domNode.attribs['data-image-position-y'];

      let imageContainerId = container;
      let currentNode = domNode;

      while (currentNode) {
        const cont = currentNode.attribs['data-container'];
        if (cont) {
          imageContainerId = cont;
          break;
        }
        if (currentNode.parentNode) currentNode = currentNode.parentNode as Element;
        else break;
      }

      const ingredient = ingredients.find((ing) => ing.id === Number(imageContainerId));

      const imageProps = {
        children: createElement('img', domNodeUpdated),
        onUpload: (file: File) => {
          if (!mediaVariable) return;
          handleUpload?.(mediaVariable, file);
        },
        style: parseInlineStyle(domNode.attribs['style']) ?? {},
        loading: loading[mediaVariable],
        disabled: false,
        user,
        containerId: container,
        alreadyUploaded: Boolean(alreadyUploaded),
        positionEnabled: Boolean(imageWidthAttr && imageHeightAttr && xAttr && yAttr),
        handlePosition: (width: number, height: number, x: number, y: number) => {
          if (!imageWidthAttr || !imageHeightAttr || !xAttr || !yAttr) return;
          queueMetaUpdate(Number(imageContainerId), {
            [imageWidthAttr]: width,
            [imageHeightAttr]: height,
            [xAttr]: x,
            [yAttr]: y
          });
        },
        initialPosition: {
          x: ingredient?.ingredient_data[xAttr] as unknown as number,
          y: ingredient?.ingredient_data[yAttr] as unknown as number,
          width: ingredient?.ingredient_data[imageWidthAttr] as unknown as number,
          height: ingredient?.ingredient_data[imageHeightAttr] as unknown as number
        },
        resizeSelector: Boolean(height && width),
        handleResize: (value: { width: number | string; height: number | string }) => {
          handleMetaUpdates(Number(imageContainerId), {
            [width]: value.width,
            [height]: value.height
          });
        },
        // handlePosition: () => null,
        moveHandler: (position: 'center' | 'left' | 'right') => {
          updateImagePosition(Number(imageContainerId), margin, position);
        },
        css:
          imageWidthAttr && imageHeightAttr && xAttr && yAttr
            ? (css`` as never)
            : (css`
                position: relative;
                & img {
                  width: inherit !important;
                  height: inherit !important;
                  margin: 0 !important;
                }
              ` as never),
        allowedExtensions: ['image/png', 'image/jpg', 'image/jpeg'],
        addAlert,
        imageFile: domNode.attribs['src'],
        mediaVariable,
        className: domNode.attribs['class'],
        imageAspectRatio,
        meta: {
          width,
          height,
          margin,
          imageWidthAttr
        }
      };

      imgCount++;
      return createElement(HoverableDropzone, imageProps);
    } else {
      const selector = domNode.attribs['data-selector'];
      container = domNode.attribs['data-container'] ? domNode.attribs['data-container'] : container;
      background = domNode.attribs['data-bg-color'] ? domNode.attribs['data-bg-color'] : background;

      const ingredient = ingredients.find((ing) => ing.id === Number(container));
      const orders = ingredients.map((i) => ({ id: i.id, order: i.order }));

      order =
        ingredient?.order !== undefined
          ? ingredient.order
          : orders.find((o) => o.id === Number(container))?.order;

      const props: HTMLProps<HTMLElement> & {
        'data-order': string | undefined;
        'data-placeholder': string;
      } = {
        ...domNode.attribs,
        onClick: selector ? onClick : () => null,
        onDragStart: selector
          ? (ev) => {
              if (ev.target instanceof HTMLImageElement) onClick(ev);
            }
          : () => null,
        onBlur: selector ? resetRef : () => null,
        style: convertStyleStringToObject(domNode.attribs, orders) ?? {},
        'data-placeholder': '+',
        ...(background !== undefined && selector
          ? { 'data-bg-color': background }
          : { 'data-bg-color': ingredient?.id ? avaliableBackground[ingredient.id] : undefined }),
        ...(order !== undefined ? { 'data-order': String(order) } : { 'data-order': undefined })
      };

      if (!voidElements.has(domNode.name)) {
        if (selector && ingredient?.ingredient_data && ingredient?.ingredient_data[selector]) {
          // set unique key to avoid issue with referencing being totally off when moving components around/changing color
          props.key = `render-${ingredient?.ingredient}-${selector}`;
          const el = document.createElement('p');
          el.innerHTML = ingredient?.ingredient_data[selector];
          const hasImage = Boolean(el.querySelectorAll('img').length > 0);
          const hasText = el.textContent?.trim()?.length || 0 > 0;
          if (ingredient?.ingredient_data[selector] && (hasImage || hasText)) {
            props.dangerouslySetInnerHTML = {
              __html: ingredient?.ingredient_data[selector]
            };
          } else {
            if (pageRef.current) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const element = pageRef.current.querySelector(`.${props?.class}`);
              props.key = `render-${ingredient?.ingredient}-${selector}-empty`;
              if (element) {
                const computedStyles = window.getComputedStyle(element);
                if (
                  computedStyles.textAlign === 'center' ||
                  computedStyles.justifyContent === 'center'
                ) {
                  props.dangerouslySetInnerHTML = {
                    __html: `<p class="placeholder-wrapper-pw-center" data-placeholder="+"></p>`
                  };
                } else
                  props.dangerouslySetInnerHTML = {
                    __html: `<p class="placeholder-wrapper-pw" data-placeholder="+"></p>`
                  };
              }
            }
          }
        } else {
          props.children = domNode.children
            ? domToReact(domNode.children as DOMNode[], {
                replace: addEventHandlersAndUpdateValues
              })
            : null;
        }
      }

      return createElement(domNode.name, props);
    }
  };

  // preparse the template to extract useful data
  parse(template, { replace: extractUsefulData });

  const parsedTemplate = parse(template, { replace: addEventHandlersAndUpdateValues });

  return parsedTemplate;
};

export const updateTemplateForPreview = (template: string, ingredients: IngredientType[]) => {
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'input',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
    'img'
  ]);

  let container: string;

  const convertStyleStringToObject = (attributes: Record<string, string>) => {
    const styles = attributes['style'];

    const styleObject: CSSProperties = {};
    if (!styles) return styleObject;

    const reactStyles = parseInlineStyle(styles);

    return { ...reactStyles, ...styleObject };
  };

  const addEventHandlersAndUpdateValues = (domNode: DOMNode): ReactElement | false => {
    if (domNode.type !== 'tag') {
      return false;
    }

    const selector = domNode.attribs['data-selector'];
    container = domNode.attribs['data-container'] ? domNode.attribs['data-container'] : container;

    const ingredient = ingredients.find((ing) => ing.id === Number(container));

    const props: HTMLProps<HTMLElement> & {
      'data-selector': string | undefined;
      'data-container': string | undefined;
    } = {
      ...domNode.attribs,
      'data-selector': undefined,
      'data-container': undefined,
      style: convertStyleStringToObject(domNode.attribs)
    };

    if (!voidElements.has(domNode.name)) {
      if (selector && ingredient?.ingredient_data && ingredient?.ingredient_data[selector]) {
        props.dangerouslySetInnerHTML = {
          __html: ingredient?.ingredient_data[selector]
        };
      } else {
        props.children = domNode.children
          ? domToReact(domNode.children as DOMNode[], {
              replace: addEventHandlersAndUpdateValues
            })
          : null;
      }
    }
    return createElement(domNode.name, props);
  };

  return parse(template, { replace: addEventHandlersAndUpdateValues });
};

export const isValidUrl = (url: string) => {
  const pattern = new RegExp(
    '^((https?:\\/\\/)?' + // optional protocol (http or https)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '(\\d{1,3}\\.){3}\\d{1,3}|' + // OR IP (v4) address
      'localhost))' + // OR localhost
      '(\\:\\d+)?' + // optional port
      '(\\/[-a-z\\d%_.~+]*)*' + // optional path
      '(\\?[-a-z\\d%_.~+=&]*)?' + // optional query string
      '(\\#.*)?$', // optional fragment locator
    'i'
  );
  return pattern.test(url);
};

export const capitalizeMatches = (input: string) => {
  const regex = /\b[a-z]+\b/g;
  let match;
  let lastIndex = 0;
  let result = '';

  while ((match = regex.exec(input)) !== null) {
    result += input.substring(lastIndex, match.index) + match[0].toUpperCase();
    lastIndex = match.index + match[0].length;
  }

  result += input.substring(lastIndex);

  return result;
};
