import { useEffect, useMemo, useRef, type FC } from 'react';
import type { TopBarTypes } from './types';

import styled from 'styled-components';

import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import { defaultWebValue, filterBackgroundColor } from 'shared/helpers/website';
import { sanitizeRichText } from 'shared/helpers/utils';
import { COLOR_BLUE_3 } from 'shared/helpers/colors';

import { Wrapper, Button, Download } from '../Common';
import useSales from 'shared/hooks/useSales';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div<{ backgroundColor: string }>`
  background: ${(props) => props.backgroundColor};
  padding: 18px 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${({ theme: { $width } }) => $width <= 768 && 'flex-direction: column; gap:10px;'}
`;

const Title = styled.div<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme }) => theme.$subheadlineFont};
  font-weight: 500;
  max-width: 95%;
  line-height: 1.4em;
  cursor: text;
  position: relative;
  min-width: 30%;

  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    text-align: center;
    padding-right: 0px;
  `}
`;

const StyledButton = styled((props) => <Button {...props} />)`
  position: relative;
  z-index: 99;
  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-top: 8px;
  `}
`;

export const V1: FC<TopBarTypes> = (props) => {
  const {
    title = defaultWebValue,
    button = defaultWebValue,
    hidden,
    secondaryColor = COLOR_BLUE_3,
    meta,
    textColor
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { type, salesPageValues, deliveryPageValues, isSubscription } = useSales();

  const { isEmpty } = useIsEmpty(meta, 'title');

  useEffect(() => {
    const updateSticky = () => {
      const checkValues = type === 'sales' ? salesPageValues : deliveryPageValues;
      if (!ref.current || !ref.current.parentElement || websiteAssembler || !checkValues) return;
      const values = checkValues[meta['accessorKey']];

      const minOrder = Math.min(
        ...Object.values(checkValues)
          .filter((item) => typeof item === 'object')
          .map((item) => item['order'])
          .filter((order) => typeof order === 'number')
      );

      if (typeof values === 'object' && values.order !== minOrder) return;

      const top = ref.current.parentElement.getBoundingClientRect().top;

      if (top < 0) {
        ref.current.classList.add('pw-sticky-topbar');
      } else if (top >= 0) {
        ref.current.classList.remove('pw-sticky-topbar');
      }
    };

    window.addEventListener('scroll', updateSticky);
    window.addEventListener('resize', updateSticky);

    return () => {
      window.removeEventListener('scroll', updateSticky);
      window.removeEventListener('resize', updateSticky);
    };
  }, [deliveryPageValues, websiteAssembler, meta, salesPageValues, type]);

  const href = useMemo(() => {
    if (meta['url']) return meta['url'];
    if (isSubscription) return 'recurring_form';

    return 'form';
  }, [isSubscription, meta]);

  return (
    <Container
      id="sales_topbar_component"
      backgroundColor={secondaryColor}
      ref={ref}
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <TitleContainer>
          <Title
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            id={title.id}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            style={{ color: filterBackgroundColor(secondaryColor, textColor) }}
            editable={editable}
            isEmpty={isEmpty}
          />
          {type === 'delivery' || !['form', 'recurring_form'].includes(href) ? (
            <Download textColor={textColor} button={button} meta={meta} />
          ) : (
            <StyledButton
              to={isSubscription ? 'recurring_form' : 'form'}
              button={button}
              meta={meta}
            />
          )}
        </TitleContainer>
      </Wrapper>
    </Container>
  );
};
