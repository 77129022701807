import type { SalesComponentTypes } from 'shared/components/Sales/SalesRenderer';
import type { BookBaseColor, ColorPalette } from 'shared/types/Branding';
import type { SalesVersionTypes } from 'shared/types/Render';
import type { ReactElement, ReactNode, RefObject } from 'react';
import type { ColorPaletteType, FontSizeTypes, PageData, SalesItem, Wording } from '../types';

import tinycolor, { type ColorInput } from 'tinycolor2';

import { colorToKeyMapping, keyToColorMapping } from './pallete';
import { fontSizeMapper } from './static';

export const defaultWebValue = {
  val: '<p><br></p>',
  color: '',
  hidden: true,
  id: ''
};

export const fixWebsiteOrdering = <T extends PageData>(data: T) => {
  const copy = { ...data };

  // Filter out entries that are objects with 'order' and sort them
  const items = Object.keys(copy)
    .filter((key) => {
      const accessor = copy[key];
      return typeof accessor === 'object' && 'order' in accessor;
    })
    .sort((a, b) => (copy[a] as SalesItem).order - (copy[b] as SalesItem).order);

  // Assign new order
  items.forEach((key, index) => {
    if (typeof copy[key] === 'object') (copy[key] as unknown as SalesItem).order = index;
  });

  return copy; // Return the modified copy
};

export const updateWebsiteKeysToColor = <T extends PageData>(
  data: T,
  colorPalette: ColorPaletteType,
  fixOrdering?: boolean
) => {
  const updatedData = { ...data };

  (Object.keys(updatedData) as Array<keyof T>).forEach((key) => {
    const value = updatedData[key];

    if (typeof value === 'object' && value !== null) {
      Object.keys(value).forEach((objKey) => {
        const objValue = value[objKey as keyof SalesItem];
        if (typeof objValue === 'object' && objValue !== null && 'color' in objValue) {
          updatedData[key] = {
            ...value,
            [objKey]: {
              ...objValue,
              color: tinycolor(objValue.color).isValid()
                ? objValue.color
                : keyToColorMapping(objValue.color, colorPalette)
            }
          } as T[keyof T];
        }
      });
    } else if (typeof value === 'string')
      updatedData[key] = tinycolor(value).isValid()
        ? value
        : (keyToColorMapping(value, colorPalette) as T[keyof T]);
  });

  if (fixOrdering) return fixWebsiteOrdering(updatedData);
  return updatedData;
};

export const filterBackgroundColor = (backgroundColor: string, textColor: BookBaseColor) =>
  tinycolor(backgroundColor).isDark() ? textColor.dark : textColor.value;

export const filterBackgroundColorMultiple = (
  backgroundColor: string[],
  textColor: BookBaseColor
) => {
  const dark = backgroundColor.some((color) => tinycolor(color).isDark());
  return dark ? textColor.dark : textColor.value;
};

export const updateWebsiteColorsToKeys = <T extends PageData>(
  data: T,
  colorPalette: ColorPaletteType
): T => {
  const updatedData = { ...data };

  (Object.keys(updatedData) as Array<keyof T>).forEach((key) => {
    const value = updatedData[key];

    if (typeof value === 'object' && value !== null) {
      Object.keys(value).forEach((objKey) => {
        const objValue = value[objKey as keyof SalesItem];
        if (typeof objValue === 'object' && objValue !== null && 'color' in objValue) {
          const color = tinycolor(objValue.color as ColorInput);
          if (color.isValid()) {
            updatedData[key] = {
              ...value,
              [objKey]: {
                ...objValue,
                color: colorToKeyMapping(objValue.color, colorPalette)
              }
            } as T[keyof T];
          }
        }
      });
    } else if (typeof value === 'string') {
      const color = tinycolor(value);

      if (color.isValid()) {
        updatedData[key] = colorToKeyMapping(value, colorPalette) as T[keyof T];
      }
    }
  });

  return updatedData;
};

export const extractComponentKey = <T extends SalesComponentTypes, K extends keyof T>(key: K) => {
  const regex = /_(\d+)$/;
  if (regex.test(key.toString())) return key.toString().replace(regex, '');
  return key;
};

export const extractLastKey = <T extends SalesComponentTypes, K extends keyof T>(key: K) => {
  const regex = /_(\d+)$/;
  const match = key.toString().match(regex);
  return match ? match[1] : null;
};

export const problemSolutionWordings = (pagesWording: Wording['pages_wording']) => {
  const problemWording = pagesWording.flatMap((d) => d.problem).filter((d) => d !== '');
  const solutionWording = pagesWording.flatMap((d) => d.solution).filter((d) => d !== '');
  const shortSolutionWording = pagesWording
    .flatMap((d) => d.short_solution)
    .filter((d) => d !== '');
  return { problemWording, solutionWording, shortSolutionWording };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const randomItem = (array: Array<any>) => array[Math.floor(Math.random() * array.length)];

export const accessAndUpdateObjectByPath = (
  obj: Partial<PageData>,
  path: Array<string | number>,
  newValue: string
) => {
  let current = obj;
  for (let i = 0; i < path.length; i++) {
    const key = path[i];
    if (!Object.prototype.hasOwnProperty.call(current, key)) {
      current[key] = {
        val: '',
        color: '',
        hidden: true
      } as unknown as SalesItem;
    }

    if (i === path.length - 1) {
      current[key] = newValue;
    } else {
      if (!current[key]) {
        current[key] = undefined;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      current = current[key];
    }
  }
  return obj;
};

export const versionMapper = (component: string): Array<string> => {
  const versions: SalesVersionTypes = {
    hero_detailed: ['V1', 'V2', 'V3'],
    heading: ['V1', 'V2'],
    check_section: ['V1', 'V2'],
    for_you: ['V1', 'V2'],
    about_me: ['V1', 'V2'],
    call_to_action: ['V1', 'V2'],
    faq: ['V1', 'V2', 'V3'],
    hero_basic: ['V1', 'V2', 'V3'],
    how_to_start: ['V1', 'V2', 'V3'],
    headline_only: ['V1', 'V2'],
    paragraph: ['V1', 'V2'],
    heading_with_button: ['V1', 'V2', 'V3'],
    heading_with_button_event: ['V1', 'V2', 'V3'],
    heading_with_button_calendar: ['V1', 'V2', 'V3'],
    heading_with_button_community: ['V1', 'V2', 'V3'],
    guarantee: ['V1', 'V2', 'V3'],
    hero_coach: ['V1', 'V2'],
    mini_hero: ['V1', 'V2'],
    hero: [
      'V1',
      'V2',
      'V3',
      'V4',
      'V5',
      'V6',
      'V7',
      'V8',
      'V9',
      'V10',
      'V11',
      'V12',
      'V13',
      'V14',
      'V15'
    ],
    video: ['V1', 'V2', 'V3', 'V4', 'V5'],
    topbar: ['V1', 'V2', 'V3'],
    photo: ['V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'V9', 'V10', 'V11', 'V12'],
    event: ['V1', 'V2', 'V3'],
    form: ['V1', 'V2'],
    recurring_form: ['V1', 'V2', 'V3', 'V4', 'V5']
  };

  if (component in versions) {
    return versions[component as keyof SalesVersionTypes];
  }
  return [];
};

export const detectColorType = (colorPallete: ColorPalette, key: string) => {
  const keys = Object.keys(colorPallete);
  const extractedKey = key.slice(0, -1);
  return keys
    .map((k) => {
      if (k.startsWith(extractedKey)) return k;
      return false;
    })
    .filter(Boolean);
};

export const hexToRgb = (hex: string) => {
  hex = hex.replace(/^#/, '');

  // Parse the hex string
  let r: number = 0,
    g: number = 0,
    b: number = 0;

  if (hex.length === 3) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.substr(0, 2), 16);
    g = parseInt(hex.substr(2, 2), 16);
    b = parseInt(hex.substr(4, 2), 16);
  } else {
    return 'Incorrect Format';
  }

  return {
    r,
    g,
    b
  };
};

interface RGB {
  r: number;
  g: number;
  b: number;
}

export const calculateLuminance = (hex: string) => {
  const { r, g, b } = hexToRgb(hex) as RGB;

  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const calculateResponsiveDesign = (width: number, type: FontSizeTypes) => {
  const sizes = fontSizeMapper[type];
  if (width <= 375) return sizes['375'];
  if (width <= 575) return sizes['575'];
  if (width <= 768) return sizes['768'];
  if (width <= 991) return sizes['991'];
  if (width <= 1252) return sizes['1252'];

  return sizes['1920'];
};

export const convertDangerousHtmlToChildren = (node: ReactElement) => {
  if (node.props && node.props.dangerouslySetInnerHTML) {
    return {
      ...node,
      props: {
        ...node.props,
        dangerouslySetInnerHTML: undefined,
        children: node.props.dangerouslySetInnerHTML.__html
      }
    };
  }
  return node;
};

export const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};

export const isBoundingClientRectZoomed = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  // Get the actual computed size of the element
  const actualWidth = element.offsetWidth;
  const actualHeight = element.offsetHeight;

  // Compare rect dimensions with actual dimensions
  const widthDiff = Math.abs(rect.width - actualWidth);
  const heightDiff = Math.abs(rect.height - actualHeight);

  const tolerance = 5; // Some browsers may have sub-pixel differences
  return widthDiff > tolerance || heightDiff > tolerance;
};

export const getImageRef = (node: ReactNode): RefObject<HTMLImageElement> | null => {
  if (node && typeof node === 'object' && 'type' in node && node.type === 'img' && 'ref' in node) {
    const element = node as ReactElement<{ ref?: RefObject<HTMLImageElement> }>;
    if (element.props.ref && 'current' in element.props.ref) {
      return element.props.ref;
    }
  }
  return null;
};
