import type { FC } from 'react';
import type { FirstInputType } from './First';
import type { FormProps } from './V1';

import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { loadStripe as LoadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { First } from './First';
import { Second } from './Second';

import useSales from 'shared/hooks/useSales';
import useErrorLogger from 'shared/hooks/useErrorLogger';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

import 'react-phone-number-input/style.css';
import { ApiService } from 'shared/helpers/api';
import { COLOR_BLUE_6 } from 'shared/helpers/colors';
import { isLocalhost, shade, sanitizeRichText } from 'shared/helpers/utils';
import { defaultWebValue, filterBackgroundColor } from 'shared/helpers/website';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Wrapper } from '../Common';
import HeadlineOnly from '../HeadlineOnly';
import { stateCodes } from 'shared/helpers/static';

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  padding: 80px 0 80px;
  position: relative;
  background: ${(props) => props.backgroundColor};

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 52px 0 60px;
  `}
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 4;
  gap: 80px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    justify-content: center;
  `}
`;

const TitleContainer = styled.div<{
  isTitleEmpty?: boolean;
  isContentEmpty?: boolean;
  editable?: boolean;
}>`
  width: 47%;
  max-width: 47%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  ${({ isTitleEmpty, editable }) => (isTitleEmpty && editable ? `gap: 50px;` : '')}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 34px;
  `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: 60px;
  margin-bottom: 32px;
  line-height: 1.3em;
  position: relative;
  min-height: 40px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    font-size: 50px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    font-size: 36px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    font-size: 30px;
    margin-bottom: 16px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: 24px;
  line-height: 1.35em;
  position: relative;
  min-height: 40px; /* Ensure there's enough height for content */

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    font-size: 22px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const FormInner = styled.div`
  position: relative;
  padding: 46px 88px;
  z-index: 4;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 32px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 52px 35px 72px;
  `}
`;

const FormBg = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

export const V2: FC<FormProps> = (props: FormProps) => {
  const {
    button = defaultWebValue,
    backgroundColor = COLOR_BLUE_6,
    type,
    stripe,
    title = defaultWebValue,
    content = defaultWebValue,
    sub_domain,
    deliveryToken,
    navigate,
    textColor,
    meta
  } = props;

  const [firstPhase, setFirstPhase] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [stripeConfig, setStripeConfig] = useState({ id: '', clientSecret: '' });
  const [customerData, setCustomerData] = useState<FirstInputType | null>();

  const {
    updateDownloadUrl,
    salesType,
    price,
    eventDetails,
    salesOrderCount,
    deliveryPageValues,
    amazonURL
  } = useSales();
  const { updateActiveElement, resetActiveElement, containerClassname, editable } =
    useWebsiteEditor();
  const { logError } = useErrorLogger();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');
  const [orderData, setOrderData] = useState<FirstInputType>({
    phone: '',
    email: '',
    first_name: '',
    last_name: '',
    subscribe: false,
    city: '',
    street: '',
    apartment: '',
    zipcode: '',
    state: ''
  });

  const stripePromise = useMemo(() => {
    if (type === 'sales' && stripe)
      return LoadStripe(stripe.public_key, { stripeAccount: stripe.account });
    return null;
  }, [type, stripe]);

  const subHeadline = useMemo(() => {
    if (salesType === 'sales') {
      return 'We will email you the files after your purchase is complete.';
    }
    return 'It will be headed your way soon, just give us your email address.';
  }, [salesType]);

  const navigateToThankYou = () => {
    if (amazonURL && deliveryPageValues && deliveryPageValues?.['no_delivery']) {
      if (typeof window !== 'undefined') window.location.replace(amazonURL);
      return;
    }
    if (amazonURL) window.open(amazonURL, '_blank');
    if (isLocalhost()) {
      navigate?.(`/${sub_domain}/thankyou/${deliveryToken}`);
    } else {
      navigate?.(`/thankyou/${deliveryToken}`);
    }
  };

  const handleFirst = async (values: FirstInputType) => {
    try {
      setIsLoading(true);

      const res = await ApiService.post(`/sales/page/${sub_domain}/order/`, values);
      setOrderData({
        ...values,
        ...res.data.book_order,
        state: stateCodes[values.state as keyof typeof stateCodes] || values.state
      });
      if (res.data?.book_order?.type === 'amazon') {
        navigateToThankYou();
      }
      const clientSecret = res.data.client_secret;
      const id = String(res.data.book_order.uuid);
      setCustomerData(values);

      setStripeConfig({
        id,
        clientSecret
      });

      if ((price === '0' || Number(price) === 0) && res.data.book_order.status === 'delivered') {
        const response = await ApiService.get(`/sales/page/${sub_domain}/delivery/${id}/`);

        updateDownloadUrl(response.data.file_url);
        navigateToThankYou();
      } else {
        setFirstPhase(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errors = error.response.data?.errors;
      if (errors && errors.length > 0 && errors[0].code === 'BOOKORDER_ERROR_0001') {
        navigateToThankYou();
      } else {
        logError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSecond = () => {
    try {
      setIsLoading(true);

      const checkDeliveryStatus = setInterval(async () => {
        const params = {
          email: customerData?.email,
          uuid: stripeConfig.id,
          sub_domain
        };
        const status = await ApiService.get(`/sales/page/${sub_domain}/status/`, { params });

        if (status.data.status === 'delivered') {
          clearInterval(checkDeliveryStatus);
          const response = await ApiService.get(
            `/sales/page/${sub_domain}/delivery/${stripeConfig.id}/`
          );

          updateDownloadUrl(response.data.file_url);
          setIsLoading(false);
          navigateToThankYou();
          setFirstPhase(false);
        }
      }, 2000);
    } catch (_error) {
      setIsLoading(false);
    }
  };

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  if (
    !editable &&
    Number(eventDetails?.peopleLimit) &&
    Number(eventDetails?.peopleLimit) <= salesOrderCount
  ) {
    return (
      <HeadlineOnly.V1
        {...props}
        title={{
          id: title.id,
          val: 'Sorry, registration is full'
        }}
      />
    );
  }

  return (
    <Container id="form" backgroundColor={shadedBackgroundColor} className={containerClassname}>
      <StyledWrapper>
        <TitleContainer
          editable={editable}
          isTitleEmpty={isTitleEmpty}
          isContentEmpty={isContentEmpty}>
          <Title
            id={title?.id}
            style={{
              color: filterBackgroundColor(shadedBackgroundColor, textColor),
              display: sanitizeRichText(title.val, editable, true) || editable ? 'block' : 'none'
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            dangerouslySetInnerHTML={{
              __html: sanitizeRichText(title.val, editable, true)
                ? sanitizeRichText(title.val, editable, false, '<p>It’s almost yours!</p>')
                : '<p></br><p>'
            }}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="It’s almost yours!"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            id={content?.id}
            dangerouslySetInnerHTML={{
              __html: sanitizeRichText(content.val, editable, true)
                ? sanitizeRichText(content.val, editable, false, subHeadline)
                : '<p><br></p>'
            }}
            style={{
              color: filterBackgroundColor(shadedBackgroundColor, textColor),
              display: sanitizeRichText(content.val, editable, true) || editable ? 'block' : 'none'
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder={subHeadline}
            editable={editable}
            isEmpty={isContentEmpty}
          />
        </TitleContainer>
        <FormInner>
          {isLoading ? (
            <Center>
              <CircularProgress color="inherit" />
            </Center>
          ) : !firstPhase && stripePromise ? (
            <Elements stripe={stripePromise} options={{ clientSecret: stripeConfig.clientSecret }}>
              <Second
                orderData={orderData}
                backgroundColor={backgroundColor}
                callback={handleSecond}
                textColor={textColor}
              />
            </Elements>
          ) : (
            <First
              backgroundColor={backgroundColor}
              callback={handleFirst}
              textColor={textColor}
              button={button}
              meta={meta}
            />
          )}
          <FormBg />
        </FormInner>
      </StyledWrapper>
    </Container>
  );
};
