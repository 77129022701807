import { useMemo, type FC, useState } from 'react';
import type { HeroCoachProps } from './types';

import styled, { css } from 'styled-components';

import {
  ApiService,
  COLOR_YELLOW,
  baseMediaEndpoint,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper, Button, Mockup } from '../Common';
import { HoverableDropzone } from '../HoverableDropzone';
import { Image as ImageComp } from 'shared/components/Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Coaching = styled.div`
  position: relative;
  padding: 73px 0 123px;
  background: #fff;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  padding: 63px 0 53px;
    `}
`;

const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 5;
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  text-align: center;
  margin-bottom: 35px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 20px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const ImageInner = styled.div`
  display: flex;
  position: relative;
  padding-left: 160px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  padding-left: 80px;
`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  padding-left: 20px;
`}

${({ theme: { $width } }) =>
    $width <= 575 &&
    `
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
`}
`;

const MockUpStyled = styled((props) => <Mockup {...props} />)`
  width: 540px;
  height: auto;
  position: relative;
  z-index: 6;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  width: 430px;
`}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  width: 370px;
`}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 80%;
    `}

   ${({ mockup_id, theme: { $width } }) =>
    mockup_id === 3
      ? `width:500px; 

      ${
        $width <= 1254 &&
        `
        width: 400px;
        left: 50px;
      `
      }

      ${
        $width <= 991 &&
        `
        width: 360px;
        left: 50px;
      `
      }

      ${
        $width <= 768 &&
        `
        width: 320px;
        left: 0px;
      `
      }

      ${
        $width <= 575 &&
        `
        width: 70%;
        margin-bottom: 30px;
      `
      }
        `
      : mockup_id === 2
        ? `
      ${
        $width <= 575 &&
        `
        left: -3%;
      `
      }
      `
        : mockup_id === 1
          ? `

      ${
        $width <= 575 &&
        `
        left: 0%;
      `
      }
      `
          : ``}
`;

const PhotoInner = styled.div<{ editable?: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.editable ? 20 : 4)};
  left: 49.5%;
  top: 40px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    left: 45%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    left: 51%;
    `}

    ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
      left: 62%;
      `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 28;
  top: auto;
  position: relative;
    top: -30px;
`}
`;

const photoImages = css`
  border-radius: 50%;
  overflow: visible !important;

  & img {
    width: 465px;
    height: 465px;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
    border: 10px solid;

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
  width: 400px;
    height: 400px;
    border: 13px solid;
`}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
  width: 370px;
  height: 370px;
`}

${({ theme: { $width } }) =>
      $width <= 768 &&
      `
  width: 210px;
  height: 210px;
`}

${({ theme: { $width } }) =>
      $width <= 575 &&
      `
  width: 180px;
  height: 180px;
  border: 8px solid;
  margin: 0 auto;
  display: block;

`}
  }
`;

const OwnerText = styled.div`
  position: absolute;
  right: 14%;
  top: 477px;
  width: 150px;
  text-align: center;
  transform: rotate(330deg);
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  line-height: 1.4em;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  top: 416px;
`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  right: 11%;
  transform: rotate(344deg);
`}

${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  right: 4%;
  transform: rotate(344deg);
  top: 356px;
`}

${({ theme: { $width } }) =>
    $width <= 575 &&
    `
  position: relative;
  right: auto;
  top: auto;
  transform: rotate(0deg);
  margin-top: 10px;
  width: 100%;
`}
`;

const TitleInner = styled.div`
  text-align: center;
  margin-top: 30px;
  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
  margin-top: 10px;
`}
`;

const SubtitleBot = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.4em;
  margin-bottom: 50px;
  position: relative;

  strong {
    font-weight: 700;
    display: block;
  }

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  margin-bottom: 40px;
`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  margin-bottom: 30px;
`}
`;

const ButtonStyled = styled(Button)<{ isEmpty?: boolean; editable?: boolean }>`
  display: inline-block;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 80%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const AbsoluteBg = styled.div`
  position: absolute;
  top: 0px;
  height: 60%;
  width: 100%;
`;

const ColoredBg = styled.div<{ backgroundColor?: string }>`
  background: ${(props) => props.backgroundColor};
  height: 100%;
  position: relative;
  opacity: 0.28;
`;

const SvgBg = styled.svg`
  fill: #fff;
  position: absolute;
  bottom: -2px;
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  height: 685px;
  width: 513px;
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  // object-fit: ${(props) => (props.isImageUploaded ? 'cover' : 'contain')};
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V1: FC<HeroCoachProps> = (props) => {
  const {
    secondaryColor = COLOR_YELLOW,
    backgroundColor = COLOR_YELLOW,
    mockup,
    button = defaultWebValue,
    title = defaultWebValue,
    content = defaultWebValue,
    owner = defaultWebValue,
    textColor,
    hidden,
    meta = {},
    cover_thumbnail
  } = props;

  const { salesPageId, updateImageMeta, pagesThumbnails, isSubscription } = useSales();

  const [isLoading, setIsLoading] = useState([false, false, false]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const { updateActiveElement, resetActiveElement, containerClassname, editable } =
    useWebsiteEditor();

  const { pages, isCoverDisabled, bookMedia } = useBook();
  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages, cover_thumbnail, pagesThumbnails]);

  const youHereImage = useMemo(() => {
    if (meta['you_here']) {
      if (baseMediaEndpoint?.includes('localhost') && !meta['you_here'].includes('localhost')) {
        return `${baseMediaEndpoint}${meta['you_here']}`;
      }
      return meta['you_here'];
    }
    return '/img/you_here.png';
  }, [meta]);

  const handleUpload = async (file: File, type: string, index: number) => {
    setIsLoading((prev) => prev.map((_, i) => (i === index - 1 ? true : false)));
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    if (index === 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          setResize({ width, height });
          updateImageMeta(meta['accessorKey'], width, 'width');
          updateImageMeta(meta['accessorKey'], height, 'height');
        };
        img.src = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, type);
    setIsLoading([false, false]);
  };

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  return (
    <Coaching
      className={containerClassname}
      style={{
        display: hidden && !editable ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        <Title
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor('#fff', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-placeholder="+"
          editable={editable}
          isEmpty={isTitleEmpty}
        />
        <ImageInner>
          {bookMockup ? (
            <HoverableDropzone
              isSalePage
              css={imageStyles as never}
              disabled={!editable}
              allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
              onUpload={(file) => handleUpload(file, 'image', 0)}
              loading={isLoading[0]}
              alreadyUploaded={Boolean(bookMockup)}
              resizeSelector={editable}
              handleResize={({ width, height }) => {
                setResize({ width, height });
                updateImageMeta(meta['accessorKey'], width, 'width');
                updateImageMeta(meta['accessorKey'], height, 'height');
              }}
              imageFile={bookMockup}
              initialPosition={{
                x: meta['x_1'] || 0,
                y: meta['y_1'] || 0,
                width: meta['d_width_1'],
                height: meta['d_height_1']
              }}
              meta={meta}
              positionEnabled
              handlePosition={(width: number, height: number, x: number, y: number) => {
                updateImageMeta(meta['accessorKey'], x, 'x_1');
                updateImageMeta(meta['accessorKey'], y, 'y_1');
                updateImageMeta(meta['accessorKey'], width, 'd_width_1');
                updateImageMeta(meta['accessorKey'], height, 'd_height_1');
              }}
              imageAspectRatio={{
                ratio: '3x4',
                pixels: '600x800'
              }}>
              <BookMediaImg
                isImageUploaded={isImageUploaded}
                src={bookMockup}
                width={resize.width || 500}
                height={resize.height || 500}
                alt="Book Mockup"
              />
            </HoverableDropzone>
          ) : (
            <MockUpStyled
              mockup={mockup?.file}
              mockup_id={mockup?.id}
              cover_thumbnail={coverThumbnail}
            />
          )}

          <PhotoInner editable={editable}>
            <HoverableDropzone
              css={
                css`
                  ${photoImages}
                  & img {
                    border-color: ${secondaryColor};
                  }
                ` as never
              }
              disabled={!editable}
              allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
              onUpload={(file) => handleUpload(file, 'you_here', 1)}
              loading={isLoading[1]}
              borderRadius="50%"
              alreadyUploaded={Boolean(meta['you_here'])}
              imageFile={meta['you_here']}
              imageAspectRatio={{
                ratio: '1x1',
                pixels: '1000x1000'
              }}
              initialPosition={{
                x: meta['x_2'],
                y: meta['y_2'],
                width: meta['d_width_2'],
                height: meta['d_height_2']
              }}
              meta={meta}
              positionEnabled
              handlePosition={(width: number, height: number, x: number, y: number) => {
                updateImageMeta(meta['accessorKey'], x, 'x_2');
                updateImageMeta(meta['accessorKey'], y, 'y_2');
                updateImageMeta(meta['accessorKey'], width, 'd_width_2');
                updateImageMeta(meta['accessorKey'], height, 'd_height_2');
              }}
              size="md">
              <ImageComp
                style={{
                  borderColor: secondaryColor
                }}
                src={youHereImage}
                alt="author photo"
                width={500}
                height={500}
              />
            </HoverableDropzone>
          </PhotoInner>
          <OwnerText>
            <div
              id={owner.id}
              dangerouslySetInnerHTML={{ __html: sanitizeRichText(owner.val) }}
              style={{ color: filterBackgroundColor(secondaryColor, textColor) }}
              onClick={updateActiveElement}
              onBlur={resetActiveElement}
              data-accessor-parent={meta['accessorKey']}
              data-accessorkey="owner"
            />
          </OwnerText>
        </ImageInner>
        <TitleInner>
          <SubtitleBot
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <ButtonStyled
            to={isSubscription ? 'recurring_form' : 'form'}
            button={button}
            meta={meta}
          />
        </TitleInner>
      </StyledWrapper>

      <AbsoluteBg>
        <ColoredBg backgroundColor={backgroundColor}>
          <SvgBg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1771 262" fill="none">
            <path d="M1752.95 29.3134L1770.5 35.5V262H0V35.5L329.099 147.318C536.251 217.701 760.139 222.07 969.879 159.823L1436.47 21.3462C1540.16 -9.42859 1650.93 -6.64005 1752.95 29.3134Z" />
          </SvgBg>
        </ColoredBg>
      </AbsoluteBg>
    </Coaching>
  );
};
