import { useContext, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type {
  BenefitsTypes,
  Book,
  BookPages,
  Funnel,
  OrderItem,
  PageData,
  Sales,
  SalesInitializedProduct,
  SalesItem,
  SalesStaticItem,
  StaticComponentDataType
} from 'shared/types/index';
import type { TypeOfPages } from '../context/SalesContext';
import {
  ApiService,
  baseKeysForPriceButtons,
  colorToKeyMapping,
  convertColorsToPallete,
  CURRENCIES,
  customDeliveryUrl,
  DefaultComponentAccesories,
  // areArraysEqual,
  generateUUID,
  languageMapping,
  PRIMARY_CURRENCY_SYMBOLS,
  randomItem,
  removeStrings,
  TOP_USED_CURRENCIES
} from 'shared/helpers/index';
import useSales from './useSales';
import { WebsiteCreationContext } from '../context';
import useBook from './useBook';
import useAuth from './useAuth';

import useBranding from './useBranding';
import slugify from 'slugify';
import useDeliveryEmail from './useDeliveryEmail';
import { generateComponentWithFaker } from 'shared/helpers/openAiFaker';
import useOpenAi from './useOpenAi';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const salesTypeToStaticMapping = {
  sales: 'sales_page',
  delivery: 'delivery_page'
};

const funnelType = 'digital_product';

/**
 * @function useWebsiteCreation
 *
 * @param {string} book_id - The book id
 *
 * @description This hook is used for all operations in the website creation process.
 * It uses the OpenAI hook to generate text and the Sales hook to manage the sales data.
 */
const useWebsiteCreation = () => {
  const { updateDeliveryEmail } = useDeliveryEmail();
  const { runAi } = useOpenAi();
  const { i18n } = useTranslation();

  const { isLoading, updateLoading } = useContext(WebsiteCreationContext);

  const {
    webPrompt,
    ai: aiPrompt,
    updateWebPrompt,
    // price,
    salesPageValues,
    deliveryPageValues,
    websiteStaticData,
    type,
    updateInitialWebsite,
    salesPageId,
    updateAi,
    salesComponentOrdering,
    selectedCurrency,
    setFunnels,
    metaTags,
    setSalesPageCollection
  } = useSales();
  const { bookName, bookMedia, setSalesData, salesData, updateSalesId } = useBook();
  const { user, userAvatar, isStaff } = useAuth();

  const { colorPalette } = useBranding();

  const generateWebsiteValue = async (name: string) => {
    const baseKey = name?.replace(/_\d+$/, '');
    const prompt = await generateAiBasedOnKey(baseKey ?? '', '1', funnelType, aiPrompt);

    const data = type === 'sales' ? cloneDeep(salesPageValues) : cloneDeep(deliveryPageValues);
    const item = cloneDeep(data?.[name]);
    if (typeof item === 'object' && data) {
      const filled = fillExistingComponentValues(item, prompt);
      data[name] = filled;
      updateInitialWebsite(type, data);
    }
    return prompt;
  };

  const generateWebsiteValues = async (
    newPrompt?: boolean,
    funnelType?: string,
    defaultAi = bookName,
    image_meta: Record<string, Record<string, unknown>> = {}
  ) => {
    updateLoading(true);
    if (newPrompt) updateWebPrompt(aiPrompt);

    if (!funnelType) return {};
    let salesDigitalProduct = [] as OrderItem[];

    // if (salesPageValues) {
    //   Object.keys(salesPageValues)
    //     .filter((key) => {
    //       const value = salesPageValues?.[key];
    //       return typeof value === 'object';
    //     })
    //     .sort((a, b) => {
    //       const valueA = salesPageValues?.[a];
    //       const valueB = salesPageValues?.[b];
    //       if (typeof valueA !== 'object' || typeof valueB !== 'object') return 0;
    //       return valueA?.order - valueB?.order;
    //     })
    //     .forEach((key) => {
    //       const value = salesPageValues?.[key];
    //       salesDigitalProduct.push({
    //         name: key.replace(/_\d+$/, ''),
    //         version:
    //           typeof value === 'object' && value.version
    //             ? Number(value.version.replace('V', ''))
    //             : 1
    //       });
    //     });
    // }
    if (salesDigitalProduct.length === 0) {
      salesDigitalProduct =
        salesComponentOrdering['sales_page'][funnelType] ||
        salesComponentOrdering['sales_page']['digital_product'];
    }

    const saledDigitalGenerated = await Promise.all(
      salesDigitalProduct?.map((d) =>
        generateAiBasedOnKey(d?.name ?? '', String(d.version), funnelType, defaultAi)
      )
    );
    const salesValues = saledDigitalGenerated.map((d, index) => {
      return {
        values: d,
        ...salesDigitalProduct[index]
      };
    });
    let deliveryDigitalProduct = [] as OrderItem[];
    // if (deliveryPageValues) {
    //   Object.keys(deliveryPageValues)
    //     .filter((key) => {
    //       const value = deliveryPageValues?.[key];
    //       return typeof value === 'object';
    //     })
    //     .sort((a, b) => {
    //       const valueA = deliveryPageValues?.[a];
    //       const valueB = deliveryPageValues?.[b];
    //       if (typeof valueA !== 'object' || typeof valueB !== 'object') return 0;
    //       return valueA?.order - valueB?.order;
    //     })
    //     .forEach((key) => {
    //       const value = deliveryPageValues?.[key];
    //       deliveryDigitalProduct.push({
    //         name: key.replace(/_\d+$/, ''),
    //         version:
    //           typeof value === 'object' && value.version
    //             ? Number(value.version.replace('V', ''))
    //             : 1
    //       });
    //     });
    // }

    if (deliveryDigitalProduct.length === 0) {
      deliveryDigitalProduct =
        salesComponentOrdering['delivery_page'][funnelType] ||
        salesComponentOrdering['delivery_page']['digital_product'];
    }

    const deliveryDigitalGenerated = await Promise.all(
      deliveryDigitalProduct.map((d) =>
        generateAiBasedOnKey(d?.name ?? '', String(d.version), funnelType, defaultAi)
      )
    );
    const deliveryValues = deliveryDigitalGenerated.map((d, index) => {
      return {
        values: d,
        ...deliveryDigitalProduct[index]
      };
    });

    // const bookAboutMe = bookMedia?.find((m) => m.name.includes('photo'));
    // const aboutMeImageUrl = bookAboutMe?.url || userAvatar?.file;
    const about_me_image = '';

    // if (aboutMeImageUrl) {
    //   const response = await fetch(aboutMeImageUrl);
    //   const blob = await response.blob();
    //   const formData = new FormData();
    //   formData.append('type', 'about_me_image');
    //   formData.append('file', blob);
    //   const { data } = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    //   about_me_image = data.file;
    // }

    const salesIdsContainer = {} as Record<string, number>;
    const generatedSalesComponents = salesValues
      .map((x, index) => {
        if (salesIdsContainer[x.name]) {
          salesIdsContainer[x.name] = salesIdsContainer[x.name] + 1;
        } else {
          salesIdsContainer[x.name] = 1;
        }
        return generateComponentValue(
          x.name,
          x.values,
          x.version,
          index,
          `${x.name}_${salesIdsContainer[x.name]}`,
          '',
          about_me_image,
          image_meta
        );
      })
      .reduce((acc, curr) => {
        return { ...acc, [curr?.id as string]: curr };
      }, {});

    const deliveryIdsContainer = {} as Record<string, number>;
    const generatedDeliveryComponents = deliveryValues
      .map((x, index) => {
        if (deliveryIdsContainer[x.name]) {
          deliveryIdsContainer[x.name] = deliveryIdsContainer[x.name] + 1;
        } else {
          deliveryIdsContainer[x.name] = 1;
        }
        return generateComponentValue(
          x.name,
          x.values,
          x.version,
          index,
          `${x.name}_${deliveryIdsContainer[x.name]}`,
          x.type,
          undefined,
          image_meta
        );
      })
      .reduce((acc, curr) => {
        return { ...acc, [curr?.id as string]: curr };
      }, {});

    updateLoading(false);
    return {
      page_data: generatedSalesComponents as PageData,
      delivery_page_data: generatedDeliveryComponents as PageData
    };
  };

  const defaultEmptyValues = (key: string) => {
    const sampleData = DefaultComponentAccesories[key];

    const defaultEmptyData = {
      ...(sampleData?.id && { id: generateUUID() }),
      ...(sampleData?.title && { title: '' }),
      ...(sampleData?.content && { content: '' }),
      ...(sampleData?.button && { button: '' }),
      ...(sampleData?.benefits &&
        sampleData.benefits.length > 0 && {
          benefits: sampleData.benefits.map((benefit) => {
            return {
              ...(benefit?.id && { id: generateUUID() }),
              ...(benefit.title && { title: '' }),
              ...(benefit.content && { content: '' }),
              ...(benefit.button && { button: '' })
            };
          })
        })
    };

    return cloneDeep(defaultEmptyData);
  };

  const getStaticValues = (key: string, version: string = '1', newFunnelType = funnelType) => {
    const staticType = salesTypeToStaticMapping[type];
    const DEFAULT_EMPTY_VALUES = defaultEmptyValues(key);

    const componentStaticValues = websiteStaticData[key];
    if (!componentStaticValues) return DEFAULT_EMPTY_VALUES;
    let productStaticvalues = componentStaticValues[Number(version)] || componentStaticValues[1];
    if (!productStaticvalues) {
      productStaticvalues = componentStaticValues[1];
    }
    if (!productStaticvalues) return DEFAULT_EMPTY_VALUES;
    const funnelStaticValues = productStaticvalues[newFunnelType];
    if (!funnelStaticValues) return DEFAULT_EMPTY_VALUES;
    const staticValues = funnelStaticValues[staticType];
    if (!staticValues) return DEFAULT_EMPTY_VALUES;
    const staticValue = randomItem(staticValues ?? []);

    return staticValue;
  };

  const getPromptValues = async (
    key: string,
    _version: string = '1',
    newFunnelType = funnelType,
    defaultAi = bookName
  ) => {
    // const staticType = salesTypeToStaticMapping[type];
    const DEFAULT_EMPTY_VALUES = defaultEmptyValues(key);

    // const componentStaticValues = websitePrompts[key];
    // if (!componentStaticValues) return DEFAULT_EMPTY_VALUES;
    // const productPromptValues = componentStaticValues[1] || componentStaticValues[1];
    // if (!productPromptValues) return DEFAULT_EMPTY_VALUES;
    // const funnelPromptValues = productPromptValues[newFunnelType];
    // if (!funnelPromptValues) return DEFAULT_EMPTY_VALUES;
    // const promptValues = funnelPromptValues[staticType];
    // if (!promptValues) return DEFAULT_EMPTY_VALUES;

    // const prompt = promptValues?.prompt?.[0];
    // // const prompt = randomItem(promptValues.prompt ?? []); Stop Randomizing and use V1 for now

    // if (!prompt) return DEFAULT_EMPTY_VALUES;

    const aiValueOfWebsite = aiPrompt || webPrompt || bookName || defaultAi;
    if (localStorage.getItem('isAiMocked') === 'true') {
      return generateComponentWithFaker();
    }
    try {
      const response = await ApiService.post(`sales/sales-page/generate-ai-sales/`, {
        name: key,
        funnel_name: newFunnelType,
        sale_page_type: type === 'sales' ? 'sales_page' : 'delivery_page',
        prompt_input: aiValueOfWebsite,
        language: i18n.language || 'en',
        retry_count: 2
      });
      if (!response.data) return DEFAULT_EMPTY_VALUES;
      return response.data;
    } catch {
      return DEFAULT_EMPTY_VALUES;
    }
  };

  const replaceStaticVariables = (value?: string) => {
    if (!value) return '';

    const replacedValue = value
      .replaceAll('{PRODUCT.NAME}', bookName)
      .replaceAll('{USER.NAME}', user?.entity?.name || '')
      .replaceAll(
        '{NAME}',
        (() => {
          if (typeof user !== 'object' || user === null) return '';

          const entityName = user?.entity?.name;
          const businessName = user?.business_name;

          if (typeof entityName === 'string' && entityName.trim()) {
            return entityName.split(' ')[0]; // Use the first part of the entity name
          }

          if (typeof businessName === 'string' && businessName.trim()) {
            return businessName; // Use the business name if no entity name is found
          }

          return '';
        })()
      )
      .replaceAll('{USER.BUSINESS_NAME}', user?.business_name || '')
      .replaceAll('{PRODUCT.DESCRIPTION}', (metaTags?.['product_description'] as string) || '')
      .replaceAll('{PRODUCT.SPECIFICATION}', (metaTags?.['product_specification'] as string) || '');

    return replacedValue;
  };

  const fillComponentValues = async (
    key: string,
    promptValue: SalesStaticItem,
    staticValue: SalesStaticItem
  ) => {
    const value = defaultEmptyValues(key) as SalesStaticItem;
    promptValue = JSON.parse(JSON.stringify(promptValue).replaceAll('\n', ''));

    let titleValue = promptValue.title || replaceStaticVariables(staticValue.title);
    let contentValue = promptValue.content || replaceStaticVariables(staticValue.content);
    let buttonValue = promptValue.button || replaceStaticVariables(staticValue.button);

    const isTranslationNeeded = !['en', 'en-gb'].includes(i18n.language);

    if (!promptValue.title && titleValue && isTranslationNeeded) {
      titleValue = await runAi(
        `Translate This to ${titleValue}: ${languageMapping[i18n.language]}`,
        'Translation'
      );
    }
    if (!promptValue.content && contentValue && isTranslationNeeded) {
      contentValue = await runAi(
        `Translate This to ${contentValue}: ${languageMapping[i18n.language]}`,
        'Translation'
      );
    }
    if (!promptValue.button && buttonValue && isTranslationNeeded) {
      buttonValue = await runAi(
        `Translate This to ${buttonValue}: ${languageMapping[i18n.language]}`,
        'Translation'
      );
    }

    if (Object.prototype.hasOwnProperty.call(value, 'title') && titleValue) {
      value['title'] = titleValue;
    }
    if (Object.prototype.hasOwnProperty.call(value, 'content') && contentValue) {
      value['content'] = contentValue;
    }
    if (Object.prototype.hasOwnProperty.call(value, 'button') && buttonValue) {
      value['button'] = buttonValue;
    }
    if (Object.prototype.hasOwnProperty.call(value, 'benefits')) {
      value['benefits'] = value['benefits'] || [];
      if (staticValue?.benefits?.length && staticValue?.benefits?.length > 0) {
        value['benefits'] = staticValue.benefits;
      }
      if (promptValue?.benefits?.length && promptValue?.benefits?.length > 0) {
        const temporaryBenefits = promptValue.benefits || value.benefits;
        value['benefits'] = temporaryBenefits?.map((x, index) => {
          let copiedBenefit = { ...x };
          if (x.title) {
            copiedBenefit = {
              ...copiedBenefit,
              title: promptValue.benefits?.[index]?.title
            };
          }
          if (x.content) {
            copiedBenefit = {
              ...copiedBenefit,
              content: promptValue.benefits?.[index]?.content
            };
          }
          if (x.button) {
            copiedBenefit = {
              ...copiedBenefit,
              button: promptValue.benefits?.[index]?.button
            };
          }
          return copiedBenefit;
        });
      }
      value.benefits?.forEach(async (benefit, index) => {
        const promptBenefit = promptValue.benefits?.[index];
        const staticBenefit = staticValue.benefits?.[index];

        let titleBenefit = promptBenefit?.title || replaceStaticVariables(staticBenefit?.title);
        let contentBenefit =
          promptBenefit?.content || replaceStaticVariables(staticBenefit?.content);
        let buttonBenefit = promptBenefit?.button || replaceStaticVariables(staticBenefit?.button);

        if (!promptValue?.benefits?.[index]?.title && titleBenefit && isTranslationNeeded) {
          titleBenefit = await runAi(
            `Translate This to ${titleBenefit}: ${languageMapping[i18n.language]}`,
            'Translation'
          );
        }
        if (!promptValue?.benefits?.[index]?.content && contentBenefit && isTranslationNeeded) {
          contentBenefit = await runAi(
            `Translate This to ${contentBenefit}: ${languageMapping[i18n.language]}`,
            'Translation'
          );
        }
        if (!promptValue?.benefits?.[index]?.button && buttonBenefit && isTranslationNeeded) {
          buttonBenefit = await runAi(
            `Translate This to ${buttonBenefit}: ${languageMapping[i18n.language]}`,
            'Translation'
          );
        }

        if (Object.prototype.hasOwnProperty.call(benefit, 'title') && titleBenefit) {
          benefit['title'] = titleBenefit;
        }
        if (Object.prototype.hasOwnProperty.call(benefit, 'content') && contentBenefit) {
          benefit['content'] = contentBenefit;
        }
        if (Object.prototype.hasOwnProperty.call(benefit, 'button') && buttonBenefit) {
          benefit['button'] = buttonBenefit;
        }
      });
    }

    return value;
  };

  const externalProductValues = (key: string, bullets: string[], benefitCount: number) => {
    const benefits = [] as Record<string, string>[];
    if (key.includes('check_section')) {
      let splitChar = '';
      const splitters = [':', '】', ']'];
      const splitterMap = {
        ':': ':',
        '】': '【',
        ']': '[',
        ')': '('
      } as Record<string, string>;
      splitters.forEach((splitter) => {
        if (bullets.every((bullet) => bullet.includes(splitter))) {
          splitChar = splitter;
        }
      });
      if (splitChar) {
        bullets.slice(0, benefitCount).forEach((bullet) => {
          const [t, c] = bullet.split(splitChar);

          benefits.push({
            title: t.replaceAll(splitChar, '').replaceAll(splitterMap[splitChar], '').trim(),
            content: c.replaceAll(splitChar, '').replaceAll(splitterMap[splitChar], '').trim()
          });
        });
      } else {
        bullets.slice(0, benefitCount).forEach((bullet) => {
          benefits.push({
            title: '',
            content: bullet
          });
        });
      }
    }
    return {
      benefits
    };
  };

  const generateAiBasedOnKey = async (
    key: string,
    version: string = '1',
    newFunnelType = funnelType,
    defaultAi = bookName
  ) => {
    let staticValues = getStaticValues(key, version, newFunnelType);
    let promptValues = await getPromptValues(key, version, newFunnelType, defaultAi);
    if (
      metaTags['amazon_url'] &&
      metaTags?.['amazon_feature_bullets'] &&
      typeof metaTags?.['amazon_feature_bullets'] === 'object' &&
      metaTags?.['amazon_feature_bullets']?.length > 0 &&
      key.includes('check_section')
    ) {
      staticValues = externalProductValues(
        key,
        metaTags?.['amazon_feature_bullets'],
        staticValues.benefits?.length
      );
      promptValues = staticValues;
    }
    if (isStaff && !user?.email?.includes('platoni')) {
      console.log('key', key);
      console.log('promptValues', promptValues);
      console.log('staticValues', staticValues);
      console.log('=============================');
    }
    return fillComponentValues(key, promptValues, staticValues);
  };

  const fillExistingComponentValues = (component: SalesItem, values: SalesStaticItem) => {
    if (component?.title) {
      const defaultValue = typeof component?.title === 'string' ? component?.title : '';
      component['title'] = {
        ...component.title,
        val: values.title || defaultValue
      };
    }
    if (component?.content) {
      const defaultValue = typeof component?.['content'] === 'string' ? component?.['content'] : '';
      component['content'] = {
        ...component.content,
        val: values['content'] || defaultValue
      };
    }
    if (component?.button) {
      const defaultValue = typeof component?.button === 'string' ? component?.button : '';
      component['button'] = {
        ...component.button,
        val: values.button || defaultValue
      };
    }
    if (component?.benefits && component?.benefits?.length > 0) {
      const benefits = component.benefits;
      benefits.forEach((benefit, index) => {
        const defaultTitle = typeof benefit.title?.val === 'string' ? benefit?.title.val : '';
        const defaultContent = typeof benefit.content?.val === 'string' ? benefit.content.val : '';
        const defaultButton = typeof benefit.button?.val === 'string' ? benefit.button.val : '';

        const benefitsValue = values.benefits;

        if (benefit.title) {
          benefit['title'] = {
            ...component.title,
            val: benefitsValue?.[index]?.title || defaultTitle
          };
        }
        if (!benefit.title && benefitsValue?.[index]?.title) {
          benefit['title'] = {
            ...component.title,
            val: benefitsValue?.[index]?.title,
            id: generateUUID()
          };
        }
        if (benefit.content) {
          benefit['content'] = {
            ...component.content,
            val: benefitsValue?.[index]?.content || defaultContent
          };
        }
        if (!benefit.content && benefitsValue?.[index]?.content) {
          benefit['content'] = {
            ...component.content,
            val: benefitsValue?.[index]?.content,
            id: generateUUID()
          };
        }
        if (benefit.button) {
          benefit['button'] = {
            ...component.button,
            val: benefitsValue?.[index]?.button || defaultButton
          };
        }
        if (!benefit.button && benefitsValue?.[index]?.button) {
          benefit['button'] = {
            ...component.content,
            val: benefitsValue?.[index]?.button || defaultButton,
            id: generateUUID()
          };
        }
      });
    }
    return component;
  };

  const categorizedColorPalette = useMemo(() => {
    return convertColorsToPallete(colorPalette);
  }, [colorPalette]);

  const generateComponentValue = (
    selector: string,
    values: SalesStaticItem,
    version: number,
    order: number,
    componentId: string,
    type?: string,
    about_me_image?: string,
    image_meta: Record<string, Record<string, unknown>> = {}
  ) => {
    const component = DefaultComponentAccesories[selector];
    if (!component) return;

    const generatedComponent = {
      id: componentId,
      version: `V${version}`,
      order: order,
      hidden: false,
      meta: {}
    } as SalesItem;

    if (type) {
      generatedComponent['meta'] = {
        [type]: type === 'url' ? '' : true
      };
    }

    if (!generatedComponent['id']) generatedComponent['id'] = generateUUID();

    if (selector.includes('about_me')) {
      const bookAboutMe = bookMedia?.find((m) => m.name.includes('photo'));
      const aboutMeImageUrl = about_me_image || bookAboutMe?.url || userAvatar?.file;
      generatedComponent['meta'] = {
        image: aboutMeImageUrl
      };
    }

    if (
      (['hero_coach', 'hero_basic', 'hero_coach'].includes(selector) || selector === 'hero') &&
      image_meta['book_mockup']
    ) {
      const value = image_meta['book_mockup'];
      generatedComponent['meta'] = {
        ...generatedComponent['meta'],
        ...value
      };
    }

    if (selector.includes('check_section') && image_meta['hero_mockup']) {
      const value = image_meta['hero_mockup'];
      generatedComponent['meta'] = {
        ...generatedComponent['meta'],
        ...value
      };
    }

    if (selector.includes('mini_hero') && image_meta['minihero_mockup']) {
      const value = image_meta['minihero_mockup'];
      generatedComponent['meta'] = {
        ...generatedComponent['meta'],
        ...value
      };
    }

    if (selector.includes('hero') && userAvatar?.file) {
      generatedComponent['meta'] = {
        ...generatedComponent['meta'],
        you_here: userAvatar?.file
      };
    }

    if (component.title) {
      generatedComponent['title'] = {
        id: generateUUID(),
        val: values.title || ''
      };
    }
    if (component.content) {
      generatedComponent['content'] = {
        id: generateUUID(),
        val: values.content || ''
      };
    }

    const lowerCaseColors = categorizedColorPalette?.mainColors.map((color) => ({
      ...color,
      value: color.value.toLowerCase()
    }));

    const index = lowerCaseColors.findIndex((c) => c.value === salesPageValues?.website_color);
    const secondary =
      lowerCaseColors.length === index + 1 ? lowerCaseColors[0] : lowerCaseColors[index + 1];
    const secondaryIndex = lowerCaseColors.findIndex((c) => c.value === secondary.value);
    const button =
      lowerCaseColors.length === secondaryIndex + 1
        ? lowerCaseColors[0]
        : lowerCaseColors[secondaryIndex + 1];

    if (component.button) {
      generatedComponent['button'] = {
        id: generateUUID(),
        val: values.button || '',
        color: button?.value || colorPalette.color3
      };
    }
    if (component.benefits && component.benefits.length > 0) {
      generatedComponent['benefits'] = values.benefits?.map((benefit) => {
        const benefitPart = {} as BenefitsTypes;

        if (benefit?.title) {
          benefitPart['title'] = {
            id: generateUUID(),
            val: benefit?.title
          };
        }
        if (benefit?.content) {
          benefitPart['content'] = {
            id: generateUUID(),
            val: benefit?.content
          };
        }
        if (benefit?.button) {
          benefitPart['button'] = {
            id: generateUUID(),
            val: benefit?.button,
            color: colorPalette.color3
          };
        }

        return benefitPart;
      });
    }

    return generatedComponent;
  };

  const generateComponentValues = async (
    components: OrderItem[],
    type: TypeOfPages = 'sales',
    newFunnelType: string,
    image_data: Record<string, Record<string, unknown>> = {}
  ) => {
    const componentDatas = (await Promise.allSettled(
      components.map(async (component) => {
        const { name, version } = component;
        return generateAiBasedOnKey(name, `V${version}`, newFunnelType);
      })
    )) as { status: string; value: SalesStaticItem }[];
    const idsContainer = {} as Record<string, number>;
    const componentValues = componentDatas.map((x) => x.value);
    // const bookAboutMe = bookMedia?.find((m) => m.name.includes('photo'));
    // const aboutMeImageUrl = bookAboutMe?.url || userAvatar?.file;
    const about_me_image = '';

    // if (aboutMeImageUrl) {
    //   const response = await fetch(aboutMeImageUrl);
    //   const blob = await response.blob();
    //   const formData = new FormData();
    //   formData.append('type', 'about_me_image');
    //   formData.append('file', blob);
    //   const { data } = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    //   about_me_image = data.file;
    // }
    const generatedComponents = components
      .map((x, index) => {
        if (idsContainer[x.name]) {
          idsContainer[x.name] = idsContainer[x.name] + 1;
        } else {
          idsContainer[x.name] = 1;
        }
        return generateComponentValue(
          x.name,
          componentValues[index],
          x.version,
          index + 1,
          `${x.name}_${idsContainer[x.name]}`,
          x.type,
          about_me_image,
          image_data
        );
      })
      .reduce((acc, curr) => {
        return { ...acc, [curr?.id as string]: curr };
      }, {});

    let copiedValues: PageData | null | undefined;

    if (type === 'sales') {
      copiedValues = cloneDeep(salesPageValues);

      Object.keys(salesPageValues ?? {}).forEach((x) => {
        const value = salesPageValues?.[x];
        if (typeof value === 'object') {
          delete copiedValues?.[x];
        }
      });
    } else {
      copiedValues = cloneDeep(deliveryPageValues);

      Object.keys(deliveryPageValues ?? {}).forEach((x) => {
        const value = deliveryPageValues?.[x];
        if (typeof value === 'object') {
          delete copiedValues?.[x];
        }
      });
    }

    const concatenatedValues = {
      ...copiedValues,
      ...generatedComponents
    } as PageData;
    updateInitialWebsite(type, concatenatedValues);
    localStorage.setItem(
      `${salesPageId}-${type}-salesFunnelData-${newFunnelType}`,
      JSON.stringify(concatenatedValues)
    );
    return concatenatedValues;
  };

  const removePriceFromComponent = (value: string) => {
    let cleanedValue = value.replace(/\d+([,.]\d+)?/g, '').trim();
    cleanedValue = removeStrings(cleanedValue, Object.keys(TOP_USED_CURRENCIES));
    cleanedValue = removeStrings(cleanedValue, Object.values(TOP_USED_CURRENCIES));
    cleanedValue = cleanedValue.replaceAll('/m', '').replaceAll('/y', '');

    return cleanedValue;
  };

  const applyPriceToComponent = (value: string, price: number, currency: string) => {
    const currencySymbol = CURRENCIES[currency as keyof typeof CURRENCIES] || currency;
    let newValue = value;
    if (price > 0) {
      newValue = `${newValue} ${currencySymbol}${price}`;
      const primaryCurrencySelection =
        PRIMARY_CURRENCY_SYMBOLS[currencySymbol as keyof typeof PRIMARY_CURRENCY_SYMBOLS];
      if (primaryCurrencySelection && primaryCurrencySelection !== currency) {
        newValue = `${newValue} ${currency}`;
      }
    }
    return newValue;
  };

  const updatePriceForComponents = (
    initialData: PageData,
    price: number,
    currency: string = 'USD',
    isLocalUpdate: boolean = true
  ) => {
    let data = { ...initialData };
    Object.keys(data).forEach((key) => {
      if (!baseKeysForPriceButtons.some((base) => key.includes(base))) {
        return;
      }
      const copy = data[key];
      let payload = {};

      if (typeof copy === 'object') {
        const value = copy?.button?.val || '';
        const cleanedValue = removePriceFromComponent(value);
        const newValue = applyPriceToComponent(cleanedValue, price, currency);

        payload = {
          ...data,
          [key]: {
            ...copy,
            button: {
              ...copy.button,
              val: newValue
            }
          }
        };
        data = {
          ...data,
          ...payload
        };
      }
    });
    if (isLocalUpdate) {
      updateInitialWebsite(type, data);
    }
    return data;
  };

  const patchDelivery = async (id: number, subDomain: string, deliveryToken: string) => {
    const deliveryEmail = await ApiService.get(`/sales/book-delivery-email/${id}/`);
    await updateDeliveryEmail(id, {
      ...deliveryEmail.data,
      body: deliveryEmail.data.body.replace(
        'THANK_YOU_URL_',
        customDeliveryUrl(subDomain, deliveryToken)
      )
    });
  };

  const initializeDraftWebsite = async (
    book: Book,
    salesInitializedProduct: SalesInitializedProduct,
    extraPayload: Record<string, string | number> = {},
    extraSalesHeader: Record<string, unknown> = {},
    funnelType: string = 'digital_product'
  ) => {
    const customPage = book.pages?.find((p: BookPages) => p.name?.includes('AboutMe'));
    setSalesData({
      mockup: book.mockup,
      cover_thumbnail: book.cover_image_preview,
      pages_thumbnails: book.pages?.map((x: BookPages) => x.image_preview),
      about_me_image: customPage?.image_preview || '',
      color_palette: book.options.color_palette,
      book_file: book.book_file,
      book_name: book.name,
      type: 'sales',
      ...(book.sale_page && { id: book.sale_page })
    });

    // Return if sales page already exists, otherwise create a draft one
    if (salesData.id) return;
    updateAi(book.name);
    updateWebPrompt(book.name);

    const { salesPageData, deliveryPageData, extraHeader } = salesInitializedProduct;

    const pricingUpdates = updatePriceForComponents(
      salesPageData,
      Number(extraPayload?.['price']),
      selectedCurrency,
      false
    );

    let footer = user?.entity?.name;
    if (footer && user?.business_name) {
      footer = ` ${footer} & ${user?.business_name} `;
    }
    if (!footer) {
      footer = user?.business_name || user?.email;
    }
    const extra_body = {
      footer: ` ${footer} `,
      formButton: 'Sign Up to Get Started'
    };

    const payload = {
      type: 'lead_magnet',
      price: 0,
      price_currency: selectedCurrency,
      color_palette: book.options.color_palette,
      delivery_page: 'basic',
      funnel_type: funnelType,
      page_data: {
        ...pricingUpdates,
        website_color: 'color2',
        base_color: colorToKeyMapping('#000000', colorPalette),
        secondary_color: 'color3',
        pawprint: true
      },
      delivery_page_data: {
        ...deliveryPageData,
        base_color: colorToKeyMapping('#000000', colorPalette),
        website_color: 'color2',
        secondary_color: 'color3',
        ...(metaTags['amazon_url'] && { no_delivery: true })
      },
      extra_body,
      sub_domain: slugify(generateUUID(), { lower: true }),
      is_draft: true,
      sales_prompt: book.name,
      book: +book.id,
      extra_head: {
        ...extraHeader,
        ...extraSalesHeader
      },
      ...extraPayload
    };

    const response = await ApiService.post('/sales/sales-page/', payload);
    updateSalesId(response.data.id);
    await patchDelivery(response.data.id, response.data.sub_domain, response.data.delivery_token);
    const salesPage = await ApiService.get(`/sales/sales-page/${response.data.id}/`);
    return salesPage;
  };

  const addNewFunnelToStaffComponents = (data: StaticComponentDataType, newFunnelType: string) => {
    const dataCopy = { ...data };
    Object.keys(data || {}).forEach((key) => {
      const versionItem = data[key];
      Object.keys(versionItem).forEach((version) => {
        const funnelItem = versionItem[version];
        if (!funnelItem[newFunnelType]) {
          funnelItem[newFunnelType] = funnelItem['short_and_sweet'];
        }
        versionItem[version] = funnelItem;
      });
      dataCopy[key] = versionItem;
    });
    return dataCopy;
  };

  const { refetch: refetchSalesFunnel } = useQuery(
    `/sales/sale-funnel/`,
    () => ApiService.get(`/sales/sale-funnel/`),
    {
      select: (response) => response?.data?.results,
      onSuccess: (data) => {
        const sorted = data.sort((a: Funnel, b: Funnel) => a.id - b.id);
        setFunnels(sorted);
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!user?.user_id
    }
  );

  useQuery(`/sales/sales-page/`, () => ApiService.get(`/sales/sales-page/`), {
    select: (response) => response.data?.results as Sales[],
    onSuccess: (results) => {
      setSalesPageCollection(results);
    },
    refetchOnWindowFocus: false,
    retry: false
  });

  return {
    isLoading,
    updateLoading,
    generateWebsiteValues,
    generateAiBasedOnKey,
    fillExistingComponentValues,
    generateComponentValues,
    generateComponentValue,
    initializeDraftWebsite,
    addNewFunnelToStaffComponents,
    generateWebsiteValue,
    updatePriceForComponents,
    removePriceFromComponent,
    applyPriceToComponent,
    salesFunnelActions: {
      refetchSalesFunnel
    }
  };
};

export default useWebsiteCreation;
