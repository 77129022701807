import type {
  Cover,
  Mockup,
  BookPages,
  Sales,
  SavedPage,
  Media,
  NicheAndTopic,
  SystemBatch,
  BookFolder,
  Book
} from 'shared/types/index';
import type { FC, PropsWithChildren } from 'react';

import { createContext, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';

type BookContextData = {
  bookId: string;
  setBookId: (data: string) => void;
  bookName: string;
  setBookName: (data: string) => void;
  bookSubtitle: string;
  setBookSubtitle: (data: string) => void;
  cover?: Cover;
  setCover: (data: Cover) => void;
  coverCollection: Cover[];
  setCoverCollection: (data: Cover[]) => void;
  isCoverDisabled: boolean;
  setIsCoverDisabled: (data: boolean) => void;
  mockup?: Mockup;
  setMockup: (data: Mockup) => void;
  mockupCollection: Mockup[];
  setMockupCollection: (data: Mockup[]) => void;
  aboutMePageIds: number[];
  setAboutMePageIds: (data: number[]) => void;
  books: Book[];
  setBooks: (data: Book[]) => void;
  bookFolders: BookFolder[];
  setBookFolders: (data: BookFolder[]) => void;
  pages: BookPages[];
  setPages: (data: BookPages[]) => void;
  history: BookPages[];
  updateHistory: (data: BookPages[]) => void;
  updatePages: (data: BookPages[]) => void;
  syncPage: (
    page: BookPages,
    ingredient_metadata: Record<string, Record<string, string>>,
    everything?: boolean
  ) => void;
  page: BookPages | undefined;
  renderPage: BookPages | undefined;
  setPage: (data?: BookPages) => void;
  updatePage: (data?: BookPages) => void;
  updateRenderPage: (data?: BookPages) => void;
  setRenderPage: (data?: BookPages) => void;
  salesData: Partial<Sales>;
  setSalesData: (data: Partial<Sales>) => void;
  updateSalesId: (data: number) => void;
  parentDimensions: { width: number; height: number };
  updateParentRef: (el: HTMLDivElement) => void;
  scale: number;
  pendingChanges: boolean;
  updatePendingChanges: (value: boolean) => void;
  previewScale: number;
  responsiveDimensions: {
    width: number;
    height: number;
  };
  resetBookValues: () => void;
  savedPages: SavedPage[];
  setSavedPages: (data: SavedPage[]) => void;
  bookMedia?: Media[];
  setBookMedia: (data: Media[]) => void;
  updateBookMedia: (data: Media) => void;
  updateBookMedias: (data: Media[]) => void;
  activeElement: HTMLElement | null;
  updateActiveElement: (el: HTMLElement) => void;
  resetActiveElement: () => void;
  saveModal: boolean;
  updateSaveModal: (value: boolean) => void;
  lastModified: string;
  updateLastModified: (date: string) => void;
  editable: boolean;
  updateEditable: (value: boolean) => void;
  showLink: boolean;
  updateShowLink: (value: boolean) => void;
  setSalesHomePageValues: (data: Sales) => void;
  productTopics: NicheAndTopic[];
  setProductTopics: (data: NicheAndTopic[]) => void;
  productNiches: NicheAndTopic[];
  setProductNiches: (data: NicheAndTopic[]) => void;
  batchSearchKeyword: string;
  setBatchSearchKeyword: (data: string) => void;
  selectedTopics: NicheAndTopic[];
  setSelectedTopics: (data: NicheAndTopic[]) => void;
  selectedNiches: NicheAndTopic[];
  setSelectedNiches: (data: NicheAndTopic[]) => void;
  batchIds: number[];
  setBatchIds: (data: number[]) => void;
  selectedBatches: SystemBatch[];
  setSelectedBatches: (data: SystemBatch[]) => void;
  toggleSelectedBatch: (data: SystemBatch) => void;
  toggleGrid: boolean;
  setToggleGrid: (data: boolean) => void;
  hasPdfCover: boolean;
};

const BookContext = createContext<BookContextData>({
  bookId: '',
  setBookId: () => null,
  bookName: '',
  setBookName: () => null,
  bookSubtitle: '',
  setBookSubtitle: () => null,
  cover: undefined,
  setCover: () => null,
  coverCollection: [],
  setCoverCollection: () => null,
  isCoverDisabled: false,
  setIsCoverDisabled: () => null,
  mockup: undefined,
  setMockup: () => null,
  mockupCollection: [],
  setMockupCollection: () => null,
  aboutMePageIds: [],
  setAboutMePageIds: () => null,
  books: [],
  setBooks: () => null,
  bookFolders: [],
  setBookFolders: () => null,
  pages: [],
  setPages: () => null,
  page: undefined,
  renderPage: undefined,
  setPage: () => null,
  updatePage: () => null,
  updateRenderPage: () => null,
  salesData: {},
  setSalesData: () => null,
  updateSalesId: () => null,
  parentDimensions: { width: 0, height: 0 },
  updateParentRef: () => null,
  scale: 1,
  previewScale: 1,
  responsiveDimensions: {
    width: 1275,
    height: 1750
  },
  pendingChanges: false,
  updatePendingChanges: () => null,
  resetBookValues: () => null,
  savedPages: [],
  setSavedPages: () => null,
  bookMedia: undefined,
  setBookMedia: () => null,
  updateBookMedia: () => null,
  updateBookMedias: () => null,
  activeElement: null,
  updateActiveElement: () => null,
  resetActiveElement: () => null,
  saveModal: false,
  updateSaveModal: () => null,
  updatePages: () => null,
  syncPage: () => null,
  setRenderPage: () => null,
  history: [],
  updateHistory: () => null,
  lastModified: '',
  updateLastModified: () => null,
  editable: false,
  updateEditable: () => null,
  showLink: false,
  updateShowLink: () => null,
  setSalesHomePageValues: () => null,
  productTopics: [],
  setProductTopics: () => null,
  productNiches: [],
  setProductNiches: () => null,
  batchSearchKeyword: '',
  setBatchSearchKeyword: () => null,
  selectedTopics: [],
  setSelectedTopics: () => null,
  selectedNiches: [],
  setSelectedNiches: () => null,
  batchIds: [],
  setBatchIds: () => null,
  selectedBatches: [],
  setSelectedBatches: () => null,
  toggleSelectedBatch: () => null,
  toggleGrid: false,
  setToggleGrid: () => null,
  hasPdfCover: false
});

const BookProvider: FC<PropsWithChildren> = ({ children }) => {
  const responsiveDimensions = useState({ width: 1400, height: 1770 })[0];
  const [activeElement, setActiveElement] = useState<HTMLElement | null>(null);
  const [parentRef, setParentRef] = useState<HTMLDivElement | null>(null);
  const [bookId, setBookId] = useState<string>('');
  const [bookName, setBookName] = useState<string>('');
  const [bookSubtitle, setBookSubtitle] = useState<string>('');
  const [cover, setCover] = useState<Cover>();
  const [coverCollection, setCoverCollection] = useState<Cover[]>([]);
  const [isCoverDisabled, setIsCoverDisabled] = useState<boolean>(false);
  const [mockup, setMockup] = useState<Mockup>();
  const [mockupCollection, setMockupCollection] = useState<Mockup[]>([]);
  const [aboutMePageIds, setAboutMePageIds] = useState<number[]>([]);
  const [pages, setPages] = useState<BookPages[]>([]);
  const [history, setHistory] = useState<BookPages[]>([]);
  const [page, setPage] = useState<BookPages>();
  const [renderPage, setRenderPage] = useState<BookPages>();
  const [salesData, setSalesData] = useState<Partial<Sales>>({});
  const [parentDimensions, setParentDimensions] = useState({
    width: 0,
    height: 0
  });
  const [savedPages, setSavedPages] = useState<SavedPage[]>([]);
  const [bookMedia, setBookMedia] = useState<Media[]>([]);
  const [saveModal, setSaveModal] = useState(false);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [lastModified, setLastModified] = useState('');
  const [editable, setEditable] = useState(true);
  const [showLink, setShowLink] = useState(false);
  const [productTopics, setProductTopics] = useState<NicheAndTopic[]>([]);
  const [productNiches, setProductNiches] = useState<NicheAndTopic[]>([]);
  const [batchSearchKeyword, setBatchSearchKeyword] = useState<string>('');
  const [selectedTopics, setSelectedTopics] = useState<NicheAndTopic[]>([]);
  const [selectedNiches, setSelectedNiches] = useState<NicheAndTopic[]>([]);
  const [batchIds, setBatchIds] = useState<number[]>([]);
  const [toggleGrid, setToggleGrid] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState<SystemBatch[]>([]);
  const [books, setBooks] = useState<Book[]>([]);
  const [bookFolders, setBookFolders] = useState<BookFolder[]>([]);

  // Calculate scale factors for width and height
  const scale = useMemo(() => {
    const scaleX = parentDimensions.width / responsiveDimensions.width;
    const scaleY = parentDimensions.height / responsiveDimensions.height;

    return Math.min(scaleX, scaleY);
  }, [responsiveDimensions, parentDimensions]);

  // Calculate scale factors for width and height
  const previewScale = useMemo(() => {
    const scaleX = 136 / responsiveDimensions.width;
    const scaleY = 176.11 / responsiveDimensions.height;
    return Math.min(scaleX, scaleY);
  }, [responsiveDimensions]);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      setParentDimensions({
        width: entry.contentRect.width,
        height: entry.contentRect.height
      });
    };

    if (parentRef) {
      const rect = parentRef.getBoundingClientRect();
      setParentDimensions({
        width: rect.width,
        height: rect.height
      });
    }

    const observer = new ResizeObserver(handleResize);
    if (parentRef) observer.observe(parentRef);

    return () => observer.disconnect();

    // listen to when parentRef mounts
  }, [parentRef]);

  useEffect(() => {
    updatePendingChanges(!isEqual(history, pages));
  }, [history, pages]);

  const updateParentRef = (el: HTMLDivElement) => {
    setParentRef(el);
  };

  const updateShowLink = (value: boolean) => {
    setShowLink(value);
  };

  const updateLastModified = (date: string) => {
    setLastModified(date);
  };

  const updateEditable = (value: boolean) => {
    setEditable(value);
  };

  const updateHistory = (data: Array<BookPages>) => {
    setHistory(data);
  };

  const updatePages = (data: Array<BookPages>) => {
    setPages(data);
  };

  const updateRenderPage = (page?: BookPages) => {
    setRenderPage(page);
  };

  const updatePendingChanges = (data: boolean) => {
    setPendingChanges(data);
  };

  const updatePage = (page?: BookPages) => {
    setPage(page);
  };

  const syncPage = (
    page: BookPages,
    ingredient_metadata: Record<string, { [x: string]: string }>,
    everything?: boolean
  ) => {
    const target = page.ingredients.find((ing) => ing.id in ingredient_metadata);

    const updatedPage = {
      ...page,
      ingredients: page.ingredients.map((ing) => {
        if (target && ing.order === target.order) {
          const updatedMeta = ingredient_metadata[ing.id];
          return {
            ...ing,
            ingredient_data: {
              ...ing.ingredient_data,
              ...updatedMeta
            }
          };
        }
        return ing;
      })
    };
    if (everything) {
      setRenderPage(updatedPage);
    }
    setPages(
      pages.map((p) => {
        if (page.id === p.id) return updatedPage;
        return p;
      })
    );
  };

  const updateBookMedia = (media: Media) => {
    setBookMedia({
      ...bookMedia,
      ...media
    });
  };

  const updateBookMedias = (medias: Media[]) => {
    setBookMedia(medias);
  };

  const updateSalesId = (data: number) => {
    setSalesData((prev) => ({ ...prev, id: data }));
  };

  const updateSaveModal = (data: boolean) => {
    setSaveModal(data);
  };

  const updateActiveElement = (el: HTMLElement) => {
    if (editable) setActiveElement(el);
  };

  const resetActiveElement = () => {
    if (editable) setActiveElement(null);
  };

  const resetBookValues = () => {
    setBookId('');
    setBookName('');
    setBookSubtitle('');
    setCover(undefined);
    setCoverCollection([]);
    setIsCoverDisabled(false);
    setMockup(undefined);
    setMockupCollection([]);
    setAboutMePageIds([]);
    setPages([]);
    setPage(undefined);
    setRenderPage(undefined);
    setSalesData({});
    setBatchSearchKeyword('');
    setSelectedBatches([]);
  };

  const setSalesHomePageValues = (data: Sales) => {
    if (!data) return;
    setBookName(data.book_name);
    setIsCoverDisabled(data.is_cover_disabled);
    setMockup(data.mockup);
    setSalesData(data);
  };

  const toggleSelectedBatch = (data: SystemBatch) => {
    if (selectedBatches.some((item) => data.id === item.id)) {
      setSelectedBatches(selectedBatches.filter((item) => item.id !== data.id));
    } else {
      setSelectedBatches([...selectedBatches, data]);
    }
  };

  const hasPdfCover = useMemo(() => {
    if (!isCoverDisabled) {
      return false;
    }
    if (pages?.[0]) {
      return pages[0].bookpage_type === 'pdf';
    }
    return false;
  }, [isCoverDisabled, pages]);

  return (
    <BookContext.Provider
      value={{
        bookId,
        setBookId,
        bookName,
        setBookName,
        bookSubtitle,
        setBookSubtitle,
        cover,
        setCover,
        coverCollection,
        setCoverCollection,
        isCoverDisabled,
        setIsCoverDisabled,
        mockup,
        setMockup,
        mockupCollection,
        setMockupCollection,
        aboutMePageIds,
        setAboutMePageIds,
        books,
        setBooks,
        bookFolders,
        setBookFolders,
        pages,
        setPages,
        page,
        setPage,
        salesData,
        setSalesData,
        updateSalesId,
        parentDimensions,
        updateParentRef,
        scale,
        responsiveDimensions,
        resetBookValues,
        savedPages,
        setSavedPages,
        bookMedia,
        setBookMedia,
        updateBookMedia,
        updateBookMedias,
        activeElement,
        updateActiveElement,
        resetActiveElement,
        saveModal,
        updateSaveModal,
        updateRenderPage,
        renderPage,
        updatePages,
        syncPage,
        updatePage,
        updatePendingChanges,
        pendingChanges,
        setRenderPage,
        previewScale,
        history,
        updateHistory,
        lastModified,
        updateLastModified,
        editable,
        updateEditable,
        showLink,
        updateShowLink,
        setSalesHomePageValues,
        productTopics,
        setProductTopics,
        productNiches,
        setProductNiches,
        batchSearchKeyword,
        setBatchSearchKeyword,
        selectedTopics,
        setSelectedTopics,
        selectedNiches,
        setSelectedNiches,
        batchIds,
        setBatchIds,
        toggleGrid,
        setToggleGrid,
        selectedBatches,
        setSelectedBatches,
        toggleSelectedBatch,
        hasPdfCover
      }}>
      {children}
    </BookContext.Provider>
  );
};

export type { BookContextData };

export { BookContext, BookProvider };
